import React, { useEffect, useState } from 'react';
import Services from '../services';
import { PrismicService } from '../services/prismic';
import NavbarHeader from '../components/NavbarHeader';
import NavBarMenu from '../components/NavBarMenu';
import ContentSections from '../components/ContentSections/ContentSections';
import Footer from '../components/Footer';
import SALESFORCE from '../services/salesforce';
import { SALESFORCE_PRODUCT } from '../services/utils/salesforce-products';
import { validation } from './Hogar/validaton';
import { PROSPECTO } from '../services/prospecto';
import { TipoAsegurado } from '../services/utils/tipo-asegurado';
import { SalesforceStrCual } from "../services/utils/salesforce-str-cual";
import { CODIGO_ASESOR } from "../services/utils/CODIGO_ASESOR";
import { DATALAYERS } from '../services/datalayers';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TermsAndConditionsModal from '../components/TermsAndConditionsModal';
import { environment } from '../environments/environment';
import { Helmet } from 'react-helmet';
import { getMaestro } from '../services/maestro';
import { replaceKeyByMap } from '../services/replace-key-by-map';
import * as queryString from 'query-string';
import { TipoDocumentoSalesforce } from "../services/utils/TipoDocumentoSalesforce"

const SeguroHogar = ({ tenantName }) => {
  const [contactSection, setContactSection] = useState({
    contactSections: [],
    contactData: [],
    loadingContact: true,
  });

  const { asesor, canal, utm_campaign } = queryString.parse(window.location.search);

  useEffect(() => {
    DATALAYERS.pushObject({
      name: 'pv-vida',
      source: 'suraenlinea',
      event: 'pageview-virtual',
    });
  }, []);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [menu, setMenu] = useState([]);

  const [state, setState] = useState({
    sections: [],
    data: [],
    loading: true,
  });

  const [maestroState, setMaestroState] = useState({
    maestro: [],
  });
  const [showTCModal, setShowTCModal] = useState(false);

  const getMapMaestro = () => {
    const keysMap = {
      codigo: 'key',
      short_name: 'value',
    };
    const { maestro } = maestroState;
    return replaceKeyByMap(keysMap, maestro);
  };

  const getTipoDocumentoSalesforceBySigla = (sigla) => {
    const siglaTipoDocumentoSalesforce = Object.keys(
      TipoDocumentoSalesforce
    ).find(keyTipoDocumentoSalesforce => keyTipoDocumentoSalesforce === sigla);
    return siglaTipoDocumentoSalesforce ? TipoDocumentoSalesforce[siglaTipoDocumentoSalesforce] : maestroState.maestro.find((maestro) => maestro.codigo === sigla).short_name.toUpperCase()
  }

  const fields = [
    {
      label: 'Nombres',
      type: 'text',
      name: 'firstName',
      value: '',
      maxlength: 50,
    },
    {
      label: 'Apellidos',
      type: 'text',
      name: 'lastName',
      value: '',
      maxlength: 50,
    },
    {
      label: 'Tipo de identificación',
      type: 'select',
      name: 'typeId',
      value: '',
      maxlength: 50,
      data: getMapMaestro(),
    },
    {
      label: 'Número de identificación',
      type: 'text',
      name: 'document',
      value: '',
      maxlength: 11,
    },
    {
      label: 'Correo electrónico',
      type: 'text',
      name: 'email',
      value: '',
      maxlength: 50,
    },
    { label: 'Celular', type: 'text', name: 'phone', value: '', maxlength: 10 },
    {
      label:
        'Autorizo el uso responsable de mi información y acepto términos y condiciones',
      type: 'checkbox',
      name: 'authorization',
      value: '',
      onClick: () => setShowTCModal(true),
    },
  ];

  const onBannerFormSubmit = (values, resetForm) => {
    const tenant = environment.tenants.find((t) => t.tenant === tenantName);
    executeRecaptcha('cotizarSeguroVida').then((token) => {
      PROSPECTO.saveProspecto(
        {
          seguros: [
            {
              producto: { nombre: 'SeguroVida' },
            },
          ],
          datosAsesor: {
            codigoCanal: canal ? canal : tenant && tenant.codigoCanal,
            codigoAsesor: asesor ? asesor : tenant && tenant.codigoAsesor,
          },
          aceptos: [
            {
              tipo: 'Datos personales',
              nombre: 'Tratamiento de datos SeguroVida',
            },
          ],
          trazabilidad: {
            origen: document.location.origin,
            tenant: tenantName,
            url: document.location.href,
          },
          fechaCreacion: new Date().valueOf(),
          tomador: {
            persona: {
              PersonaNaturalTemp: {
                primerNombre: values.firstName.split(' ')[0],
                segundoNombre: values.firstName.split(' ')[1],
                primerApellido: values.lastName.split(' ')[0],
                segundoApellido: values.lastName.split(' ')[1],
                correo: values.email,
                celular: values.phone.toString(),
                numeroIdentificacion: values.document,
                tipoIdentificacion: values.typeId,
              }
            },
            tipoAsegurado: TipoAsegurado.ASEGURADO_PRINCIPAL,
          },
        },
        token
      );
    });

    const camposAdicionales = {
      Solucion_de_interes__c: 'Vida',
      Asesor_Solicitado__c: asesor ? asesor : '',
      description: utm_campaign ? utm_campaign : ''
    };

    const getStrCual = () => {
      if (asesor) {
        if (asesor === CODIGO_ASESOR.SURA) {
          return SalesforceStrCual.FORMULARIO_COMPRA;
        }
        else {
          return SalesforceStrCual.URL_PERSONALIZADA_ASESOR;
        }
      }
      else {
        return SalesforceStrCual.FORMULARIO_COMPRA;
      }
    }

    SALESFORCE.sendTomadorDataToSalesForce(
      values.firstName,
      values.lastName,
      values.phone,
      getTipoDocumentoSalesforceBySigla(values.typeId),
      values.document,
      values.email,
      SALESFORCE_PRODUCT.VIDA,
      getStrCual(),
      camposAdicionales
    ).finally(() => {
      resetForm();
    });
    DATALAYERS.push('Lead', 'Vida-Asesoría');
  };

  useEffect(() => {
    PrismicService.getDocument('landing', [tenantName, 'seguro-vida']).then((response) => {
      setState({
        sections: response.results[0].data.sections,
        data: response.results[0].data,
        loading: false,
      });
    });

    getMaestro('mst_tipo_documento', 'landings').then((response) => {
      const orderedRes = response.sort((a, b) => {
        const x = a.codigo;
        const y = b.codigo;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      });
      setMaestroState({
        maestro: orderedRes,
      });
    });

    PrismicService.getDocument('contacto', [tenantName]).then((response) => {
      setContactSection({
        contactData: response.results[0].data,
        contactSections: response.results[0].data.sections,
        loadingContact: false,
      });
    });
  }, []);

  useEffect(() => {
    Services.COMMONS.MENUS.get(tenantName).then(menuPrismic => setMenu(menuPrismic));
  }, []);

  const { sections, data, loading } = state;
  const { contactSections, contactData, loadingContact } = contactSection;

  return (
    <React.Fragment>
      <Helmet>
        <title>Seguro de Vida SURA / suraenlinea.com</title>
        <meta
          name='description'
          content='Cotiza aquí y déjanos asesorarte con el mejor Seguro de Vida para ti o tu familia, para que cumplan sus sueños y aseguren la protección del patrimonio.'
        />
        <meta
          name='keywords'
          content='seguro de vida, poliza de vida, seguro de vida cotizador, seguro de vida tarifas'
        />
      </Helmet>
      <NavbarHeader />
      {menu && <NavBarMenu menus={menu} />}
      {!loading ? (
        <ContentSections
          sections={sections}
          data={data}
          fields={fields}
          validation={validation}
          onSubmit={onBannerFormSubmit}
        />
      ) : (
          <p>Loading...</p>
        )}
      {!loadingContact && (
        <ContentSections sections={contactSections} data={contactData} />
      )}
      <Footer />
      <TermsAndConditionsModal
        show={showTCModal}
        onDismiss={() => setShowTCModal(false)}
        prismicTags={['seguro-vida']}
      ></TermsAndConditionsModal>
    </React.Fragment>
  );
};

export default SeguroHogar;
