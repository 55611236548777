import styled from 'styled-components';

export const Titulo = styled.p`
  color: var(--primary);
  font-size: 1.875rem;
  font-family:  ${props => props.titleResults ? "FSJoeyLight" : 'FSJoeySemiBold'};
  margin-bottom: 1.125rem !important;
`;

export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 44px;
  &:after {
    content: '\f107';
    font-family: 'Font Awesome 5 Pro';
    color: var(--primary);
    position: absolute;
    right: 11px;
    pointer-events: none;
    font-size: 1.6rem;
  }
`;

export const Select = styled.select`
  border-color: var(--primary) !important;
  border-radius: 4px !important;
  color: var(--primary) !important;
  font-size: 1.125rem !important;
  font-family: 'FSJoeySemiBold';
  text-align-last:center;
  cursor: pointer;
  padding-right: 2rem !important;
  @media (min-width: 992px) {
    width: ${props => props.selectResults ? "14.25rem !important" : ""};
  }
  -moz-appearance: none;
  -webkit-appearance: none;
  &::-ms-expand { display: none; }
`;

export const Label = styled.label`
  color: #606060;
  font-family: 'FSJoeySemiBold';
  font-size: 1.125rem !important;
`;


export const SearchText = styled.span`
  font-family: 'FSJoeySemiBold';
`;
