import styled from 'styled-components';
import { fadeIn } from '../../styles/animations';

export const FooterContainer = styled.footer`
  ${fadeIn()};
  background-color: var(--quarter);
  padding: 2rem 0;
  color: #fff;
  font-family: var(--font-family-secondary);
`;

export const Hr = styled.hr`
  border-color: #fff;
`;

export const FooterTerms = styled.div`
  font-size: ${(props) => props.size};
`;
