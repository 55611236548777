import React, { useEffect, useState } from 'react';
import Services from '../services';
import { PrismicService } from '../services/prismic';
import NavbarHeader from '../components/NavbarHeader';
import NavBarMenu from '../components/NavBarMenu/NavBarMenu';
import ContentSections from '../components/ContentSections/ContentSections';
import Footer from '../components/Footer';

import SALESFORCE from '../services/salesforce';
import { SALESFORCE_PRODUCT } from '../services/utils/salesforce-products';
import { validation } from './SaludEvoluciona/validaton';
import { PROSPECTO } from '../services/prospecto';
import { TipoAsegurado } from '../services/utils/tipo-asegurado';
import { DATALAYERS } from '../services/datalayers';
import TermsAndConditionsModal from '../components/TermsAndConditionsModal';
import { environment } from '../environments/environment';
import { Helmet } from 'react-helmet';
import { getMaestro } from '../services/maestro';
import { replaceKeyByMap } from '../services/replace-key-by-map';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as queryString from 'query-string';
import { MAESTRO_EDADES } from '../services/utils/ages-mock';
import { PREGUNTA_SALUD_EVOLUCIONA } from './SaludEvoluciona/preguntasSalud';
import {SalesforceStrCual} from "../services/utils/salesforce-str-cual";
import { TipoDocumentoSalesforce } from "../services/utils/TipoDocumentoSalesforce"

const SaludEvoluciona = ({ tenantName }) => {

  const { asesor, canal } = queryString.parse(window.location.search);

  const [contactSection, setContactSection] = useState({
    contactSections: [],
    contactData: [],
    loadingContact: true,
  });

  const [menu, setMenu] = useState([]);
  const [state, setState] = useState({
    sections: [],
    data: [],
    loading: true,
  });
  const [maestroState, setMaestroState] = useState({
    maestro: []
  });
  const [showTCModal, setShowTCModal] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getMapMaestro = () => {
    const keysMap = {
      codigo: 'key',
      short_name: 'value'
    };
    const { maestro } = maestroState;
    return replaceKeyByMap(keysMap, maestro);
  }

  const getTipoDocumentoSalesforceBySigla = (sigla) => {
    const siglaTipoDocumentoSalesforce = Object.keys(
      TipoDocumentoSalesforce
    ).find(keyTipoDocumentoSalesforce => keyTipoDocumentoSalesforce === sigla);
    return siglaTipoDocumentoSalesforce ? TipoDocumentoSalesforce[siglaTipoDocumentoSalesforce] : maestroState.maestro.find((maestro) => maestro.codigo === sigla).short_name.toUpperCase()
  }

  const fields = [
    { label: 'Nombres', type: 'text', name: 'firstName', value: '', maxlength: 50 },
    { label: 'Apellidos', type: 'text', name: 'lastName', value: '', maxlength: 50 },
    { label: 'Tipo de identificación', type: 'select', name: 'typeId', value: '', maxlength: 50, data: getMapMaestro() },
    { label: 'Número de identificación', type: 'text', name: 'document', value: '', maxlength: 10 },
    { label: 'Edad', type: 'select', name: 'edad', value: '', data: MAESTRO_EDADES },
    { label: 'Correo electrónico', type: 'text', name: 'email', value: '', maxlength: 50 },
    { label: 'Celular', type: 'text', name: 'phone', value: '', maxlength: 10 },
    {
      label: PREGUNTA_SALUD_EVOLUCIONA.CIRUGIAS,
      type: 'checkbox',
      name: 'cirugias',
      value: '',
      for: 'cirugias'
    },
    {
      label: PREGUNTA_SALUD_EVOLUCIONA.EMBARAZO,
      type: 'checkbox',
      name: 'embarazo',
      value: '',
      for: 'embarazo'
    },
    {
      label: 'Autorizo el uso responsable de mi información y acepto términos y condiciones',
      type: 'checkbox',
      name: 'authorization',
      value: '',
      onClick: () => setShowTCModal(true),
    },
  ];

  const onBannerFormSubmit = (values, resetForm) => {
    const tenant = environment.tenants.find(t => t.tenant === tenantName);
    executeRecaptcha("saludEvoluciona").then((token) => {
      PROSPECTO.saveProspecto({
        seguros: [{
          producto: { nombre: 'ProductoSaludEvoluciona' },
        }],
        datosAsesor: {
          codigoCanal: canal ? canal : tenant && tenant.codigoCanal,
          codigoAsesor: asesor ? asesor : tenant && tenant.codigoAsesor,
        },
        aceptos: [{
          tipo: 'Datos personales',
          nombre: 'Tratamiento de datos ProductoSaludEvoluciona'
        }],
        trazabilidad: {
          origen: document.location.origin,
          tenant: tenantName,
          url: document.location.href,
        },
        fechaCreacion: new Date().valueOf(),
        tomador: {
          persona: {
            PersonaNaturalTemp: {
              primerNombre: values.firstName.split(' ')[0],
              segundoNombre: values.firstName.split(' ')[1],
              primerApellido: values.lastName.split(' ')[0],
              segundoApellido: values.lastName.split(' ')[1],
              correo: values.email,
              celular: values.phone.toString(),
              numeroIdentificacion: values.document,
              tipoIdentificacion: values.typeId,
            }
          },
          tipoAsegurado: TipoAsegurado.ASEGURADO_PRINCIPAL,
        },
      }, token)
    })

    const camposAdicionales = {
      Solucion_de_interes__c: 'Salud Evoluciona',
      Asesor_Solicitado__c: asesor ? asesor : '',
      description: `Edad: ${values.edad} - 
      ${PREGUNTA_SALUD_EVOLUCIONA.CIRUGIAS}: ${values.cirugias} -
      ${PREGUNTA_SALUD_EVOLUCIONA.EMBARAZO}: ${values.embarazo}'`
    };
    SALESFORCE.sendTomadorDataToSalesForce(
      values.firstName,
      values.lastName,
      values.phone,
      getTipoDocumentoSalesforceBySigla(values.typeId),
      values.document,
      values.email,
      SALESFORCE_PRODUCT.SALUD_EVOLUCIONA,
      SalesforceStrCual.FORMULARIO_COMPRA,
      camposAdicionales
    ).finally(() => {
      resetForm();
    });
    DATALAYERS.push('Lead', 'Salud-Evoluciona');
  };

  useEffect(() => {

    DATALAYERS.pushObject({
      'name': 'pv-salud-evoluciona',
      'source': 'suraenlinea',
      'event': 'pageview-virtual'
    });

    PrismicService.getDocument('landing', [tenantName, 'salud-evoluciona'])
      .then(response => {
        setState({
          sections: response.results[0].data.sections,
          data: response.results[0].data,
          loading: false,
        });
      }
      );

    PrismicService.getDocument("contacto", [tenantName]).then(response => {
      setContactSection({
        contactData: response.results[0].data,
        contactSections: response.results[0].data.sections,
        loadingContact: false,
      });
    });

    getMaestro('mst_tipo_documento', 'landings').then(response => {
      const orderedRes = response.sort((a, b) => {
        const x = a.codigo;
        const y = b.codigo;
        if (x < y) { return -1 }
        if (x > y) { return 1 }
      });
      setMaestroState({
        maestro: orderedRes
      });
    });
  }, []);

  useEffect(() => {
    Services.COMMONS.MENUS.get(tenantName).then(menuPrismic => setMenu(menuPrismic));
  }, []);

  const { sections, data, loading } = state;
  const { contactSections, contactData, loadingContact } = contactSection;

  return (
    <React.Fragment>
      <Helmet>
        <title>Seguro de Salud, Plan Evoluciona / suraenlinea.com</title>
        <meta name="description" content="Cotiza aquí el Plan Salud Evoluciona, un Seguro de Salud SURA con el que accedes a especialistas, tratamientos de enfermedades de alto costo y otros servicios para tu bienestar." />
        <meta name="keywords" content="seguro de salud, pplan salud evoluciona, seguro médico, mejor seguro médico, seguro de gastos médicos mayores, póliza de salud, seguros de salud Colombia, cáncer, sida" />
      </Helmet>
      <NavbarHeader />
      {menu && <NavBarMenu menus={menu} />}
      {
        !loading ?
          <ContentSections
            sections={sections}
            data={data}
            fields={fields}
            validation={validation}
            onSubmit={onBannerFormSubmit}
          />
          : <p>Loading...</p>
      }
      {!loadingContact && <ContentSections
        sections={contactSections}
        data={contactData}
      />}
      <Footer />
      <TermsAndConditionsModal
        show={showTCModal}
        onDismiss={() => setShowTCModal(false)}
        prismicTags={['salud-evoluciona']}>
      </TermsAndConditionsModal>
    </React.Fragment>
  );
}


export default SaludEvoluciona;
