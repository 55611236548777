import React, { Fragment } from 'react';
import { Field } from 'formik';

const renderTextArea = (input) => (
  <Fragment key={input.name}>
    <label>{input.label}</label>
    <div>
      <Field
        name={input.name}
        render={(props) => {
          const { field } = props;
          const { errors, touched } = props.form;
          const hasError = errors[input.name] && touched[input.name] ? 'hasError' : '';
          return (
            <div>
              <textarea {...field} id={hasError}>
              </textarea>
            </div>
          );
        }}
      />
    </div>
  </Fragment>
);

export default renderTextArea;
