import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Title, Card, DivExtend, Icono } from './styles';
import ListItemWithImage from '../../StyledShared/ListItemWithImage';
import { RichText } from "prismic-reactjs";
import { DATALAYERS } from '../../../services/datalayers';
import Link from '../../StyledShared/Link';

const MediaObjectB = ({ data }) => (
	<Container id={data.linkInterno}>
		<Title>{data.title}</Title>					
		<Row>
			{data.data.map(( media, index ) => (
				<Col xs="12" md="6" key={`media-object-type-b-${index}`} className="d-flex">
					<Card className="mb-3 p-3">
						<ListItemWithImage
							imageUrl={media.img_item.url} 
							imageAlt={media.img_item.alt} 
							description={RichText.render(media.text_item)}
						/> 
					</Card>
				</Col>
			))}
		</Row>
		<Row>
			<Col>
				<DivExtend className="mt-4 mb-5">
					<Icono className="icomoon-sel-info-circle mr-1"></Icono>
					<Link
						to={data.linkTerms.url}
						target={data.linkTerms.target}
						variant="tertiary"
						outline
						size={5}
						color="var(--primary)" 
						onClick={() => DATALAYERS.push('Interaccion-Condiciones-seguro', 'Ver-las-Condiciones-de-tu-seguro')}>
							Ver las condiciones de tu seguro
					</Link>
				</DivExtend>
			</Col>
		</Row>
	</Container>
);

export default MediaObjectB;
