import styled from "styled-components";

export const RowOptionsContent = styled.div`
  border-right: 0.125rem solid var(--white);
  height: 100%;
  padding: 0.938rem 2.5rem 0.938rem 2.5rem;
  &:last-child {
    border-right: 0.125rem solid transparent;
    text-align: left;
  }
`;

export const Titulo = styled.a`
  cursor: pointer;
  font-family: var(--font-family-secondary);
  color: var(--secondary);
`;
