import React, { useState } from "react";

import { Container, Row } from "react-bootstrap";
import MenuDiv from '../MenuDiv';
import classNames from 'classnames/bind';
import Styles from './NavBarMenu.module.scss';

function NavBarMenu({ menus }) {

  const [state, setState] = useState({ active: false });

  const handleState = () => setState({ active: !state.active });
  
  return(
    
    <React.Fragment>
      <div 
        onClick={handleState} 
        className={classNames(Styles.buttonCollapse, state.active ? Styles.buttonCollapse_none : null )}
      >
       
        <span className={Styles.buttonCollapse__span}></span> 
        <span className={Styles.buttonCollapse__span}></span>
        <span className={Styles.buttonCollapse__span}></span>
      </div>
      <div className={classNames(Styles.collapseNav, Styles.gbMenuWeb, state.active ? Styles.collapseNav_active : null)}>
        <Container>
          <Row className="justify-content-between">
          <div 
            onClick={handleState} 
            className={classNames(Styles.buttonCollapse, state.active ? Styles.buttonCollapse_active : null)}
          >
            <span className={Styles.buttonCollapse__span}></span> 
            <span className={Styles.buttonCollapse__span}></span>
            <span className={Styles.buttonCollapse__span}></span>
          </div>
            {menus && menus.map((m, index) => (
              <MenuDiv position={ menus.length === 1 ? "right": ""} menu={m} index={index} key={`_menu_nav_bar_${index}`} />
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NavBarMenu;
