import React, { Fragment } from 'react';
import { Field } from 'formik';
import { HasError, Input, Group, CheckboxLabel } from '../styles';
import classNames from 'classnames/bind';
import { Subtitulo } from './styles';

const renderCheckBox = (input) => {
  return (
  <Fragment key={input.name}>
    <Group flexStart checkBox className="custom-control custom-checkbox">
      <Field
        name={input.name}
        render={(props) => {
          const { field } = props;
          const { errors, touched } = props.form;
          const hasError = errors[input.name] && touched[input.name] ? 'hasError' : '';
          const isValid = !errors[input.name] && touched[input.name] ? 'valid' : '';
          return (
            <React.Fragment>
              <Input
                name={input.name}
                id={input.name}
                className={classNames('custom-control-input', hasError, isValid)}
                type="checkbox"
                checked={field.value}
                onChange={field.onChange} 
                autoWidth
                id={input.name}
              />
              <label className="custom-control-label" htmlFor={input.name}></label>
              <CheckboxLabel style={{cursor: input.onClick && 'pointer'}} onClick={input.onClick}>{input.label} <Subtitulo>términos y condiciones</Subtitulo></CheckboxLabel>
              {hasError && <HasError>{errors[input.name]}</HasError>}
            </React.Fragment>
          );
        }}
      />
      
    </Group>
  </Fragment>
)};

export default renderCheckBox;
