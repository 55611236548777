
import styled from 'styled-components';
import Sprite from '../../../assets/images/sprites.png';

const IconSprite = styled.i(({ width, height, position }) => `
  background: url(${Sprite}) no-repeat ${position};
  width: ${width};
  height: ${height};
  display: inline-block;
`
);

export default IconSprite;
