export const colsubsidioTenant = {
    name: 'colsubsidio',
    properties: {
        // Primary colors
        '--primary': '#001F5B',
        '--secondary': '#FFCE00',
        '--tertiary': '#707070',
        '--quarter': '#011F5B',
        '--error': 'red',
        '--nav-bar': 'var(--primary)',

        // Navbar
        '--color-text-nav-bar': 'white',
        /* Buttons */
        // Primary
        '--color-button-primary': 'var(--primary)',
        '--bg-color-button-primary': '#ffe800',
        '--hover-color-button-primary': '#0e8288',
        '--hover-bg-color-button-primary': 'var(--primary)',
        // Secondary
        '--color-button-secondary': '#003466',
        '--bg-color-button-secondary': '#ffe900',
        '--hover-color-button-secondary': '#fff',
        '--bg-hover-color-button-secondary': '#003466',
        // Tertiary
        '--color-button-tertiary': '#003466',
        '--bg-color-button-tertiary': '#ffe900',
        '--hover-color-button-tertiary': '#fff',
        '--bg-hover-color-button-tertiary': '#003466',

        // Forms
        '--color-input-valid': 'var(--primary)',

        // Fonts
        '--primary-semibold-font-family': 'GalanoGrotesqueMedium',
        '--font-family-secondary': 'GalanoGrotesqueLight',
        '--font-family-secondary-medium': 'GalanoGrotesqueLight',

        // Header
        '--background-header': '#001F5B',
        '--color-breadcrumb': '#EFEFEFDE',
        '--color-breadcrumb-sucess': '#00aec7',
        '--logo': 'url(../../../../assets/images/tenants/colsubsidio/logo.svg)',

        // Footer
        '--footer-color': 'var(--primary)',

        // SOAT
        '--color-tittle-h2': 'var(--primary)',
        '--color-tittle-h3': 'var(--secondary)',

        // FAQ
        '--color-text-question-title': 'white',
        '--background-color-question-title': 'var(--primary)',
    }
};
