import Prismic from "prismic-javascript";
import { environment } from '../commons/enviroment';
import { PRISMIC_DOCUMENT_NAME } from '../services/utils/prismic-document-name';
import { HOME_DOCUMENT } from "../assets/prismic/home_document";
import { MENU_DOCUMENT } from "../assets/prismic/menu_document";
import { CATEGORIA_MENU } from "../assets/prismic/categoria_menu_document";
 
export const PrismicService = {
  getDocument:(documentType, tags) => {
    if (documentType === PRISMIC_DOCUMENT_NAME.HOME) {
      return new Promise((resolve, reject) => {
        resolve( HOME_DOCUMENT );
      });
    }

    if (documentType === PRISMIC_DOCUMENT_NAME.MENU) {
      return new Promise((resolve, reject) => {
        resolve( MENU_DOCUMENT );
      });
    }

    return Prismic.api(environment.prismicApi,  environment.prismicToken ).then(api => {
      return api.query([ 
        Prismic.Predicates.at('document.type', documentType),
        Prismic.Predicates.at('document.tags', tags) 
      ])
    });
  },
  getDocumentsByID: (idsDocuments)=> {
    return new Promise((resolve, reject) => {
      resolve( CATEGORIA_MENU );
    });
  }
}
