export const PRISMIC_PRODUCT_NAME = Object.freeze({ 
    PATINETAS: 'seguro-patineta-electrica'
});

export const PRISMIC_DOCUMENT_TYPE = Object.freeze({
    LANDING: 'landing',
    TIPO_DOCUMENTO: 'mst_tipo_documento',
    CONTACTO: 'contacto'
});

export const PRISMIC_DOCUMENT_NAME = Object.freeze({
    HOME: 'home',
    MENU: 'menu_suraenlinea',
});