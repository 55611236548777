import axios from "axios";
import { environment } from "../environments/environment";
import { GTM } from "./gtm";

const URL = environment.flujoVentaApi;

export const PROSPECTO = {
  saveProspecto: (prospecto, recaptchaCode) => {
    const uri = `${URL}/prospecto`;
    if (prospecto.trazabilidad) {
      prospecto.trazabilidad.idGoogle = GTM.getGoogleId();
    }
    return axios
      .post(uri, prospecto, {
        headers: {
          "Content-Type": "application/json",
          "CAPTCHA-TOKEN": recaptchaCode,
          [environment.headerSuscripcionNombre]: environment.headerSuscripcionValor,
        },
      })
      .then((response) => {
        return response.respuesta;
      });
  },
};
