import styled from 'styled-components';


export const Container = styled.div`
  position: fixed;
  bottom: 9rem;
  background-color: var(--primary);
  z-index: 1000;
  border: 0;
  right: 1rem;
  border-radius: 50%;
`;

export const ContainerSchedule = styled.div`
  font-size: 0.6875rem;
  color: var(--primary);
`;

export const ScheduleService = styled.div`
  font-size: 1.125rem;
  color: var(--primary);
  font-weight: bold;
`;

export const ButtonClose = styled.button`
  right: 0;
  top: 0;
  background-color: transparent;
  border: 0;
  top: .3rem;
  right: .3rem;
`;

export const ModalTitle = styled.h4`
  color: var(--primary);
  font-size: 1.375rem;
  font-family: 'FSJoeySemiBold';
`;

export const IconPhone = styled.img`
  width: 2.3rem;
`;
