import React, { useState } from 'react';
import classNames from 'classnames/bind';
import Style from './NavOptionsMobile.module.scss';
import Col from 'react-bootstrap/Col';
 
const NavOptionsMobile = ({data}) =>{
    const [state, setState] = useState({ active: false });
    const handleShow = () => setState({ active: !state.active });
    return(
        <Col className="d-xl-none d-md-none d-lg-none padding-0 mt-4">
            <div className={classNames("dropdown", Style.contentdorpdown)} >
                <button className={classNames("btn-block btn-lg dropdown-toggle text-left", Style.contentdorpdown__dropdown)} onClick={handleShow}  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{data[0].item_name}</button>
                <div className={classNames("dropdown-menu", Style.contentdorpdown__dropdownmenu, state.active ? Style.contentdorpdown__active : null)} aria-labelledby="dropdownMenuButton">
                    {data.map((item, index)=>{
                        let href = `#${item.enlace_a_seccion_interna}`
                        if(item.item_link && item.item_link.url){
                            href = item.item_link.url;
                        }
                        return (
                        <a className={Style.contentdorpdown__dropdownitem} key={index} href={href}>{ item.item_name}</a>
                    )})}
                </div>
            </div>
        </Col>
    )
}
export default NavOptionsMobile;
