import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Accordion, Card, Button } from 'react-bootstrap';
import Styles from '../FAQR.module.scss';
import { Titulo, Select, ContainerSelect } from '../styles';
import { RichText } from 'prismic-reactjs';
import { DATALAYERS } from '../../../services/datalayers';
import { DATA_LAYER_FAQ } from '../../../utils/constants/DataLayer';

const FAQResult = ({ searchText, searchCategory, data, changeCategoryFn }) => {
  const [show, setShow] = useState(false);
  const initialNumberOfResultsToShow = 5;
  const [numberOfResultsToShow, setNumberOfResultsToShow] = useState(initialNumberOfResultsToShow);
  const [showAllResults, setShowAllResults] = useState(false);
  const [categorySelected, setCategorySelected] = useState(searchCategory);

  const handleSelectChange = event => {
    changeCategoryFn(event.target.value);
    setCategorySelected(event.target.value);
    setShow(true);
    setNumberOfResultsToShow(initialNumberOfResultsToShow);
    setShowAllResults(false);
  };
  
  const onShowAllClick = (numberOfResultsToShow) => {
    setNumberOfResultsToShow(numberOfResultsToShow);
    setShowAllResults(true);
  }

  const filterResults = data => {
    return data.faq_results_faq_results.filter(
      result => {
        return result.result_category === searchCategory
      }
    );
  };

  const filterData = data => {
    const result = { ...data };
    if (
      result &&
      result.length !== 0 &&
      result.faq_results_faq_results &&
      searchText
    ) {
      result.faq_results_faq_results = result.faq_results_faq_results
        .filter(
          faqResult =>
            (faqResult.result_header || '')
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (faqResult.result_body
              ? RichText.asText(faqResult.result_body)
              : ''
            )
              .toLowerCase()
              .includes(searchText.toLowerCase())
        );
    }
    return result;
  };

  data = filterData(data.data);
  const results = filterResults(data);

  const createTable = () => {
    let answers = [];
    let faq;
    let resultsToShow = numberOfResultsToShow
    let areMorePages = true;

    if (results.length < numberOfResultsToShow) {
      resultsToShow = results.length
      areMorePages = false;
    }

    for (let resultIndex = 0; resultIndex < resultsToShow; resultIndex++) {
      faq = results[resultIndex];
      answers.push(<FAQAccordion key={resultIndex} resultIndex={resultIndex} question={faq.result_header} answer={faq.result_body} category={searchCategory}></FAQAccordion>)
    }
    if (!showAllResults && areMorePages) {
      answers.push(<div className="col-12 text-center" key={resultsToShow}>
        <a onClick={() => onShowAllClick(results.length)} className="btn btn-link text-primary">Ver más</a>
      </div>);
    }
    return answers
  }

  return (
    <div className={classNames(Styles.FAQResults, 'container mb-5 pb-5 mt-5')}>
      <div className="d-flex mb-4 flex-column flex-md-row">
        <Titulo className="m-0 mr-3 text-center text-lg-left">
          {data.faq_results_header_faq_results}
        </Titulo>
        <ContainerSelect className="mt-3 mt-md-0">
          <Select
            selectResults
            className={classNames(
              show && Styles.FAQResults__select_active,
              'text-center form-control'
            )}
            name="faq_search_category"
            id="faq_search_category"
            onChange={handleSelectChange}
            value={categorySelected}
          >
            {data.insurance_category_options_faq_results.map((item, index) => (
              <option
                key={index}
                value={item.insurance_category_option_name}
              >
                {item.insurance_category_option_name}
              </option>
            ))}
          </Select>
        </ContainerSelect>
      </div>

      {results && results.length > 0 ? (
        <Accordion>
          { createTable() }
        </Accordion>
      ) : (
          <Titulo titleResults>
            No se encontraron preguntas en esta sección
        </Titulo>
        )}
    </div>
  );
};

const FAQAccordion = ({ resultIndex, question, answer, category }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classToApply = isOpen ? [Styles.FAQResults__link, Styles.FAQResults__link_active] : [Styles.FAQResults__link]

  return (
    
      <Card className={classNames(Styles.FAQResults__card)}>
        <Card.Header className={classNames(Styles.FAQResults__heder)}>
          <Accordion.Toggle
            className={classNames(
              classToApply,
              'd-block w-100 text-left'
            )}
            as={Button}
            variant="link"
            eventKey={resultIndex + 1}
            onClick={() => {
              setIsOpen(!isOpen);
              DATALAYERS.pushObject({
                accion: category,
                category: DATA_LAYER_FAQ.CATEGORY,
                etiqueta: question,
                event: DATA_LAYER_FAQ.EVENT
              })
            }}
          >
            {question}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={resultIndex + 1}>
          <Card.Body className={classNames(Styles.FAQResults__cardBody)}>
            {RichText.render(answer)}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    
  )
}

export default FAQResult;
