export const clickamTenant = {
  name: 'clickam',
  properties: {
    // Primary colors
    '--primary': '#00d9e4',
    '--secondary': '#003466',
    '--tertiary': '#ffe800',
    '--quarter': '#54555a',
    '--error': 'red',
    '--nav-bar': '#00d9e4',

    //Navbar
    '--color-text-nav-bar': 'white',

    /* Buttons */
    // Primary
    '--color-button-primary': '#00b5cc',
    '--bg-color-button-primary': '#ffe800',
    '--hover-color-button-primary': '#0e8288',
    '--hover-bg-color-button-primary': '#00b5cc',
    // Secondary
    '--color-button-secondary': '#003466',
    '--bg-color-button-secondary': '#ffe900',
    '--hover-color-button-secondary': '#fff',
    '--bg-hover-color-button-secondary': '#003466',
    // Tertiary
    '--color-button-tertiary': '#003466',
    '--bg-color-button-tertiary': '#ffe900',
    '--hover-color-button-tertiary': '#fff',
    '--bg-hover-color-button-tertiary': '#003466',

    // Forms
    '--color-input-valid': '#00b5cc',

    // Fonts
    '--primary-semibold-font-family': 'GalanoGrotesqueMedium',
    '--font-family-din-regular': 'GalanoGrotesqueLight',
    '--font-family-din-medium': 'GalanoGrotesqueLight',

    // Header
    '--background-header': '#ffffff',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#00aec7',
    '--logo': 'url(../../../../assets/images/tenants/exito/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': 'var(--secondary)',
    '--color-tittle-h3': 'var(--secondary)',

    // FAQ
    '--color-text-question-title': '#ffff',
    '--background-color-question-title': '#797979',
  }
};
