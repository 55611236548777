import React, { useState } from 'react';
import {
  ContainerCotizador,
  Title,
  Button,
  ModalBody,
  TitleListSeguros,
  List,
  ListItem,
  IconContainer,
  ListItemLink,
  ButtonClose,
  IconClose,
  ContainerButtonFixed
} from './styles';
import Modal from 'react-bootstrap/Modal'
import './reset.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { DATALAYERS } from '../../../../services/datalayers';

const Cotizador = ({ data }) => {

  const [showModal, setshowModal] = useState(false);

  const handleShowModal = () => setshowModal(true);
  const handleCloseModal = () => setshowModal(false);

  const pushDatalayer = (tagName) => DATALAYERS.push('Interaccion-Cotizador', tagName);

  return (
    <React.Fragment>
    <ContainerCotizador className="p-3 d-none d-md-block">
      <Title>Cotiza tu seguro</Title>
      <Button onClick={handleShowModal}>SELECCIONA EL DE TU INTERÉS</Button>
    </ContainerCotizador> 
    <ContainerButtonFixed className="py-3 d-md-none">
      <Button onClick={handleShowModal} className="d-md-none" border>Cotiza tu seguro</Button>
    </ContainerButtonFixed>
    <Modal show={showModal} onHide={handleCloseModal} centered dialogClassName="modal-cotizador-home">
      <ButtonClose variant="secondary" className="p-0" onClick={handleCloseModal}><IconClose>X</IconClose></ButtonClose>
      <ModalBody className="py-4 px-3">
        <Container>
          <Row>
          {data.data.map((groupCotizador, indexGroupCotizador) => (
            <Col key={`group-cotizador-${indexGroupCotizador}`} className={groupCotizador.primary.bootstrap_columns}>
              <TitleListSeguros className="pb-2 mb-3">{groupCotizador.primary.title_group_seguros}</TitleListSeguros>
              <Row>
                {groupCotizador.items.map((link, indexLink) => (
                <Col key={`inner-group-cotizador-${indexLink}`} className={groupCotizador.primary.bootstrap_columns_items}>
                  <List className="p-0">
                    <ListItem className="d-flex align-content-center m-0">  
                      <IconContainer className="mr-2 d-flex align-items-center justify-content-center">
                        <img 
                          src={link.imagen_seguro.url}
                          alt={link.imagen_seguro.alt}
                        />         
                      </IconContainer> 
                      <ListItemLink
                        onClick={() => pushDatalayer(link.title_seguro)}
                        href={link.link_seguro.url}
                        target={link.link_seguro.target ? link.link_seguro.target : '_self'}
                      >
                        {link.title_seguro}
                      </ListItemLink>
                    </ListItem>
                  </List>
                </Col>
                ))}
              </Row>
              </Col>
            ))}
          </Row>
        </Container>
      </ModalBody>   
    </Modal>
    </React.Fragment>
  );
}

export default Cotizador;
