import styled from 'styled-components'

const Button = styled.button`
  border: 0;
  outline: 0;
  border-radius: 50px;
  transition: all .15s ease-in-out;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  display: block;
  margin: 0 auto;
  padding: .5rem 1.5rem;
  font-family: 'FSJoeySemiBold';
  font-weight: bold;
  &:focus {
    outline: none;
  }
  ${props =>
    (props.w100 &&`
      width: 100%;
    `)
  }
  ${props =>
    (props.variant === 'primary' && `
      background-color: #00aec7;
      color: #fff;
      font-size: ${props.size + 'rem' || '1.375rem'};
    `)
  }
  ${props =>
    (props.variant === 'secondary' && `
      background-color: var(--tertiary);
      color: var(--primary);
      font-size: ${props.size ? props.size + 'rem' : '1rem'};
      :hover {
        color: var(--primary);
      }
    `)
  }
  ${props =>
    (props.variant === 'tertiary' && `
      background-color: ${props.outline ? '#fff' : 'var(--primary)'};
      border: ${props.outline && '1px solid var(--primary)'};
      color: ${props.outline ? 'var(--primary)' : '#fff'};
      font-weight: normal;
      font-size: ${props.size ? props.size + 'rem' : '1rem'};
      :hover {
        color: #fff;
        background-color: var(--primary);
      }`
    )
  }
`;

export default Button;
