import styled from 'styled-components'
import { Typeahead } from 'react-bootstrap-typeahead';

export const CustomTypeahead = styled(Typeahead)`
  .rbt-input-main {
    font-size: 1.0625rem;
    height: auto;
    padding: 0.8rem 0.75rem;
    border-color: #707070;
  }
`;

export const Search = styled.button`
  width: 3.125rem;
  background-color: var(--primary);
  color: #fff;
  border: none;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  &:focus {
    outline: none;
  }
`;