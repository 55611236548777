

export const empleadosSuraTenant = {
  name: 'empleadossura',
  properties: {
    // Primary colors
    '--primary': '#0033a0',
    '--secondary': '#00aec7',
    '--tertiary': '#e3e829',
    '--quarter': '#0033a0',
    '--error': 'red',
    '--nav-bar': '#fff',

    /* Buttons */
    // Primary
    '--color-button-primary': '#0033a0',
    '--bg-color-button-primary': '#e3e829',
    '--hover-color-button-primary': '#0033a0',
    '--hover-bg-color-button-primary': '#00aec7',
    // Secondary
    '--color-button-secondary': '#fff',
    '--bg-color-button-secondary': '#00aec7',
    '--hover-color-button-secondary': '#0033a0',
    '--bg-hover-color-button-secondary': '#e3e829',
    // Tertiary
    '--color-button-tertiary': '#fff',
    '--bg-color-button-tertiary': '#00aec7',
    '--hover-color-button-tertiary': '#0033a0',
    '--bg-hover-color-button-tertiary': '#e3e829',

    // Forms
    '--color-input-valid': '#00aec7',

    // Fonts
    '--primary-semibold-font-family': 'FSJoeySemiBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#fff',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#63ba2f',
    '--logo': 'url(../../../../assets/images/tenants/empleadossura/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--secondary)',
  }
};
