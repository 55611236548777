import React, { Fragment } from "react";
import ContentSection from "../ContentSection/ContentSection";
import "./ContentSections.scss";

const ContentSections =   ({ sections, data, fields, validation, onSubmit }) => {
  return (    
    <Fragment>
      {sections.map((section, index) => (
        <ContentSection
          title={data[`title_${section.section}`]}
          linkInterno={data[`internal_link_${section.section}`]}
          linkTerms={data[`link_terms_${section.section}`]}
          colorTitle={{color: data[`color_title_${section.section}`]}}
          body={data[`body_${section.section}`]}
          colorBody={data[`color_body_${section.section}`]}
          background={data[`background_${section.section}`]}
          data={data[`list_${section.section}`]}
          bootstrapCols={data[`bootstrap_cols_${section.section}`]}
          component={data[`list_component_${section.section}`]}
          key={`_content_index_${index}`}
          fields={fields}
          validation={validation}
          onSubmit={onSubmit}
        />
      ))}
    </Fragment>
  )
};

export default ContentSections;
