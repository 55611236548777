import { PrismicService } from './prismic';

const normalizeMenuFromPrismic = async (menuResponse) =>{
  let idsDocumentsCategorias = []
  let menu = []
  menu.push({
      caption: menuResponse.nombre_producto_menu,
      active:true})
  menuResponse.categorias_menu.map(document => idsDocumentsCategorias.push(document.categoria.id));
  const response = await PrismicService.getDocumentsByID(idsDocumentsCategorias)     
  return [...menu,...normalizeCategoriasFromPrsimic(response.results)] 
}

const normalizeCategoriasFromPrsimic = (categorias)=>{      
  return categorias.map(categoria => {
    return categoria.data.is_collapsable ? normalizeCategoriaCollapsable(categoria.data) : normalizeCategoriaButton(categoria.data);
  })
}

const normalizeCategoriaButton = (categoria) =>{
  return {
    caption: categoria.nombre_categoria[0].text,
    link: categoria.href_categoria,
    target: categoria.target,
    icon: categoria.icono_categoria ? categoria.icono_categoria.url : null
  };      
}

const normalizeCategoriaCollapsable = (categoria) =>{
 return {
    caption: categoria.nombre_categoria[0].text,
    groupSubMenus: normalizeSubcategorias(categoria.body, categoria.numero_columnas_subcategorias)
  };
}

const normalizeSubcategorias = (subCategoriasPrismic, numeroColumnas) =>{      
  let groupSubMenus = [];
  for (let i = 1; i <= numeroColumnas; i++) {
    let subMenu = [];
    subCategoriasPrismic.map((subCategoria) => {
      if (subCategoria.primary.numero_columna_submenu === i) {
        subMenu.push(normalizeSubCategoria(subCategoria));
      }
    });
    groupSubMenus.push(subMenu);
  }
  return groupSubMenus;
}

const normalizeSubCategoria = (subCategoriaPrismic) =>{      
  return {
    caption: subCategoriaPrismic.primary.titulo_subcategoria[0] ? subCategoriaPrismic.primary.titulo_subcategoria[0].text:'' ,
    icon: subCategoriaPrismic.primary.icono_subcategoria ? subCategoriaPrismic.primary.icono_subcategoria.url: null,
    subMenus: subCategoriaPrismic.items.map(item=>{
      return normalizeItemSubCategoria(item)
    }) 
  }
}

const normalizeItemSubCategoria = (itemPrismic) =>{      
    return {
      caption:itemPrismic.nombre_item_subcategoria[0].text ? itemPrismic.nombre_item_subcategoria[0].text: '',
      link: itemPrismic.href_item,
      target: itemPrismic.target_item
    }
}

const getMenuFromPrismic = async (tenant) =>{
    const response = await PrismicService.getDocument('menu_suraenlinea', [tenant]);
    const menuResponse = response.results[0] ? response.results[0].data:null;
    if(menuResponse){    
      const menu = await normalizeMenuFromPrismic(menuResponse);      
      return menu;  
    }    
}
export default getMenuFromPrismic;