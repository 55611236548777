

export const alkostoTenant = {
  name: 'alkosto',
  properties: {
    // Primary colors
    '--primary': '#ff5e1d',
    '--secondary': '#004590',
    '--tertiary': '#fff',
    '--quarter': '#54555a',
    '--error': 'red',
    '--nav-bar': '#fff',

    /* Buttons */
    // Primary
    '--color-button-primary': '#ff5e1d',
    '--bg-color-button-primary': '#fff',
    '--hover-color-button-primary': '#ff5e1d',
    '--hover-bg-color-button-primary': '#fff',
    // Secondary
    '--color-button-secondary': '#fff',
    '--bg-color-button-secondary': '#df471e',
    '--hover-color-button-secondary': '#fff',
    '--bg-hover-color-button-secondary': '#0056a6',
    // Tertiary
    '--color-button-tertiary': '#fff',
    '--bg-color-button-tertiary': '#0056a6',
    '--hover-color-button-tertiary': '#fff',
    '--bg-hover-color-button-tertiary': '#df471e',

    // Forms
    '--color-input-valid': '#004c99',

    // Fonts
    '--primary-semibold-font-family': 'HelveticaNeueBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#fff',
    '--color-breadcrumb': '#004590',
    '--color-breadcrumb-sucess': '#ff4611',
    '--logo': 'url(../../../../assets/images/tenants/alkosto/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--primary)',
  }
};
