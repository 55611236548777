import React from "react";
import classNames from "classnames/bind";
import { ContainerIcon, ItemParagraph } from "./styles";

const ListItemWithImage = ({
  imageUrl,
  imageAlt,
  description,
  customClass,
}) => (
  <div
    className={classNames(
      "d-flex align-items-start align-items-md-center flex-column flex-md-row",
      customClass
    )}
  >
    {imageUrl && (
      <ContainerIcon>
        <img src={imageUrl} alt={imageAlt} />
      </ContainerIcon>
    )}
    <ItemParagraph>{description}</ItemParagraph>
  </div>
);

export default ListItemWithImage;
