import React from 'react';
import { ALink } from './styles';

const Link = ({ to, target = '_blank', elementId, children, variant, color, background, outline, className }) => (
  <ALink href={to} target={target} id={elementId} variant={variant} color={color} background={background} outline className={className}>
    {children}
  </ALink>
);

export default Link;
