
import * as tenantStyles from "../tenants/index";

export const STYLES = {
    setStyles: (tenant) => {

        const element = document.getElementById("root");
        const tenants = Object.values(tenantStyles);
        const {properties: styles} = tenants.find(tenantObject => tenantObject.name === tenant)
          || tenants.find(tenantObject => tenantObject.name === 'sura') || {};

        for (const key in (styles || [])) {
          if (key) {
            element.style.setProperty(key, styles[key]);
          }
        }
      },
};