import styled from "styled-components";
import { Card, Button } from "react-bootstrap";

export const CardExtended = styled(Card)`
  border: 0rem 0rem 0.063rem 0rem !important;
  background-color: transparent !important;
`;

export const CardHeaderExtended = styled(Card.Header)`
  background-color: var(--white) !important;
  padding: 0.3rem !important;
  border-radius: 0.25rem !important;
  border-bottom: 0 0 2 0 !important;
`;

export const ButtonExtended = styled(Button)`
  color: #777777 !important;
  font-family: var(--font-family-secondary);
  font-size: 1rem !important;
  position: relative;
  box-shadow: 0rem 0rem 0rem 0rem !important;
  text-decoration: none !important;
  padding: 0.375rem 3rem 0.375rem 0.75rem !important;
  @media (min-width: 48rem) {
    padding: 0.375rem 0.75rem;
  }
  &::before {
    content: "+";
    font-weight: bolder;
    position: absolute;
    bottom: 0;
    left: 61rem;
    @media (min-width: 48rem) {
      right: 1.875rem;
    }
    margin: auto;
    font-size: 1.5rem;
    background: transparent;
    box-shadow: 0 0 0 var(--color-text-nav-bar);
  }
`;

export const CardBodyExtended = styled(Card.Body)`
  color: #808080;
  font-size: 0.875rem;
  padding: 0.9375rem 0.9375rem 0.3125rem 0.9375rem !important;
`;
