import React from 'react';
import Style from './infoContact.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames/bind';
 
const InfoContact = ({data}) => {
    return(
        <div className={classNames('w-100',Style.buyPhone)}>
            <Container>
                <Row>
                    <Col xs="12" sm="12" className="d-sm-none">
                        <img src={data.background.url} alt={data.background.alt} className={classNames("img-fluid position-relative",Style.buyPhone__imgResponsive)}/>
                    </Col>
                    <Col xs="12" sm="12" className={classNames('w-100 position-relative d-sm-none',Style.buyPhone__contentMobile)}>
                        <div className={Style.buyPhone__content}>
                            <div>
                                <h2 className={Style.buyPhone__content_tittle}> {data.title}</h2>
                                    <div className="d-md-none  mb-4">
                                        <a href={`tel:${data.data[0].phone}`} className={Style.buyPhone__bodyText_button}>Ventas</a>
                                    </div>
                                <div className="media">
                                    <img src={data.data[0].img.url} alt={data.data[0].img.alt} className={classNames("mr-3",Style.buyPhone__content_imgStandar)} />
                                    <div className={classNames("media-body text-justify", Style.buyPhone__content_bodyText, Style.buyPhone__bodyText)}>
                                        <h5 className={classNames("mt-0 h2 mb-0 d-none d-sm-block", Style.buyPhone__bodyText_title)}>{data.data[0].phone}</h5>
                                        
                                        {data.data[0].text}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default InfoContact;
