import styled from 'styled-components';

export const ButtonClose = styled.button`
background: transparent;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
right: 1rem;
top: 1.5rem;
border: 2px solid var(--primary);
border-radius: 50%;
width: 1.875rem;
height: 1.875rem;
line-height: 0;
z-index: 10;
color: var(--primary);
font-weight: 500;
:focus { outline: none; }
`;

export const IconClose = styled.span`
height: 1px;
font-size: 1.3rem;
`;

export const ModalBody = styled.div`
  background-color: #fff;
`;