import React, { useState, Fragment } from 'react';
import { Formik } from 'formik';
import { H3, Container, MsgThankYou, ContainerThankYou } from './styles';
import Button from '../../StyledShared/Button';
import renderSelect from './rendererSelect';
import renderTextArea from './renderTextArea';
import renderCheckBox from './renderCheckBox';
import renderInput from './renderInput';
import IconFontAwesome from '../../StyledShared/IconFontAwesome';


const DynamicForm = ({ validation, fields, onSubmit }) => {

  const [ step, setStep ] = useState(1);
  
  const renderFields = (inputs) => (
    inputs.map(input => {
      switch (input.type) {
        case 'select':
          return renderSelect(input);
        case 'checkbox':
          return renderCheckBox(input);
        case 'textarea':
          return renderTextArea(input);
        default:
          return renderInput(input);
      }
    })
  );

  const getInitialValues = (inputs) => {    
    const initialValues = {};
    inputs.forEach(field => {
      if(!initialValues[field.name]) {
        initialValues[field.name] = field.value;
      }
      if(!initialValues[field.name] && field.type === 'checkbox') {
        initialValues[field.name] = false;
      }
    });
    return initialValues;
  }

  const handleSubmit = (values, {resetForm}) => {
    setStep(2);
    onSubmit(values, resetForm);
  }

  const handleReset = () => {
    setStep(1);
  }

  const initialValues = getInitialValues(fields);
  
  return (
    <Container>
      {(() => {
        switch (step) {
          case 1:
            return (
              <Fragment>
                <H3 className="mb-3">Déjanos tus datos para asesorarte</H3>
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validateOnChange={true}
                  validationSchema={validation}
                  render={(form) => {
                    return (
                      <form onSubmit={form.handleSubmit}>
                        {renderFields(fields)}
                        <Button type="submit" variant="secondary" size={1.125} w100>ME INTERESA</Button>
                      </form>
                    )
                  }}
                />
              </Fragment>
            );
          case 2:
            return (
              <ContainerThankYou>
                <IconFontAwesome className="fas fa-check-circle" size={1.5} color="#0FB23B" />
                <MsgThankYou>Pronto serás contactado por uno de nuestros asesores.</MsgThankYou>
                <Button onClick={handleReset} variant="tertiary">Volver a empezar <IconFontAwesome className="fas fa-undo-alt" size={0.8125} color="#fff" /></Button>
              </ContainerThankYou>
            )
          default:
            return null;
        }
      })()}
    </Container>
  );
}

export default DynamicForm;
