import React, { useEffect, useState } from 'react'
import {PrismicService} from '../../services/prismic';
import { RichText } from "prismic-reactjs";


import Modal from 'react-bootstrap/Modal'
import {
    ModalBody,
    ButtonClose,
    IconClose,
} from './styles';
import { Container, Row } from 'react-bootstrap';

export default function TermsAndConditionsModal({ show, onDismiss, prismicDocumentType, prismicTags }) {

    const [TCData, setTCData] = useState(undefined);

    useEffect(() => {
        PrismicService.getDocument(prismicDocumentType || 'terminosycondiciones', prismicTags || [])
            .then(response => {
                let data = response && response.results && response.results[0];
                data = data.data;
                if (data) {
                    setTCData(data);
                }
            });
    }, []);

    const { titulo, cuerpo } = TCData || {};

    return (
        !TCData ? <React.Fragment></React.Fragment> : (
            <Modal show={show} onHide={onDismiss} centered dialogClassName="modal-cotizador-home">
                <ButtonClose variant="secondary" className="p-0" onClick={onDismiss}><IconClose>X</IconClose></ButtonClose>
                <ModalBody className="py-4 px-3">
                    <Container>
                        {RichText.render(titulo)}
                        <Row>
                            <div className="w-100">{
                                RichText.render(cuerpo)}</div>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>)
    );
}
