import React, { Fragment } from 'react';
import { Field } from 'formik';
import { 
  Group,
  LabelInput,
  HasError,
  Input
} from '../styles';

const renderInput = ({ name, label, type, maxlength, pattern }) => (
  <Group key={name}>
    <Field
      name={name}
      render={(props) => {
        const { field } = props;
        const { errors, touched } = props.form;
        const hasError = errors[name] && touched[name] ? 'hasError' : '';
        const isValid = !errors[name] && touched[name] ? 'valid' : '';
        return (
          <Fragment>
          <Input
            {...field}
            className={`${hasError} ${isValid}`}
            type={type}
            pattern={pattern}
            maxLength={maxlength}/>
          {hasError && <HasError>{errors[name]}</HasError>}
          </Fragment>
        );
      }}
    />
    <LabelInput>{label}</LabelInput>
  </Group>  
);

export default renderInput;
