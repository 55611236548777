import { environment } from "../environments/environment";

export const GTM = {
         constants: { CLIENT_ID: "clientId" },

         setGoogleScriptTagManager: (objectoTenant) => {
           const s = document.createElement("script");
           const headHTMLElement = document.getElementsByTagName("head")[0];
           const bodyHTMLElement = document.getElementsByTagName("body")[0];

           s.type = "text/javascript";
           const frame = document.createElement("noscript");
           s.innerHTML =
             "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push" +
             "({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)" +
             "[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
             "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})" +
             "(window,document,'script','dataLayer','" +
             environment.GTM +
             "');";
           frame.innerHTML =
             '<iframe src="//www.googletagmanager.com/ns.html?id=' +
             environment.GTM +
             '" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
           headHTMLElement.appendChild(s);
           bodyHTMLElement.appendChild(frame);
         },

         insertGTag: (objectoTenant) => {
           const headHTMLElement = document.getElementsByTagName("head")[0];
           const asyncScript = document.createElement("script");
           asyncScript.type = "text/javascript";
           asyncScript.async = true;
           asyncScript.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.UA;
           const script = document.createElement("script");
           script.type = "text/javascript";
           script.innerHTML =
             "window.dataLayer = window.dataLayer || [];" +
             "function gtag(){dataLayer.push(arguments);}" +
             "gtag('js', new Date());" +
             "gtag('config', '" +
             environment.UA +
             "');";
           headHTMLElement.appendChild(asyncScript);
           headHTMLElement.appendChild(script);
         },

         insertGTagConTimeOut: (objectoTenant) => {
           const script = document.createElement("script");
           const headHTMLElement = document.getElementsByTagName("head")[0];
           script.type = "text/javascript";
           script.innerHTML =
             "(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;" +
             "h.end=i=function(){" +
             "s.className=s.className.replace(RegExp(' ?'+y),'')};" +
             "(a[n]=a[n]||[]).hide=h;" +
             "setTimeout(function(){i();" +
             "h.end=null},c);h.timeout=c; })(" +
             "window,document.documentElement,'async-hide'," +
             "'dataLayer',4000, {'" +
             environment.OPTIMIZE_ID +
             "':true});";
           headHTMLElement.appendChild(script);
         },

         getGoogleId() {
           let firstGoogleClientId = null;
           if (document.cookie.match(/_ga=(.+?);/)) {
             firstGoogleClientId = document.cookie
               .match(/_ga=(.+?);/)[1]
               .split(".")
               .slice(-2)
               .join(".");
           }
           return firstGoogleClientId;
         },
       };
