import React, { useState, useEffect } from 'react';

import NavbarHeader from "../components/NavbarHeader";
import NavBarMenu from "../components/NavBarMenu/NavBarMenu";
import Footer from "../components/Footer";
import { PrismicService } from '../services/prismic';
import Services from "../services";
import ContentSections from "../components/ContentSections/ContentSections";
import FAQResults from "../components/FAQ/Results/FAQResults";
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom'

const PreguntasFrecuentes = ({tenant}) => {

    const getTenant = () => tenant && tenant.tenant;

    const searchUrl = useLocation().search
    const searchUrlParams = new URLSearchParams(searchUrl)
    const producto = searchUrlParams.get('producto')

    const [menu, setMenu] = useState([]);
    const [firstContentSection, setFirstContentSection] = useState({
        sections: [],
        data: [],
        loadingFAQ: true,
    });
    const [secondContentSection, setSecondContentSection] = useState({
        secondSections: [],
        secondData: [],
        loadingContact: true,
    });
    const [search, setSearch] = useState({
        searchText: '',
        searchCategory: '*',
    });

    const changeCategoryFn = (category)=> {
        setSearch({
            ...search,
            searchCategory: category,
        });
    }

    const changeTextFn = (text)=> {
        setSearch({
            ...search,
            searchText: text,
        });
    }
    
    useEffect(() => {
        Services.COMMONS.MENUS.get(getTenant()).then(menuPrismic => setMenu(menuPrismic));
    }, []);

    useEffect(() => {        
        // First content section
        PrismicService.getDocument('faq', [(tenant && tenant.tenant) || 'default']).then(response => {
            const categoria = producto || response.results[0].data.insurance_category_options_faq_results[0].insurance_category_option_name
            changeCategoryFn(categoria);
            setFirstContentSection({
                data: response.results[0].data,
                sections: response.results[0].data.sections,
                loadingFAQ: false,
            });
        });
        // Second content section
        PrismicService.getDocument("contacto", ["sura"]).then(response => {
            setSecondContentSection({
                secondData: response.results[0].data,
                secondSections: response.results[0].data.sections,
                loadingContact: false,
            });
        });

        return () => { };
    }, []);

  
    const { searchText, searchCategory } = search;

    let {sections, data, loadingFAQ} = firstContentSection;
    const { secondSections, secondData, loadingContact } = secondContentSection;
    
    return (
        <React.Fragment>
            <Helmet>
                <title>Preguntas Frecuentes aseguradora SURA / suraenlinea.com</title>
                <meta name="description" content="Aquí te respondemos tus inquietudes sobre los diferentes seguros de nuestra aseguradora SURA: SOAT digital, carro, moto, bicicleta y muchos otros." />
                <meta name="keywords" content="aseguradora sura, soat digital, seguro de carro, seguro de moto, seguro de bicicleta, seguro de vida, seguro de salud, seguros de hogar, soat." />
            </Helmet>
            <NavbarHeader tenant={tenant} tenantName={tenant && tenant.tenant} />
            {menu && <NavBarMenu menus={menu} />}
            {!loadingFAQ && 
            <React.Fragment>
                <ContentSections
                    sections={sections}
                    data={data}
                />
                <FAQResults changeCategoryFn={changeCategoryFn} data={{ data: data }} searchText={searchText} searchCategory={searchCategory}></FAQResults>
            </React.Fragment>}

            {!loadingContact && <ContentSections
                sections={secondSections}
                data={secondData}
            />}
            <Footer />
        </React.Fragment>
    )
}

export default PreguntasFrecuentes;
