export const CATEGORIA_MENU = {
  page: 1,
  results_per_page: 20,
  results_size: 4,
  total_results_size: 4,
  total_pages: 1,
  next_page: null,
  prev_page: null,
  results: [
    {
      id: "X2ktyRAAACUAhoXZ",
      uid: null,
      url: null,
      type: "categoria_menu_suraenlinea",
      href:
        "https://suraenlinea-v2.cdn.prismic.io/api/v2/documents/search?ref=ZddnfxMAACgAJZvI&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X2ktyRAAACUAhoXZ%22%29+%5D%5D",
      tags: [],
      first_publication_date: "2020-09-21T22:48:44+0000",
      last_publication_date: "2020-09-21T22:57:55+0000",
      slugs: ["categoria-menu---inicio"],
      linked_documents: [],
      lang: "es-es",
      alternate_languages: [],
      data: {
        titulodeldocumento: [
          {
            type: "heading1",
            text: "Categoría Menú - Inicio",
            spans: [],
          },
        ],
        nombre_categoria: [
          {
            type: "heading1",
            text: "Inicio",
            spans: [],
          },
        ],
        icono_categoria: {},
        is_collapsable: false,
        href_categoria: "/",
        target: "_self",
        numero_columnas_subcategorias: null,
        body: [],
      },
    },
    {
      id: "X2FAaxAAACYAYv76",
      uid: null,
      url: null,
      type: "categoria_menu_suraenlinea",
      href:
        "https://suraenlinea-v2.cdn.prismic.io/api/v2/documents/search?ref=ZddnfxMAACgAJZvI&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X2FAaxAAACYAYv76%22%29+%5D%5D",
      tags: [],
      first_publication_date: "2020-09-21T22:39:52+0000",
      last_publication_date: "2024-02-08T21:11:08+0000",
      slugs: ["categoria-menu---seguro-para-personas"],
      linked_documents: [],
      lang: "es-es",
      alternate_languages: [],
      data: {
        titulodeldocumento: [
          {
            type: "heading1",
            text: "Categoría Menú - Seguro para personas",
            spans: [],
          },
        ],
        nombre_categoria: [
          {
            type: "heading1",
            text: "Seguro para personas",
            spans: [],
          },
        ],
        icono_categoria: {},
        is_collapsable: true,
        href_categoria: null,
        target: "_self",
        numero_columnas_subcategorias: 4,
        body: [
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Hogar",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-hogar.svg",
                id: "X21WzBEAACEAthel",
                edit: {
                  x: -5,
                  y: 0,
                  zoom: 1.3333333333333333,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 3,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Seguro de Arrendamiento",
                    spans: [],
                  },
                ],
                href_item: "/arrendamiento-digital/sura",
                target_item: "_self",
              },
            ],
            id: "subcategoria$5998f803-47e2-4041-8756-7fc93bd076ac",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Plan Elige",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-plan-elige.svg",
                id: "X2vsVBAAAE4mkuCK",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 3,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Configura tu Seguro Personalizado",
                    spans: [],
                  },
                ],
                href_item: "/plan-elige/",
                target_item: "_self",
              },
            ],
            id: "subcategoria$19234451-710c-40dd-b5d8-676fb9248dbb",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Autos",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-autos.svg",
                id: "X21WzBEAACEAtheo",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 1,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Seguro de Autos",
                    spans: [],
                  },
                ],
                href_item: "/movilidad/sura/seguro-de-carro",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Centro de Servicios",
                    spans: [],
                  },
                ],
                href_item:
                  "https://www.segurossura.com.co/paginas/movilidad/autos/centros-de-servicio/inicio.aspx",
                target_item: "_blank",
              },
            ],
            id: "subcategoria$aa87f327-a9a8-48c9-a63c-29182949957a",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Motos",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-motos.svg",
                id: "X21W6hEAAGj1thgy",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 2,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Seguro de Motos",
                    spans: [],
                  },
                ],
                href_item: "/motos/plan-motos",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Centro de Servicios",
                    spans: [],
                  },
                ],
                href_item:
                  "https://www.segurossura.com.co/paginas/movilidad/motos/centros-de-servicio/motos.aspx",
                target_item: "_self",
              },
            ],
            id: "subcategoria$003cc2c6-90e3-4d98-84f0-7d1b8f2fdaf5",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Seguro de Patineta ",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-patineta.jpg",
                id: "YHdGfRMAALkvIboF",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1.3333333333333333,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 2,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Seguro de Patineta ",
                    spans: [],
                  },
                ],
                href_item:
                  "https://www.segurossura.com.co/paginas/movilidad/bicicleta/inicio.aspx",
                target_item: "_blank",
              },
            ],
            id: "subcategoria$64e35798-c4de-4b4b-81e9-79f3fd0591a0",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Seguro de Bicicleta",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-bicis.svg",
                id: "X21WzBEAAGj1thep",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 2,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Seguro de Bicicleta",
                    spans: [],
                  },
                ],
                href_item:
                  "https://www.segurossura.com.co/paginas/movilidad/bicicleta/inicio.aspx",
                target_item: "_blank",
              },
            ],
            id: "subcategoria$bc2ab6f0-bda7-4cad-9414-b8f6be1726c4",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Estilo de vida",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-digital.svg",
                id: "X21WzREAACIAthew",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 4,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Seguro de Viaje",
                    spans: [],
                  },
                ],
                href_item: "/viajes/sura",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Muévete Libre",
                    spans: [],
                  },
                ],
                href_item: "/sura/muevete-libre",
                target_item: "_self",
              },
            ],
            id: "subcategoria$28fa49da-27b3-4fba-aeb1-88b19f336b46",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "Salud",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-salud.svg",
                id: "X21WzBEAACIAthen",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 3,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Cotiza tu Seguro de Salud",
                    spans: [],
                  },
                ],
                href_item: "/sura/seguro-de-salud-plan-evoluciona",
                target_item: "_self",
              },
            ],
            id: "subcategoria$81e23a1e-16a0-4e6b-9b23-0900496bf776",
            slice_type: "subcategoria",
            slice_label: null,
          },
          {
            primary: {
              titulo_subcategoria: [
                {
                  type: "heading1",
                  text: "SOAT",
                  spans: [],
                },
              ],
              icono_subcategoria: {
                dimensions: {
                  width: 32,
                  height: 32,
                },
                alt: null,
                copyright: null,
                url:
                  "/assets/images/prismic/menu/sel-seguro-soat.svg",
                id: "Y8q6cxEAAKREEfUR",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "#fff",
                },
              },
              numero_columna_submenu: 1,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Cotiza tu SOAT",
                    spans: [],
                  },
                ],
                href_item: "/soat/sura/seguro-obligatorio",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Consulta tu SOAT",
                    spans: [],
                  },
                ],
                href_item: "/soat/sura/consulta-tu-soat",
                target_item: "_self",
              },
            ],
            id: "subcategoria$ec69cea4-ea99-4199-bc96-f9c0e994ef90",
            slice_type: "subcategoria",
            slice_label: null,
          },
        ],
      },
    },
    {
      id: "X2uW2BAAACYAkV5S",
      uid: null,
      url: null,
      type: "categoria_menu_suraenlinea",
      href:
        "https://suraenlinea-v2.cdn.prismic.io/api/v2/documents/search?ref=ZddnfxMAACgAJZvI&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X2uW2BAAACYAkV5S%22%29+%5D%5D",
      tags: [],
      first_publication_date: "2020-09-23T18:41:34+0000",
      last_publication_date: "2021-11-16T16:43:52+0000",
      slugs: ["categoria-menu---reclamaciones"],
      linked_documents: [],
      lang: "es-es",
      alternate_languages: [],
      data: {
        titulodeldocumento: [
          {
            type: "heading1",
            text: "Categoría Menú - Reclamaciones",
            spans: [],
          },
        ],
        nombre_categoria: [
          {
            type: "heading1",
            text: "Reclamaciones",
            spans: [],
          },
        ],
        icono_categoria: {},
        is_collapsable: true,
        href_categoria: null,
        target: "_self",
        numero_columnas_subcategorias: 1,
        body: [
          {
            primary: {
              titulo_subcategoria: [],
              icono_subcategoria: {},
              numero_columna_submenu: 1,
            },
            items: [
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Para Seguro de Autos",
                    spans: [],
                  },
                ],
                href_item: "/vehiculos/reclamaciones",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Para Seguro de Motos",
                    spans: [],
                  },
                ],
                href_item: "/motos/reclamaciones",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Para Seguro de Bicicletas",
                    spans: [],
                  },
                ],
                href_item: "/bicicletas/reclamaciones",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Para SOAT",
                    spans: [],
                  },
                ],
                href_item: "/soat/reclamaciones",
                target_item: "_self",
              },
              {
                nombre_item_subcategoria: [
                  {
                    type: "heading1",
                    text: "Para Seguro de Arrendamiento Digital",
                    spans: [],
                  },
                ],
                href_item:
                  "https://seguros.comunicaciones.sura.com/reclamacion-seguro-arrendamiento-digital",
                target_item: "_self",
              },
            ],
            id: "subcategoria$de0269e1-54b5-43fe-9bb7-55fd26362eae",
            slice_type: "subcategoria",
            slice_label: null,
          },
        ],
      },
    },
    {
      id: "X2uXQRAAAPspkWA4",
      uid: null,
      url: null,
      type: "categoria_menu_suraenlinea",
      href:
        "https://suraenlinea-v2.cdn.prismic.io/api/v2/documents/search?ref=ZddnfxMAACgAJZvI&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X2uXQRAAAPspkWA4%22%29+%5D%5D",
      tags: [],
      first_publication_date: "2020-09-23T18:43:17+0000",
      last_publication_date: "2020-09-25T02:33:20+0000",
      slugs: ["categoria-menu---ayuda"],
      linked_documents: [],
      lang: "es-es",
      alternate_languages: [],
      data: {
        titulodeldocumento: [
          {
            type: "heading1",
            text: "Categoría Menú - Ayuda",
            spans: [],
          },
        ],
        nombre_categoria: [
          {
            type: "heading1",
            text: "Ayuda",
            spans: [],
          },
        ],
        icono_categoria: {
          dimensions: {
            width: 32,
            height: 32,
          },
          alt: null,
          copyright: null,
          url:
            "/assets/images/prismic/menu/question.svg",
          id: "X21WzBEAAGj1them",
          edit: {
            x: 0,
            y: 0,
            zoom: 1,
            background: "#fff",
          },
        },
        is_collapsable: false,
        href_categoria: "/sura/preguntas-frecuentes",
        target: "_self",
        numero_columnas_subcategorias: null,
        body: [],
      },
    },
  ],
  version: "679c7f6",
  license: "All Rights Reserved",
};
