import React from "react";
import { Accordion } from "react-bootstrap";
import {
  CardExtended,
  CardHeaderExtended,
  CardBodyExtended,
  ButtonExtended,
} from "./style";

const AccordionList = ({ identificador, titulo, descripcion }) => (
  <CardExtended>
    <CardHeaderExtended>
      <Accordion.Toggle
        as={ButtonExtended}
        variant='LinkExtended'
        eventKey={identificador}
      >
        {titulo}
      </Accordion.Toggle>
    </CardHeaderExtended>
    <Accordion.Collapse eventKey={identificador}>
      <CardBodyExtended>{descripcion}</CardBodyExtended>
    </Accordion.Collapse>
  </CardExtended>
);

export default AccordionList;
