import SOAT from './soat';
import VIAJES from './viajes';
import COMMONS from './commons';
import SALESFORCE from './salesforce'


export default {
    SOAT, 
    VIAJES, 
    COMMONS,
    SALESFORCE,
}