import React from "react";
import { RowOptionsContent, Titulo } from "./style";

const NavOptionsWeb = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <RowOptionsContent className= "d-none d-md-block d-lg-block" key={index}>
            <NavOptionsButtonContent className="py-3" item={item}></NavOptionsButtonContent>
        </RowOptionsContent>
      ))}
    </>
  );
};

const scrollTo = (idElement) => {
  document.getElementById(idElement).scrollIntoView({ behavior: "smooth" });
};

const NavOptionsButtonContent = ({ item }) => {
  if (item.enlace_a_seccion_interna) {
    return (
      <Titulo onClick={() => scrollTo(item.enlace_a_seccion_interna)}>
        {item.item_name}
      </Titulo>
    );
  }

  if (item.item_link && item.item_link.url) {
    return (
      <Titulo className='text-white' href={item.item_link.url}>
        {item.item_name}
      </Titulo>
    );
  }

  return item.item_name;
};
export default NavOptionsWeb;
