import React from 'react';
import withContentSection from '../../hocs/withContentSection';
import './ContentSection.scss';
import Reasons from '../Reasons/Reasons';
import SliderTypeA from '../Sliders/SliderTypeA/SliderTypeA';
import SliderTypeB from '../Sliders/SliderTypeB/SliderTypeB';
import SliderTypeC from '../Sliders/TypeC';
import Promotions from '../Promotions/Promotions';
import Contact from '../Contact/Contact';
import MediaObjectA from '../MediaObject/MediaObjectA/MediaObjectA';
import MediaObjectB from '../MediaObject/MediaObjectB/MediaObjectB';
import FAQSearch from '../FAQ/Search';
import FAQResults from '../FAQ/Results/FAQResults';
import Cotizador from '../pages/home/Cotizador';
import TypeC from '../MediaObject/TypeC';
import NavOptions from '../NavOptions/NavOptions';
import InfoContact from '../infoContact/infoContact';
import { withNearScreen } from '../../hocs/withNearScreen';
import Accordion from '../Accordion/Accordion';

const stringToComponentMapper = {
  sliderTypeA: SliderTypeA,
  sliderTypeB: SliderTypeB,
  sliderTypeC: SliderTypeC,
  contact: Contact,
  reasons: withNearScreen(Reasons),
  promotions: Promotions,
  MediaObjectA: MediaObjectA,
  MediaObjectB: MediaObjectB,
  faqSearch: FAQSearch,
  faqResults: FAQResults,
  cotizadorHome: Cotizador,
  mediaObjectTypeC: TypeC,
  NavOptions: NavOptions,
  infoContact: InfoContact,
  Accordion: Accordion,
};

function ContentSection(props) {
  const WrappedComponent = withContentSection(
    stringToComponentMapper[props.component]
      ? stringToComponentMapper[props.component]
      : null
  );

  return (
    <React.Fragment>
      <WrappedComponent data={props} />
    </React.Fragment>
  );
}

export default ContentSection;
