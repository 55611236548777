import React from 'react';

const SliderTypeC = ({ data }) => (
  <figure>
    <img
      src={data.data[0].image.url}
      alt={data.data[0].image.alt}
      className='w-100 d-none d-md-block'
    />
    <img
      src={data.data[0].banner_mobile.url}
      alt={data.data[0].image.alt}
      className='w-100 d-md-none'
    />
  </figure>
);

export default SliderTypeC;
