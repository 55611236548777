import React from 'react';
import { RichText } from 'prismic-reactjs';
import classNames from 'classnames/bind';
import Styles from './Reasons.module.scss';
import { Container } from 'react-bootstrap';
import Swiper from 'swiper';
import './reasons.scss';

class Reasons extends React.Component {
  componentDidMount() {
    new Swiper('.swiper-container-reasons', {
      slidesPerView: 4,
      allowSlidePrev: false,
      allowSlideNext: false,
      breakpoints: {
        760: {
          slidesPerView: 1,
          pagination: {
            el: '.swiper-pagination-reasons',
          },
          allowSlideNext: true,
          allowSlidePrev: true,
        },
      },
    });
  }

  render() {
    const { data } = this.props;

    return (
      <section
        style={{ background: data.background }}
        className='pb-5 pt-3 py-md-4'
        ref={this.props.element}
      >
        {this.props.show && (
          <Container>
            <div
              style={{ color: data.colorTitle.color }}
              className={classNames('py-2', Styles.titleSection)}
            >
              {RichText.render(data.title)}
            </div>
            <div className='swiper-container swiper-container-reasons'>
              <div className='swiper-wrapper'>
                {data.data.map((item, index) => (
                  <div
                    className={classNames(
                      'swiper-slide',
                      data.bootstrapCols,
                      item.border,
                      Styles.reasonsContent
                    )}
                    key={`index_${index}`}
                  >
                    <img
                      className={classNames(
                        'mb-2',
                        Styles.reasonsContent__image
                      )}
                      src={item.image.url}
                      alt={item.image.alt}
                    />
                    <div
                      className={`d-block mb-2 ${Styles.reasonsContent__title}`}
                    >
                      {RichText.render(item.title)}
                    </div>
                    <div className={Styles.description}>
                      {RichText.render(item.body)}
                    </div>
                  </div>
                ))}
              </div>
              <div className='swiper-pagination swiper-pagination-reasons'></div>
            </div>
          </Container>
        )}
      </section>
    );
  }
}

export default Reasons;
