import styled from "styled-components";

export const ContainerNav = styled.div`
  width: 100%;
  background-color: var(--primary);
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  a {
    color: var(--white);
  }
  a:hover {
    color: var(--white) !important;
  }
`;
