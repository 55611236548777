import styled from 'styled-components';

export const Title = styled.h2`
  color: var(--primary);
  margin-bottom: 2rem;
  font-family: var(--font-family-secondary);
  font-weight: 600;
`;

export const Card = styled.div`
  border: 1px solid #dee2e6;
`;

export const DivExtend = styled.div`
display:flex;
justify-content: center;
align-items: center;
`;

export const Icono = styled.i`
color: var(--primary);
`;