import React from 'react';
import { FooterContainer, Hr, FooterTerms } from './styles';
import Container from 'react-bootstrap/Container';
import IconSprite from '../StyledShared/IconSprite';
import './reset.scss';
import { useNearScreen } from '../../hooks/useNearScreen';

const Footer = () => {
  const getFullYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  const [show, element] = useNearScreen();

  return (
    <FooterContainer ref={element}>
      {show && (
        <Container>
          <div className='d-flex justify-content-between'>
            <div className='d-none d-md-flex align-items-center'>
              <FooterTerms size='0.875rem' className='m-0 mr-2'>
                Contamos con el certificado de seguridad
              </FooterTerms>
              <IconSprite width='64px' height='43px' position='-349px -2px' />
            </div>
            <div className='d-flex align-items-md-center flex-column flex-md-row text-left'>
              <p className='m-md-0 pr-0 pr-md-2'>Medios de pago:</p>
              <IconSprite width='332px' height='28px' position='-3px -6px' />
            </div>
          </div>
          <Hr />
          <div className='d-flex justify-content-start flex-column flex-md-row'>
            <FooterTerms size='0.8125rem' className='text-center'>
              © {getFullYear()} SURA. Todos los derechos reservados{' '}
              <span className='d-none d-md-inline-block'>|</span>
            </FooterTerms>
            <FooterTerms size='0.8125rem' className='text-center'>
              <a
                href='/terminos-condiciones'
                className='link-terms ml-1 d-block d-md-inline-block'
                target='_self'
              >
                Políticas de uso y seguridad
              </a>{' '}
              <span className='d-none d-md-inline-block'>|</span>{' '}
              <a
                href='/politicas-privacidad'
                className='link-terms d-md-inline-block'
                target='_self'
              >
                Política de privacidad y ley de datos personales
              </a>
            </FooterTerms>
          </div>
        </Container>
      )}
    </FooterContainer>
  );
};

export default Footer;
