 export const replaceKeyByMap = (keysMap, array) => {
  const newArray = [];

  array.map(object => {
    newArray.push(
      Object.keys(object).reduce((acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: object[key] }
      }), {})
    );
  });

  return newArray;
}
  
  