import React from "react";
import { Container } from "react-bootstrap";
import { Title, AccordionExtended } from "./styles";
import { RichText } from "prismic-reactjs";
import AccordionList from "../StyledShared/AccordionList";

const Accordion = ({ data }) => (
  <Container id={data.linkInterno}>
    <Title>{data.title}</Title>
    <AccordionExtended>
      {data.data.map((media, index) => (
        <AccordionList
          key={index}
          identificador={index + 1}
          titulo={media.titulo_pregunta}
          descripcion={RichText.render(media.descripcion_pregunta)}
        />
      ))}
    </AccordionExtended>
  </Container>
);

export default Accordion;
