export const DATALAYERS = {
    push: (eventName, tagName) => {
        const event = {
            'event': eventName,
            'accion': 'click',
            'etiqueta': tagName,
        };
        if (window['dataLayer']) {
            window['dataLayer'].push(event);
        }
    },

    pushObject: (object) => {
        if (window['dataLayer']) {
            window['dataLayer'].push(object);
        }
    },
};