
const BASE_URL = 'https://apilab.suraenlinea.com'

async function asyncCallApi(endpoint, options = {}) {
	options.headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	};
	const url = BASE_URL + endpoint;
	const response = await fetch(url, options);
	const data = await response.json();

	return data;
}

async function asyncApiGet(endpoint) {
	try {
		const data = await asyncCallApi(endpoint, { method: 'GET' });
		return data;
	} catch (error) {}
}
const API = {
	asyncGet(endpoint) {
			return asyncApiGet(endpoint);
		}
};

export default API