import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ContainerNav } from'./style';
 
import NavOptionsWeb from './NavOptionsWeb';
import NavOptionsMobile from './NavOptionsMobile';
const NavOptions = ({data}) => {
    return(
        <Container fluid={true}> 
            <Row>
                <NavOptionsMobile data={data.data} />
                <ContainerNav><NavOptionsWeb data={data.data} /></ContainerNav>
            </Row>
        </Container>
    );
}
 
export default NavOptions;
