import React, { useState } from 'react';
import {
  Container,
  ContainerSchedule,
  ScheduleService,
  ButtonClose,
  ModalTitle,
} from './styles';
import { Modal } from 'react-bootstrap';
import './reset.scss';
import IconFontAwesome from '../StyledShared/IconFontAwesome';
import { ALink } from '../StyledShared/Link/styles';


const SpeakWithUs = () => {

  const [show, setShow] = useState(false);
  
  return(
    <Container className="text-center d-md-none">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdropClassName="speak-with-us-backdrop"
        dialogClassName="speak-with-us"        
      >
        <Modal.Header className="py-3 border-0 justify-content-center position-relative">
          <ModalTitle className="m-0">Habla con nosotros</ModalTitle>
          <ButtonClose 
            onClick={() => setShow(false)}
            className="position-absolute"
          >
            <IconFontAwesome className="fas fa-times" size={1.3} color="var(--primary)" />
          </ButtonClose>
        </Modal.Header>
        <Modal.Body className="text-center py-0 px-4">
          <ALink href="tel:3003870060" variant="secondary" size={1.1875} className="w-100">Ventas</ALink>
          <ContainerSchedule className="py-2">
            <p className="m-0"><b>Lunes a Viernes:</b> 8:00 a. m. a 5:00 p. m.</p>
            <p className="m-0"><b>Sábados:</b> 9:00 a. m. a 12:00 m. (excepto festivos)</p>
          </ContainerSchedule>
          <ALink href="tel:%23 888" variant="secondary" size={1.1875} className="w-100">Atención al cliente</ALink>
          <ScheduleService className="my-2">24/7</ScheduleService>
        </Modal.Body>
      </Modal>
  </Container>
  )
};

export default SpeakWithUs;
