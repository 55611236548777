import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import classNames from 'classnames/bind';
import Styles from "./MenuDiv.module.scss";
import "./MenuDiv.scss";
import { DATALAYERS } from "../../services/datalayers";


const MenuDiv = ({ menu, index, position }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(!show);

  const handleShowMobile = () => {
    const { innerWidth: width } = window;
    if ( width >= 500 ){
      setShow(!show);
    }
  }

  const handleCloseWithEvent = (e) => {
    if (
      e.nativeEvent.offsetY < 0 ||
      e.nativeEvent.offsetX < 0 ||
      e.nativeEvent.fromElement.clientWidth < e.nativeEvent.offsetX
    ) {
      setShow(false);    
    }
  }

  const pushDatalayer = (tagName) => DATALAYERS.push('Interaccion-menu', tagName);
  if (menu.groupSubMenus && menu.groupSubMenus.length !== 1 ) {
    return (
      <div 
        onMouseEnter={handleShowMobile}
        onMouseLeave={handleCloseWithEvent} 
        className={classNames('text-left text-lg-center', Styles.flexFill, menu.active ? Styles.flexFill_active : null)}
        >
        <Button
          onClick={() => {
            handleShow();
            pushDatalayer(menu.caption)
          }}

          className={classNames("py-2 text-center", Styles.decoration)}
          variant="link"
          key={`_button_menu_${index}`}
        >
          {menu.icon && (<img className={Styles.iconCategory} src={menu.icon} />)}
          <span className={classNames(Styles.title, Styles.arrow, show ? Styles.btnLink : null)}>{menu.caption}</span>
        </Button>
        <div
          className={classNames(Styles.menu, show ? 'd-block' : 'd-none')}
          onMouseLeave={handleClose}
        >
          <Container className="px-0 px-lg-3 py-4">
            <Row className="justify-content-around">
              {menu.groupSubMenus.map((group, indexGroup) => (
                <Col className="col-lg-3 col-12" key={`indexGroup_${indexGroup}`}>
                  {group.map((columnGroup, columnIndex) => (
                    <div className="mb-4" key={`columnIndex_${columnIndex}`}>
                      <h3 className={classNames('d-flex align-items-center mb-lg-2 mb-sm-0 mb-md-0', Styles.titleMenu)}><img className="mr-2" src={columnGroup.icon} />{columnGroup.caption}</h3>
                        <ul className={classNames("pl-lg-4 m-0 p-sm-0",Styles.ul)}>
                          {columnGroup.subMenus.map((subGroup, subIndex) => (                            
                            <li key={`subIndex_${subIndex}`}>
                              <a onClick={() => pushDatalayer(subGroup.caption)} target={subGroup.target} href={subGroup.link} className={classNames("pl-sm-4 pl-lg-2",Styles.link)}>
                                {subGroup.caption}
                              </a>
                            </li>
                          ))}
                        </ul>
                    </div>
                  ))}
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  if (menu.groupSubMenus && menu.groupSubMenus.length === 1) {
    return (
      <div className={classNames('text-left text-lg-center', Styles.flexFill, Styles.decoration, menu.active ? Styles.flexFill_active : null)}>
        <Button
          onClick={() => {
            handleShow();
            pushDatalayer(menu.caption)
          }}
          onMouseEnter={handleShowMobile}
          onMouseLeave={handleCloseWithEvent}
          className={classNames("py-2 text-center", Styles.decoration)}
          variant="link"
          key={`_button_menu_${index}`}
        >
          {menu.icon && (<img className={Styles.iconCategory} src={menu.icon} />)}
          <span className={classNames(Styles.title, Styles.arrow, show ? Styles.btnLink : null)}>{menu.caption}</span>
        </Button>
        <div
          className={classNames(Styles.menu, Styles.menu_dropdown, Styles.contentDropdown, show ? 'd-block' : 'd-none')}
          onMouseLeave={handleClose}
        >
            <ul className={Styles.ul}>
              {menu.groupSubMenus[0][0].subMenus.map((subMenu, indexSubMenu) => ( 
                <li key={`indexSubMenu_${indexSubMenu}`}>
                  <a onClick={() => pushDatalayer(subMenu.caption)} target={`_${subMenu.target}`} href={subMenu.link} className={Styles.link}>
                    {subMenu.caption}
                  </a>
                </li>
              ))}
            </ul>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(`text-left ${ position ? `text-lg-${ position }`:"text-lg-center"}`, Styles.flexFill, menu.active ? Styles.flexFill_active : null)}>
      <Button
        className={classNames('py-2 text-center', Styles.btnMenu, Styles.decoration, menu.active && Styles.navActive)}
        variant="link"
        key={`_button_menu_${index}`}
      >
        {menu.icon && <img className={Styles.iconCategory} src={menu.icon} />}
        {menu.iconFa && <i className={`pr-1 ${menu.iconFa}`}></i>}
        <a target={`_${menu.target}`} href={menu.link} className={Styles.title}>{menu.caption}</a>
      </Button>
    </div>
  );
}

export default MenuDiv;
