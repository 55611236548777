import React from 'react';
import ReactDOM from 'react-dom';
import Router from './commons/Router';
import { Provider } from 'react-redux';
import store from './redux/store';
import SpeakWithUs from './components/SpeakWithUs';
import ChatbotTibot from './components/ChatbotTibot';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'swiper/dist/css/swiper.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import './styles.scss';

import '@segurosvoluntarios_vel/wc/dist/sura-cookie';

const element = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <SpeakWithUs />
    <sura-cookie></sura-cookie>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
    <ChatbotTibot />
  </Provider>,
  element
);
