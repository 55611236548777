import styled from 'styled-components';

export const Content = styled.div`
  max-width: 1077px;  
  position: relative;
  margin: auto;
  margin-right: 0;
  padding-top: 12rem;
  @media (min-width: 768px) {
    padding-top: 0;
  }
`;

export const ImageFloat = styled.img`
  position: absolute;
  top: -2rem;
  max-width: 82%;
  max-height: 22.5rem;
  z-index: 0;
  @media (min-width: 576px) {
    z-index: 1;
    left: -2rem;
    bottom: 2rem;
  }
  @media (min-width: 768px) {
    max-width: 44%;
  }
`;

export const ContainerList = styled.div`
  font-family: var(--font-family-secondary);
  position: relative;
  background-color: #f5f5f5;
  margin: auto;
  margin-right: 0;
  z-index: 1;
  max-width: 92%;
  @media (min-width: 768px) {
    max-width: 100%;
    z-index: 0;
  }
`;

export const Title = styled.h2`
  color: var(--primary);
  font-weight: 600;
`;

export const ContainerIcon = styled.div`
  min-width: 5rem;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

export const ItemParagraph = styled.div`
  color: #727272;
  p {
    margin: 0 !important;
  }
`;
