export const MENU_DOCUMENT = {
  page: 1,
  results_per_page: 20,
  results_size: 1,
  total_results_size: 1,
  total_pages: 1,
  next_page: null,
  prev_page: null,
  results: [
    {
      id: "X2EzYxAAACUAYsU3",
      uid: null,
      url: null,
      type: "menu_suraenlinea",
      href:
        "https://suraenlinea-v2.cdn.prismic.io/api/v2/documents/search?ref=ZdOe_BEAACQA2geX&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X2EzYxAAACUAYsU3%22%29+%5D%5D",
      tags: ["sura", "promociones-en-seguros"],
      first_publication_date: "2020-09-21T22:39:52+0000",
      last_publication_date: "2024-02-12T14:57:33+0000",
      slugs: ["menu-suraenlinea", "seguro-para-personas"],
      linked_documents: [],
      lang: "es-es",
      alternate_languages: [],
      data: {
        titulodeldocumento: [
          {
            type: "heading1",
            text: "Menú Suraenlinea",
            spans: [],
          },
        ],
        nombre_producto_menu: "SEGUROS",
        url_producto_menu: "/",
        categorias_menu: [
          {
            categoria: {
              id: "X2ktyRAAACUAhoXZ",
              type: "categoria_menu_suraenlinea",
              tags: [],
              lang: "es-es",
              slug: "categoria-menu---inicio",
              first_publication_date: "2020-09-21T22:48:44+0000",
              last_publication_date: "2020-09-21T22:57:55+0000",
              link_type: "Document",
              isBroken: false,
            },
          },
          {
            categoria: {
              id: "X2FAaxAAACYAYv76",
              type: "categoria_menu_suraenlinea",
              tags: [],
              lang: "es-es",
              slug: "categoria-menu---seguro-para-personas",
              first_publication_date: "2020-09-21T22:39:52+0000",
              last_publication_date: "2024-02-08T21:11:08+0000",
              link_type: "Document",
              isBroken: false,
            },
          },
          {
            categoria: {
              id: "X2uW2BAAACYAkV5S",
              type: "categoria_menu_suraenlinea",
              tags: [],
              lang: "es-es",
              slug: "categoria-menu---reclamaciones",
              first_publication_date: "2020-09-23T18:41:34+0000",
              last_publication_date: "2021-11-16T16:43:52+0000",
              link_type: "Document",
              isBroken: false,
            },
          },
          {
            categoria: {
              id: "X2uXQRAAAPspkWA4",
              type: "categoria_menu_suraenlinea",
              tags: [],
              lang: "es-es",
              slug: "categoria-menu---ayuda",
              first_publication_date: "2020-09-23T18:43:17+0000",
              last_publication_date: "2020-09-25T02:33:20+0000",
              link_type: "Document",
              isBroken: false,
            },
          },
        ],
      },
    },
  ],
  version: "679c7f6",
  license: "All Rights Reserved",
};
