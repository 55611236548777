

export const presentecomTenant = {
  name: 'presentecom',
  properties: {
    // Primary colors
    '--primary': '#fee82f',
    '--secondary': '#808183',
    '--tertiary': '#b23918',
    '--quarter': '#1d237c',
    '--error': 'red',
    '--nav-bar': '#fff',

    /* Buttons */
    // Primary
    '--color-button-primary': '#fff',
    '--bg-color-button-primary': '#9fc54d',
    '--hover-color-button-primary': '#fff',
    '--hover-bg-color-button-primary': '#808183',
    // Secondary
    '--color-button-secondary': '#fff',
    '--bg-color-button-secondary': '#9fc54d',
    '--hover-color-button-secondary': '#fff',
    '--bg-hover-color-button-secondary': '#808183',
    // Tertiary
    '--color-button-tertiary': '#fff',
    '--bg-color-button-tertiary': '#9fc54d',
    '--hover-color-button-tertiary': '#fff',
    '--bg-hover-color-button-tertiary': '#808183',

    // Forms
    '--color-input-valid': '#00aec7',

    // Fonts
    '--primary-semibold-font-family': 'HelveticaNeueBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#fee82f',
    '--color-breadcrumb': '#808183',
    '--color-breadcrumb-sucess': '#00aec7',
    '--logo': 'url(../../../../assets/images/tenants/presentecom/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--primary)',
  }
};
