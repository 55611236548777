const mockGraphqlData = [
  {
    flow: "SEGURO-OBLIGATORIO",
    steps: [
      {
        name: "step1",
        title: "Step 1",
        caption: "",
        template: "FlowWithContent",
        handleSubmit: "QuoteSOAT",
        columns: "1",
        sections: [
          {
            name: "initialInfo",
            caption: "Tu SOAT en 3 PASOS",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "firstName",
                component: "input",
                caption: "Nombre",
                initialValue: "Juan",
                validationRule: "^(?!\\s*$).+",
                placeholder: "Nombre",
                size: "1",
                classNameStyle: "olnyText",
                maxLength: 60
              },
              {
                name: "licensePlate",
                component: "input",
                caption: "Placa",
                initialValue: "asd123",
                validationRule:
                  "^[a-zA-Z]{1,3}[0-9]{1,2}[a-zA-Z0-9]{1,2}|[0-9]{3}[a-zA-Z]{3}$",
                placeholder: "AAA123",
                size: "1",
                classNameStyle: "LicencePlate",
                maxLength: 6
              }
            ]
          }
        ]
      },
      {
        name: "step2",
        title: "Step 2",
        caption: "Tu SOAT en 3 PASOS",
        template: "FlowWithResume",
        handleSubmit: "ValidateSOAT",
        columns: "2",
        sections: [
          {
            name: "vehiculeInfo",
            caption: "Datos del Vehículo",
            collapsible: "true",
            collapse: "false",
            fields: [
              {
                name: "Model",
                component: "input",
                caption: "Modelo",
                initialValue: "",
                validationRule: "^[0-9]{1,4}$",
                placeholder: "AAAA",
                size: "1",
                maxLength: 4
              },
              {
                name: "servicesType",
                component: "select",
                caption: "Tipo de Servicio",
                size: "1"
              },
              {
                name: "vehicleClasses",
                component: "select",
                caption: "Clase",
                size: "1"
              },
              {
                name: "fuelType",
                component: "select",
                caption: "Tipo de Combustible",
                options: [
                  { value: "GAS", title: "Gas" },
                  { value: "GASOLINA", title: "Gasolina" }
                ],
                size: "1"
              }
            ]
          },
          {
            name: "HolderPolicy",
            caption: "Datos del Tomador",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "docType",
                component: "select",
                caption: "Tipo de Documento",
                options: [
                  { value: "CC", title: "Cedula" },
                  { value: "NIT", title: "Nit" }
                ],
                initialValue: "CC",
                size: "1"
              },
              {
                name: "firstName",
                component: "input",
                caption: "Nombres",
                size: "1"
              },
              {
                name: "lastName",
                component: "input",
                caption: "Apellido",
                size: "1"
              },
              {
                name: "city",
                component: "input",
                caption: "Ciudad",
                size: "1"
              },
              {
                name: "address",
                component: "input",
                caption: "Dirección",
                size: "1"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    flow: "VIAJES",
    steps: [
      {
        name: "step1",
        title: "Step 1",
        caption: "¡Compra tu Seguro de Viaje!",
        template: "FlowWithContent",
        handleSubmit: "QuoteTrip",
        columns: "1",
        sections: [
          {
            name: "QuoteTrip",
            caption: "",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "typeTrip",
                component: "radio",
                caption: "Tipo de Viaje",
                options: [
                  { value: "AEREO", title: "Aéreo" },
                  { value: "TERRESTRE", title: "Terrestre" }
                ],
                initialValue: "AEREO",
                size: "2"
              },
              {
                name: "passengers",
                component: "inputNumberIncrement",
                caption: "Viajeros",
                placeholder: "Viajeros",
                initialValue: "",
                validationRule: "^(?!\\s*$).+",
                size: "2"
              }
            ]
          },
          {
            name: "whereQuoteTrip",
            caption: "",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "vehicleClasses",
                component: "select",
                caption: "Origen",
                size: "2"
              },
              {
                name: "vehicleClasses",
                component: "select",
                caption: "Destino",
                size: "2"
              }
            ]
          }
        ]
      },
      {
        name: "step2",
        title: "Step 2",
        caption: "VIAJES",
        template: "FlowWithResume",
        handleSubmit: "QuoteSOAT",
        columns: "1",
        sections: [
          {
            name: "passengers",
            caption: "Viajeros",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "addressLine1",
                component: "input",
                caption: "Address Line 1"
              },
              {
                name: "addressLine2",
                component: "input",
                caption: "Address Line 2"
              },
              {
                name: "city",
                component: "input",
                caption: "City"
              },
              {
                name: "state",
                component: "input",
                caption: "state"
              },
              {
                name: "zip",
                component: "input",
                caption: "Zip"
              },
              {
                name: "monthOfMove",
                component: "input",
                caption: "Month/Year of move there",
                type: "month"
              },
              {
                name: "type",
                component: "radio",
                caption: "Type",
                options: [
                  { value: "OWNED", title: "Owned" },
                  { value: "RENTED", title: "Rented" },
                  { value: "OTHER", title: "Other" }
                ],
                initialValue: "OWNED"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    flow: "MIA",
    steps: [
      {
        name: "step1",
        title: "Step 1",
        caption: "¡Hola! estás a unos pasos de asegurar lo que más quieres",
        template: "FlowWithContent",
        handleSubmit: "QuoteSOAT",
        columns: "1",
        sections: [
          {
            name: "policyHolder",
            caption: "Dejanos Conocerte",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "name",
                component: "input",
                caption: "Nombre",
                placeholder: "Nombre",
                initialValue: "",
                size: "1"
              },
              {
                name: "email",
                component: "input",
                type: "email",
                caption: "Correo",
                placeholder: "Correo",
                initialValue: "",
                size: "1"
              }
            ]
          }
        ]
      },
      {
        name: "step2",
        title: "Step 2",
        caption: "Elije a los que quieres asegurar",
        template: "FlowWithResume",
        handleSubmit: "QuoteSOAT",
        columns: "1",
        sections: [
          {
            name: "risksPeople",
            caption:
              "¡Tu ya estas incluido en el plan, puedes incluir a 4 personas que quiereas y 2 mascotas (perro o gato)!",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "me",
                component: "input",
                type: "checkbox",
                caption: "yo",
                placeholder: "",
                initialValue: "",
                validationRule: "^(?!\\s*$).+",
                size: "3"
              },
              {
                name: "mate",
                component: "input",
                type: "checkbox",
                caption: "Pareja",
                size: "3"
              },
              {
                name: "childrens",
                component: "inputNumberIncrement",
                type: "text",
                caption: "Hijos",
                size: "3"
              },
              {
                name: "mather",
                component: "input",
                type: "checkbox",
                caption: "Mamá",
                size: "3"
              },
              {
                name: "father",
                component: "input",
                type: "checkbox",
                caption: "Padre",
                size: "3"
              },
              {
                name: "brothers",
                component: "inputNumberIncrement",
                caption: "Hermanos",
                size: "3"
              },
              {
                name: "dog",
                component: "input",
                type: "checkbox",
                caption: "Perro",
                size: "3"
              },
              {
                name: "cat",
                component: "input",
                type: "checkbox",
                caption: "Gato",
                size: "3"
              },
              {
                name: "other",
                component: "input",
                type: "checkbox",
                caption: "Otros",
                size: "3"
              }
            ]
          }
        ]
      },
      {
        name: "step3",
        title: "Step 3",
        caption: "Vehículos que quieres asegurar",
        template: "FlowWithResume",
        handleSubmit: "QuoteSOAT",
        columns: "1",
        sections: [
          {
            name: "risksMobility1",
            collapsible: "false",
            collapse: "false",
            fields: [
              {
                name: "typeMobility1",
                component: "input",
                caption: "Tipo",
                size: "1"
              },
              {
                name: "placaMobility1",
                component: "input",
                caption: "placa",
                size: "1"
              },
              {
                name: "ownerMobility1",
                component: "input",
                caption: "Propietario",
                size: "1"
              }
            ]
          },
          {
            name: "risksMobility2",
            caption: "Otros Vehiculos",
            collapsible: "true",
            collapse: "false",
            fields: [
              {
                name: "typeMobility2",
                component: "input",
                caption: "Tipo",
                size: "1"
              },
              {
                name: "placaMobility2",
                component: "input",
                caption: "placa",
                size: "1"
              },
              {
                name: "ownerMobility2",
                component: "input",
                caption: "Propietario",
                size: "1"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    flow: "seguro-hogar",
    steps: {
      title: 'aaaaa',
      form: {
          name: 1,
          fields: [
            {
              name: "firstName",
              type: "text",
              caption: "Nombre",
              value: "",
              validationRule: "^(?!\\s*$).+",
              placeholder: "Nombre",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },
            {
              name: "lastName",
              type: "text",
              caption: "Nombre",
              value: "",
              validationRule: "^(?!\\s*$).+",
              placeholder: "Nombre",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },
            
          ]
        },
    }
  },
  {
    flow: "home",
    steps: {
      title: 'aaaaa',
      form: [
        {
          name: 1,
          fields: [
            {
              name: "firstName",
              component: "input",
              caption: "Nombre",
              initialValue: "Juan",
              validationRule: "^(?!\\s*$).+",
              placeholder: "Nombre",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },
            {
              name: "lastName",
              component: "input",
              caption: "Apellido",
              initialValue: "Juan",
              validationRule: "^(?!\\s*$).+",
              placeholder: "apellido",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },

            {
              name: "phone",
              component: "input",
              caption: "adress",
              initialValue: "Juan",
              validationRule: "^(?!\\s*$).+",
              placeholder: "telefono",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },
            {
              name: "docType",
              component: "select",
              caption: "Tipo de Documento",
              options: [
                { value: "CC", title: "Cedula" },
                { value: "NIT", title: "Nit" },
                { value: "PASAPORTE", title: "Pasaporte" }
              ],
              initialValue: "CC",
              size: "1"
            }
          ]
        },
        {
          name: 2,
          fields: [
            {
              name: "firstName",
              component: "input",
              caption: "Nombre",
              initialValue: "Juan",
              validationRule: "^(?!\\s*$).+",
              placeholder: "Nombre",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },
            {
              name: "lastName",
              component: "input",
              caption: "Apellido",
              initialValue: "Juan",
              validationRule: "^(?!\\s*$).+",
              placeholder: "apellido",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },
            {
              name: "phone",
              component: "input",
              caption: "adress",
              initialValue: "Juan",
              validationRule: "^(?!\\s*$).+",
              placeholder: "telefono",
              size: "1",
              classNameStyle: "olnyText",
              maxLength: 60
            },
            {
              name: "docType",
              component: "select",
              caption: "Tipo de Documento",
              options: [
                { value: "CC", title: "Cedula" },
                { value: "NIT", title: "Nit" },
                { value: "PASAPORTE", title: "Pasaporte" }
              ],
              initialValue: "CC",
              size: "1"
            }
          ]
        },
      ]
    }
  }
];

export default mockGraphqlData;
