

export const flypassTenant = {
  name: 'flypass',
  properties: {
    // Primary colors
    '--primary': '#154a96',
    '--secondary': '#fc9a18',
    '--tertiary': '#ededed',
    '--quarter': '#0033a0',
    '--error': 'red',
    '--nav-bar': '#154a96',

    //Navbar
    '--color-text-nav-bar': 'white',

    /* Buttons */
    // Primary
    '--color-button-primary': '#000000',
    '--bg-color-button-primary': 'var(--secondary)',
    '--hover-color-button-primary': 'var(--primary)',
    '--hover-bg-color-button-primary': '#fc9a18',
    // Secondary
    '--color-button-secondary': 'var(--primary)',
    '--bg-color-button-secondary': 'var(--secondary)',
    '--hover-color-button-secondary': 'var(--primary)',
    '--bg-hover-color-button-secondary': 'var(--secondary)',
    // Tertiary
    '--color-button-tertiary': 'var(--primary)',
    '--bg-color-button-tertiary': 'var(--secondary)',
    '--hover-color-button-tertiary': 'var(--primary)',
    '--bg-hover-color-button-tertiary': 'var(--secondary)',

    // Forms
    '--color-input-valid': '#00aec7',

    // Fonts
    '--primary-semibold-font-family': 'FSJoeySemiBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#ffffff',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#63ba2f',
    '--logo': 'url(../../../../assets/images/tenants/flypass/logo.png)',

    // Footer
    '--footer-color': '#ffffff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--primary)',

    // FAQ
    '--color-text-question-title': '#ffff',
    '--background-color-question-title': '#797979',
  }
};
