import React, { useEffect, useState } from 'react';
import Services from "../services";
import NavbarHeader from "../components/NavbarHeader";
import NavBarMenu from "../components/NavBarMenu";
import { environment } from "../environments/environment";
import pageError from "../assets/images/sel-pagina-error-ups.png"
import comodo from "../assets/images/comodo.png"
import personFind from "../assets/images/sel-personaje-404.gif"

const NoFound = () => {
    const tenantName = "sura"
    const [menu, setMenu] = useState([]);
    useEffect(() => {
        Services.COMMONS.MENUS.get(tenantName).then((menuPrismic) =>
            setMenu(menuPrismic)
        );
    }, []);
    return (<React.Fragment>
        <NavbarHeader />
        {menu && <NavBarMenu menus={menu} />}
        <section class="pageNotFound__body">
            <div class="info">
                <img src={pageError} alt="Image ups" />
                <img src={personFind} alt="Image personaje" />
                <h3 class="title">¡Hemos realizado una búsqueda completa!</h3>
                <p class="text margin-bottom">Sin embargo, esta página ya no existe.</p>
                <p class="containerText text">Visita nuestra página de <a href={environment.suraPath}>inicio</a> o infórmanos este error 404 <span class="clr"></span> en servicio de atención al cliente marcando desde tu celular al <strong>#888.</strong></p>
            </div>
        </section>

        <div class="pageNotFound__footer">
            <div className="pageNotFound__footerContainer">
                <div class="pageNotFound__footerContent">
                    <p>Para resolver dudas, comentarios y sugerencias, comunícate a la línea de atención en Medellín al 604 4378888, en Bogotá al 601 4278888 y en Cali al 602 4378888; al 01 8000 51 8888 en el resto del país o al #888 sin costo desde tu celular. Se recomienda ver esta página con Internet Explorer 10 (o versiones superiores), Mozilla Firefox o Google Chrome a una resolución mínimo de 1024 x 768. Copyright c 2014. SURA, una marca Suramericana. Todos los derechos reservados. Entiéndase Suramericana como Administrador de Carteras Colectivas Suramericana S.A., Seguros Generales Suramericana S.A. y Seguros de Vida Suramericana S.A.</p>
                    <ul class="pageNotFound__infoList">
                        <li><a href={`${environment.suraPath}/terminos-condiciones`}>Políticas de uso y seguridad</a></li>
                        <li><a href={`${environment.suraPath}/politicas-privacidad`}>Políticas de Privacidad Ley de Datos Personales</a></li>
                        <li><a href={`${environment.suraPath}/empresas-relacionadas`}>Empresas Relacionadas</a></li>
                    </ul>
                </div>
                <div class="pageNotFound__footerSecurity">
                    <div class="pageNotFound__subcontainer">
                        <p>Navega tranquilo. Contamos con el certificado de seguridad:</p>
                        <a
                            href="javascript:void(window.open('https://secure.comodo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SC2&v_search=www.suraenlinea.com&x=6&y=5','','width=450,height=530,left=0,top=0,resizable=yes,menubar=no,location=no,status=yes,scrollbars=yes'))"
                            id="abrir_certificado">
                            <img src={comodo}
                                alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security" />
                        </a>
                        <span class="ico-camaraDeComercio"></span>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
}

export default NoFound;
