import styled from 'styled-components';

export const ContainerIcon = styled.div`
  min-width: 5rem;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

export const ItemParagraph = styled.div`
  font-family: var(--font-family-secondary);
  color: #727272;
  p {
    margin: 0 !important;
  }
`;
