export const SALESFORCE_PRODUCT = Object.freeze({
  // Revisar códigos porque en el documento de encuentras múltiples codigos
  HOGAR: '01td0000000n9X6AAI',
  EXEQUIAS: '01td0000000n9XQAAY',
  VIDA: '01td0000000n9XLAAY',
  AUTOS_POR_USO: '01td0000000n9XBAAY',
  SALUD_EVOLUCIONA: '01t0V000004sx9OQAQ',
  PATINETAS: '01t0V0000056nUZQAY',
  MUEVETE_LIBRE: '01td000000366WHAAY',
});
