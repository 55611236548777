export default Object.freeze({
  production: true,
  GTM: 'GTM-KWGB49',
  UA: 'UA-3571024-60',
  OPTIMIZE_ID: 'GTM-ML7FV69',
  suraApi: 'https://api.suraenlinea.com',
  suraPort: 443,
  suraTimeout: '40000',
  pagosApi: 'https://suraenlineaservicios.sura.com/ms-pagos/pago',
  pagosPort: 443,
  integradorApi: 'https://suraenlineaservicios.sura.com/ohs-ventas-digitales',
  integradorPort: 443,
  suraPath: 'https://www.suraenlinea.com',
  flujoVentaApi: 'https://suraenlineaservicios.sura.com/ms-flujo-ventas-digitales',
  flujoVentaPort: 443,
  twitterOembedApi: 'https://api.twitter.com/1/statuses/oembed.json',
  prismicApi: 'https://suraenlinea-v2.cdn.prismic.io/api/v2',
  googlereCAPTCHAKey: '6LfLzVcUAAAAAKe-UvVbGicBSh4P1VLW7WcWv-uV',
  googlereCAPTCHAKeyInvisible: '6LdRpKEUAAAAAGk0ChfHBJIj9_oVBZE1iFU3k9m-',
  googlereCAPTCHAV3Key: '6Lero6gZAAAAAJWti1TA3grfR98XxBIOQigmXU1G',
  prismicToken: 'MC5YQ1VZdFJBQUFETUFMX3VL.77-977-9Xe-_vQnvv71o77-977-9Fu-_ve-_vUEoAu-_vTMw77-977-9CSvvv71d77-977-977-977-9FG3vv73vv70',
  endpointSalesforce: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
  oidSalesforce: '00Dd0000000c6Xg',
  urlApiMaestros: 'https://suraenlineaservicios.sura.com/maestros',
  webchatTibotScript:
    'https://parly-webchat-suraco-mastertibot.1jp7r741wpkb.us-east.codeengine.appdomain.cloud/arl/js/chat-control.js?bot=suraenlinea',
  webchatTibotLogo:
    'https://parly-webchat-suraco-mastertibot.1jp7r741wpkb.us-east.codeengine.appdomain.cloud/arl/images/logo.png',
  tenants: [
    {
      tenant: 'sura',
      hostname: 'www.suraenlinea.com',
      googleTagManager: {
        GTM: 'GTM-KWGB49',
        globalSite: 'UA-3571024-60',
        UA: 'UA-3571024-60',
        optimizeId: 'GTM-ML7FV69'
      },
      facebookPixel: {
        code: '380531805706245',
        facebookEvents: ['PageView']
      },
      url: '/',
      codigoCanal: '7771',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: true,
        motos: true,
        bicicletas: true,
        creditoProtegido: true,
        proteccionLegal: true,
        seguroDeViaje: true,
        soat: true,
        proteccionDigitalPersonas: true,
        planEligeSura: false,
        perfilDigital: false,
        preguntasFrecuentes: true,
      }
    },
    {
      tenant: 'empleadossura',
      hostname: '',
      googleTagManager: {
        GTM: 'GTM-KWGB49',
        globalSite: 'UA-3571024-60',
        UA: 'UA-3571024-60',
        optimizeId: 'GTM-ML7FV69'
      },
      facebookPixel: {
        code: '380531805706245',
        facebookEvents: ['PageView']
      },
      url: '/v2/empleadossura/soat/seguro-obligatorio',
      codigoCanal: '12538',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'exito',
      hostname: 'https://www.segurosexito.com/',
      googleTagManager: {
        GTM: 'GTM-WDQMT2Z',
        UA: '',
        optimizeId: ''
      },
      facebookPixel: {
        code: '1002719383197429',
        facebookEvents: ['PageView', 'AddToCart', 'Lead', 'AddPaymentInfo', 'Purchase']
      },
      url: '/v2/exito/soat/seguro-obligatorio',
      codigoCanal: '9462',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: true,
        motos: true,
        bicicletas: false,
        creditoProtegido: true,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      }
    },
    {
      tenant: 'clickam',
      hostname: 'https://www.segurosexito.com/',
      googleTagManager: {
        GTM: '',
        UA: '',
        optimizeId: ''
      },
      facebookPixel: {
        code: '1002719383197429',
        facebookEvents: ['PageView', 'AddToCart', 'Lead', 'AddPaymentInfo', 'Purchase']
      },
      userVoice: 'qvTt3wLRJ3FqivEdmXlRHw',
      url: '/v2/clickam/soat/seguro-obligatorio',
      codigoCanal: '15031',
      codigoAsesor: '4999',
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        preguntasFrecuentes: true,
      }
    },
    {
      tenant: 'exitocom',
      hostname: 'https://www.exito.com/',
      url: '',
      codigoCanal: '9461',
      codigoAsesor: '4999',
      googleTagManager: {
        GTM: 'GTM-W53Z8G',
        UA: 'UA-3571024-79',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'cdiscount',
      hostname: '',
      googleTagManager: {
        GTM: 'GTM-W53Z8G',
        UA: 'UA-3571024-79',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      url: '/cdiscount',
      index: 'indexCdiscount',
      codigoCanal: '9461',
      codigoAsesor: '',
      restricciones: ['twitter', 'empresas', 'vehiculos', 'bicicletas', 'credito-protegido', 'soat.asesor',
        'menu.blog', 'admin', 'chat', 'soat.promociones', 'vehiculos.promociones', 'soat.tarifas',
        'soat.compra-pago', 'soat.historico-pagos', 'seguro-digital', 'cyber'
      ],
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'bancolombia',
      hostname: 'https://www.grupobancolombia.com',
      googleTagManager: {
        GTM: 'GTM-KQDGN3',
        UA: '',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      url: '/v2/bancolombia/soat/seguro-obligatorio',
      index: 'indexBancolombia',
      codigoCanal: '14619',
      codigoAsesor: '9993',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      }
    },
    {
      tenant: 'sufi',
      hostname: 'https://sufi.grupobancolombia.com/wps/portal/sufi',
      googleTagManager: {
        GTM: 'GTM-55CQGZR',
        UA: 'UA-27527568-71',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      url: '/v2/sufi/soat/seguro-obligatorio',
      index: 'indexSufi',
      codigoCanal: '14712',
      codigoAsesor: '9993',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'flypass',
      hostname: 'https://flypass.com.co/',
      googleTagManager: {
        GTM: 'GTM-5WRZXX',
        UA: 'UA-3571024-82',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      url: '/v2/flypass/soat/seguro-obligatorio',
      codigoCanal: '11093',
      codigoAsesor: '29095',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      }
    },
    {
      tenant: 'terpel',
      hostname: 'www.suraenlinea.com',
      googleTagManager: {
        GTM: 'GTM-PLS4XM',
        UA: 'UA-3571024-85',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      url: '/terpel',
      index: 'indexTerpel',
      codigoCanal: '11300',
      codigoAsesor: '',
      restricciones: ['twitter', 'empresas', 'vehiculos', 'bicicletas', 'credito-protegido',
        'menu.blog', 'admin', 'chat', 'soat.promociones', 'vehiculos.promociones', 'soat.tarifas',
        'soat.compra-pago', 'soat.historico-pagos', 'seguro-digital', 'cyber'
      ],
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: false,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'carulla',
      hostname: 'https://www.carulla.com/',
      url: '',
      codigoCanal: '9656',
      codigoAsesor: '4999',
      index: 'indexCarulla',
      googleTagManager: {
        GTM: 'GTM-5T9XXH',
        UA: 'UA-3571024-84',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'busqo',
      hostname: 'www.suraenlinea.com',
      googleTagManager: {
        GTM: 'GTM-KWGB49',
        UA: 'UA-3571024-73',
        optimizeId: ''
      },
      facebookPixel: {
        code: '396360740552961',
        facebookEvents: ['PageView']
      },
      url: '/busqo',
      codigoCanal: '',
      codigoAsesor: '13765',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: false,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'alkosto',
      hostname: 'https://www.alkosto.com/',
      googleTagManager: {
        GTM: 'GTM-PB29JXG',
        UA: '',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      url: '/v2/alkosto/soat/seguro-obligatorio',
      index: 'indexAlkosto',
      codigoCanal: '13824',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'ktronix',
      hostname: 'https://www.ktronix.com/',
      googleTagManager: {
        GTM: '',
        UA: '',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '',
      codigoCanal: '13825',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
      }
    },
    {
      tenant: 'euro',
      hostname: 'http://www.eurosupermercados.com/',
      googleTagManager: {
        GTM: 'GTM-MMQ5T9T',
        UA: '',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '/v2/euro/soat/seguro-obligatorio',
      codigoCanal: '13911',
      codigoAsesor: '11076',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'bancodeoccidente',
      hostname: 'https://www.bancodeoccidente.com.co',
      googleTagManager: {
        GTM: 'GTM-5386RGL',
        UA: 'UA-27527568-1',
        globalSite: 'UA-3571024-60',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '/v2/bancodeoccidente/soat/seguro-obligatorio',
      codigoCanal: '14778',
      codigoAsesor: '55889',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      }
    },
    {
      tenant: 'exitoatendido',
      hostname: 'https://atendido.exito.com/',
      googleTagManager: {
        GTM: 'GTM-5R4TXN',
        UA: 'UA-27527568-1',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '',
      codigoCanal: '14782',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'presentecom',
      hostname: 'https://www.presente.com.co/',
      googleTagManager: {
        GTM: 'GTM-5R4TXN',
        UA: 'UA-27527568-1',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '/v2/presentecom/soat/seguro-obligatorio',
      codigoCanal: '14046',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: false,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'alkomprar',
      hostname: 'https://www.alkomprar.com/',
      googleTagManager: {
        GTM: 'GTM-5R4TXN',
        UA: 'UA-27527568-1',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '/v2/alkomprar/soat/seguro-obligatorio',
      codigoCanal: '13590',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'foton',
      hostname: 'https://foton.com.co/',
      googleTagManager: {
        GTM: 'GTM-5R4TXN',
        UA: 'UA-27527568-1',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '/v2/foton/soat/seguro-obligatorio',
      codigoCanal: '14017',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'tuya',
      hostname: 'https://www.tuya.com.co/',
      googleTagManager: {
        GTM: 'GTM-5R4TXN',
        UA: 'UA-27527568-1',
        optimizeId: ''
      },
      facebookPixel: {
        code: '',
        facebookEvents: ''
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '/v2/tuya/soat/seguro-obligatorio',
      codigoCanal: '13996',
      codigoAsesor: '4999',
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false
      }
    },
    {
      tenant: 'colsubsidio',
      hostname: 'https://www.colsubsidio.com/',
      googleTagManager: {
        GTM: 'GTM-5R4TXN',
        globalSite: 'UA-27527568-1',
        UA: 'UA-27527568-1',
        optimizeId: 'GTM-PSFM4S5',
      },
      facebookPixel: {
        code: '380531805706245',
        facebookEvents: ['PageView'],
      },
      userVoice: 'fstYbP5lDoofPPZa8JFig',
      url: '/',
      codigoCanal: '7771',
      codigoAsesor: '4999',
      canActivateRoute: {
        preguntasFrecuentes: true,
      }
    },
  ]
});
