import styled from 'styled-components'

export const ALink = styled.a`
  letter-spacing: 0.067rem;
  font-family: var(--font-family-secondary);
  transition: all .15s ease-in-out;
  cursor: pointer;
  color: var(--primary);
  text-decoration: underline;
  :hover {
    color: var(--primary);
    text-decoration: underline;
  }
  ${props =>
    (props.variant === 'default' && `      
      padding: .3rem 2.9rem;
      @media (min-width: 48rem) {
        padding: .5rem 2.9rem; 
      }
      background-color: ${props.background};
      font-family: var(--font-family-secondary);
      font-size: 1.375rem;
      color: ${props.color};
      :hover {
        color: ${props.color};
      }
    `)
  }
  ${props =>
    (props.variant === 'primary' && `
      padding: .5rem 2.9rem;
      background-color: var(--primary);
      font-family: var(--font-family-secondary);
      font-size: 1.375rem;
      color: #fff;
      :hover {
        color: #fff;
      }
    `)
  }
  ${props =>
    (props.variant === 'secondary' && `
      padding: .5rem 2.9rem;
      background-color: var(--tertiary);
      font-family: 'FSJoeySemiBold';
      color: var(--primary) !important;
      font-weight: bold;
      font-size: ${props.size ? props.size + 'rem' : '1rem'};
      display: block;
      margin: 0 auto;
      :hover {
        color: var(--primary);
      }
    `)
  }
  ${props =>
    (props.variant === 'tertiary' && `
      background-color: ${props.background};
      color: ${props.color};
      font-weight: 600;
      font-size: ${props.size ? props.size + 'rem' : '1rem'};
      :hover {
        color: ${props.color};
      }`
    )
  }
`;