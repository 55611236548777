import React from 'react';
import Container from 'react-bootstrap/Container';
import { RichText } from "prismic-reactjs";
import { 
  Content,
  ImageFloat,
  ContainerList,
  Title
} from './styles';
import ListItemWithImage from '../../StyledShared/ListItemWithImage';

const MediaObject = ({ data }) => (    
  <Container id={data.linkInterno} className="mt-5 mb-4 mb-md-5 pt-4 px-0 px-md-2">
    <div className="justify-content-end">
      <Content>
        <ImageFloat src={data.background.url} alt={data.background.alt} />
        <ContainerList className="d-flex justify-content-end">
          <div className="col-12 col-md-7 col-lg-8 px-4 px-md-3 px-lg-5 px-xl-2 pt-4">
            <Title className="mb-3">{data.title}</Title>
              {data.data.map((media, index) => (      
                <ListItemWithImage 
                  key={`media-object-type-c-${index}`} 
                  imageUrl={media.img_item.url} 
                  imageAlt={media.img_item.alt} 
                  description={RichText.render(media.text_item)}
                  customClass="my-4"
                />               
              ))}
          </div>
        </ContainerList>
      </Content>
    </div>
  </Container>
);

export default MediaObject;
