import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
/* import Flow from './../pages/Flow'; */
import Home from '../pages/Home';
import SeguroExequias from '../pages/SeguroExequias';
import SeguroVida from '../pages/SeguroVida';
import SeguroPatinetaElectrica from '../pages/SeguroPatinetaElectrica';
import MueveteLibre from '../pages/MueveteLibre/index';

import NoFound from '../pages/NoFound';
import { GTM } from '../services/gtm';
import { withRouter } from 'react-router-dom';
import { STYLES } from '../services/styles';
import { FaqGuard } from '../components/FAQ/Guard/FaqGuard';
import { environment } from '../environments/environment';
// import AutosPorUso from '../pages/AutosPorUso';
import SaludEvoluciona from '../pages/SaludEvoluciona';
import '@segurosvoluntarios_vel/wc/dist/sura-cookie';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Router = ({ history, location }) => {
  useEffect(() => {
    setTenantStyles();
    history.listen((location, action) => {
      setTenantStyles();
    });
  }, []);

  const setTenantStyles = () => {
    const { pathname } = location;
    const tenantName =
      pathname && pathname.split('/') && pathname.split('/')[1];
    const tenantActive = isActiveTenant(tenantName);
    STYLES.setStyles((tenantActive && tenantActive.tenant) || 'sura');
  };

  const isActiveTenant = (url) => {
    return environment.tenants.find((x) => x.tenant === url);
  };

  const insertGtags = (tenantObject) => {
    if (tenantObject.googleTagManager.globalSite) {
      GTM.insertGTagConTimeOut(tenantObject);
    }
    if (tenantObject.googleTagManager.UA) {
      GTM.insertGTag(tenantObject);
    }
    GTM.setGoogleScriptTagManager(tenantObject);
  };

  const insertGoogleSearchMeta = () => {
    const meta = document.createElement('meta');
    meta.name = 'google-site-verification';
    meta.content = 'UHK_6wWfVhW_Om_nawm2Pxuz2tO_jm2P8hA4j3_RPRQ';
    document.getElementsByTagName('head')[0].appendChild(meta);
  };

  return (
    <Switch>
      <Route
        exact
        path='/'
        render={({ match: { url, params } }) => {
          const activeTenant = isActiveTenant(params.tenant);
          insertGtags(isActiveTenant('sura'));
          if (!activeTenant || activeTenant.tenant === 'sura') {
            insertGoogleSearchMeta();
          }
          return <Home tenant={activeTenant}></Home>;
        }}
      />
      <Route exact path='/home'>
        <Redirect to='/' />
      </Route>
      <Route
        path='/:tenant'
        render={({ match: { url, params } }) => {
          const activeTenant = isActiveTenant(params.tenant);
          if (activeTenant) {
            if (activeTenant.tenant === 'sura') {
              insertGoogleSearchMeta();
            }
            insertGtags(activeTenant);
            return (
              <React.Fragment>
                <GoogleReCaptchaProvider
                  reCaptchaKey={environment.googlereCAPTCHAV3Key}
                >
                  <Switch>
                    <Route exact path={`${url}/seguro-de-hogar`}>
                      <Redirect to='/' />
                    </Route>
                    <Route
                      exact
                      path={`${url}/seguro-de-exequias`}
                      render={() => (
                        <SeguroExequias tenantName={params.tenant} />
                      )}
                    />
                    <Route
                      exact
                      path={`${url}/seguro-de-vida`}
                      render={() => <SeguroVida tenantName={params.tenant} />}
                    />
                    <Route
                      exact
                      path={`${url}/seguro-de-vida?canal=:canal&asesor=:codigoAsesor`}
                      render={() => <SeguroVida tenantName={params.tenant} />}
                    />
                    {/* <Route exact path={`${url}/seguro-de-carro-por-uso`} render={() => <AutosPorUso tenantName={params.tenant}/>} />
                      <Route exact path={`${url}/seguro-de-carro-por-uso?canal=:canal&asesor=:codigoAsesor`} render={() => <AutosPorUso tenantName={params.tenant}/>} /> */}
                    <Route
                      exact
                      path={`${url}/seguro-de-salud-plan-evoluciona`}
                      render={() => (
                        <SaludEvoluciona tenantName={params.tenant} />
                      )}
                    />
                    <Route
                      exact
                      path={`${url}/seguro-de-patineta-electrica`}
                      render={() => (
                        <SeguroPatinetaElectrica tenantName={params.tenant} />
                      )}
                    />
                    <Route
                      exact
                      path={`${url}/muevete-libre`}
                      render={() => (
                        <MueveteLibre tenantName={params.tenant} />
                      )}
                    />
                    <Route
                      exact
                      path={`${url}/preguntas-frecuentes`}
                      render={() => <FaqGuard tenant={activeTenant}></FaqGuard>}
                    />
                    <Route path="/pagina-no-encontrada" component={NoFound} />
                    <Redirect to="/pagina-no-encontrada" />
                  </Switch>
                </GoogleReCaptchaProvider>
              </React.Fragment>
            );
          }
          return <Switch>
            <Route path="/pagina-no-encontrada" component={NoFound} />
            <Redirect to="/pagina-no-encontrada" />
          </Switch>
        }}
      />
      <Route path="/pagina-no-encontrada" component={NoFound} />
      <Redirect to="/pagina-no-encontrada" />
    </Switch>
  );
};

export default withRouter(Router);
