import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PreguntasFrecuentes from '../../../pages/PreguntasFrecuentes';
import { TenantService } from '../../../services/tenants';

export function FaqGuard({ tenant, ...rest }) {

    const canActivate = TenantService.canActivateFaq(tenant.tenant);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (!canActivate) {
                    window.location.href = '/pagina-no-encontrada';
                }
                return canActivate ? (
                    <PreguntasFrecuentes tenant={tenant} />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/pagina-no-encontrada',
                                state: { from: location }
                            }}
                        />
                    );
            }
            }
        />
    )
}
