import React from 'react';
import classNames from 'classnames/bind';

import Styles from './Promotions.module.scss';
import { Container, Row, Col } from 'react-bootstrap';

const Promotions = ({ data }) => (
  <section style={{ background: data.background }}>
    <Container>
      <Row className='justify-content-center'>
        {data.data.map((item, index) => (
          <Col className={data.bootstrapCols} key={`index_${index}`}>
            <img
              src={item.image.url}
              alt={item.image.alt}
              className={Styles.image}
            />
            <div className={Styles.hover}>
              <div
                className={classNames(
                  'd-flex justify-content-center align-items-center py-4',
                  Styles.contentCotiza
                )}
              >
                <a
                  href={item.link.url}
                  className={classNames('btn', Styles.contentCotiza__button)}
                >
                  {' '}
                  {item.name_button}
                </a>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);

export default Promotions;
