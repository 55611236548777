export const DATA_LAYER_EVENT = Object.freeze({ 
    PAGE_VIEW: 'pageview-virtual'
});

export const DATA_LAYER_SOURCE = Object.freeze({ 
    SURA_EN_LINEA: 'suraenlinea'
});

export const DATA_LAYER_PRODUCT_NAME = Object.freeze({ 
    PATINETAS: 'pv-patinetas'
});