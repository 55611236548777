import styled from 'styled-components';

export const ContainerProduct = styled.div`
  border: 1px solid #ebebeb;
  box-shadow: 0 2px 4px 1px #ebebeb;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all .1s ease-in-out;
  :hover {
    transform: scale(1.020);
  }
`;
