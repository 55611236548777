import React from "react";
import { Container } from "react-bootstrap";

import {
  TitleSeguros,
  Logo,
  HeaderContainer
} from './styles'; 

const NavbarHeader = ({tenant = 'sura', logoUrl, tenantName}) => {
  
  const getUrlRedirect = () => {
    
    let url = '/';

    switch (tenant.tenant) {
      case 'exito':
        url = 'https://www.segurosexito.com/'
        break;
      case 'colsubsidio':
        url = 'https://www.colsubsidio.com/'
        break;
      case 'bancodeoccidente':
      case 'flypass':
        url = `/v2/${tenant.tenant}/soat/seguro-obligatorio`;
        break;
    }
    return url;
  }

  return (
  <HeaderContainer>
    <Container className="d-flex justify-content-center justify-content-lg-start">
      <a href={getUrlRedirect()}>
        <Logo></Logo>
      </a>
    </Container>
    <TitleSeguros className="d-block d-md-none">SEGUROS</TitleSeguros>
  </HeaderContainer>
)};

export default NavbarHeader;
