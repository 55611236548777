import styled, { css } from 'styled-components';

export const H3 = styled.h3`
  color: var(--primary);
  font-size: 1.55rem;
  text-align: center;
  font-family: 'FSJoeySemiBold';
  font-weight: bold;
`;

export const Container = styled.div`
  min-height: 26rem;
  background-color: #fff;
  border-radius: .625rem;
  border: 0.03125rem solid #ABACAE;
  box-shadow: 0px 10px 9px #00000034;
  padding: 1rem;
  max-width: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContainerThankYou = styled.div`
  text-align: center;
`;

export const MsgThankYou = styled.p`
  font-size: 1.5625rem;
  color: var(--primary);
  line-height: 1;
  font-family: 'FSJoeySemiBold';
`;

export const Dropdown = styled.div`
display: inline-block;
color: #acacac;
margin-bottom: 10px;
`;

export const Group = styled.div`
  position: relative;
  margin-bottom: 1.6rem;
  display: flex !important;
  align-items: center;
  ${props =>
    (props.flexStart &&`
      align-items: flex-start;
    `)
  }
`;

export const HasError = styled.p`
  position: absolute;
  margin: 0;
  bottom: -.9rem;
  font-size: .7rem;
  font-family: var(--font-family-secondary);
`;

export const LabelInput = styled.label`
  position: absolute;
  margin: 0 .5rem;
  padding: 0 .5rem;
  pointer-events: none;
  font-size: 1rem;
  color: #727272;
  transition: all .15s ease-in-out;
  background-color: #fff;
  font-family: var(--font-family-secondary);
`;


const sharedStyleInputs = css`
  font-family: var(--font-family-secondary);
  width: 100%;
  font-size: 1rem;
  padding: .5rem 1rem;  
  border: 1px solid #727272;
  border-radius: .25rem;
  &:focus,
  &:not([value=""]) {
    outline: none;
    & ~ ${LabelInput} {
      transform: translateY(-22px);
      font-size: 13px;
    }
  }     
  &.hasError {
    border: 1px solid red;
    & ~ ${LabelInput},
    & ~ ${HasError} {
      color: red;
    }
  }
  &.valid {
    border: 1px solid #00aec7;
    & ~ ${LabelInput},
    & ~ ${HasError} {
      color: #00aec7;
    }
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
  }
  ${props =>
    (props.autoWidth &&`
      width: auto;
    `)
  }
`;


export const Input = styled.input`
  ${sharedStyleInputs};
`;

export const InputSelect = styled.select`
background: #FFFFFF;
font-family: var(--font-family-secondary);
width: 100%;
font-size: 1rem;
padding: .5rem 1rem;  
border: 1px solid #727272;
border-radius: .25rem;
&:blur{
  color: green;
}
&:focus,
&:not([value=""]){
  outline: none;
  color: black;
}
&.hasError {
  border: 1px solid red;
  & ~ ${LabelInput},
  & ~ ${HasError} {
    color: red;
  }
}
&.valid {
  border: 1px solid #00aec7;
  & ~ ${LabelInput} {
    transform: translateY(-22px);
    font-size: 13px;
    color:  #00aec7;
  },
  & ~ ${HasError}
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none; 
  margin: 0; 
}
${props =>
  (props.autoWidth &&`
    width: auto;
  `)
}
`;

export const CheckboxLabel = styled.div`
  font-family: var(--font-family-secondary);
  color: gray;
  font-size: 0.8rem;
`;


