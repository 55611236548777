import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Col, Container, Row } from 'react-bootstrap';

import './contact.scss';
import { useNearScreen } from '../../hooks/useNearScreen';

const Contact = ({ data }) => {
  const [show, element] = useNearScreen();

  return (
    <Container ref={element}>
      {show && (
        <Row className='contact'>
          {data.data.map((item, indexContact) => (
            <Col
              className={[
                data.bootstrapCols,
                item.primary.border,
                item.primary.bootstrap_class,
              ]}
              key={`indexContact_${indexContact}`}
            >
              {item.items.map((innerItem, indexInnerItem) => (
                <div
                  className='container container-contacto'
                  key={`indexInnerItem_${indexInnerItem}`}
                >
                  <b className='title'>{innerItem.title_contact}</b>
                  {innerItem.body_contact &&
                    RichText.render(innerItem.body_contact)}
                  {innerItem.image_contact_1.url && (
                    <a
                      href={innerItem.link_image_contact_1.url}
                      target={innerItem.link_image_contact_1.target}
                      className={`mr-3 ${innerItem.image_class}`}
                    >
                      <img
                        src={innerItem.image_contact_1.url}
                        alt={innerItem.image_contact_1.alt}
                        className='mw-100 '
                      />
                    </a>
                  )}
                  {innerItem.image_contact_2.url && (
                    <a
                      href={innerItem.link_image_contact_2.url}
                      target={innerItem.link_image_contact_2.target}
                      className={`mr-3 ${innerItem.image_class}`}
                    >
                      <img
                        src={innerItem.image_contact_2.url}
                        alt={innerItem.image_contact_2.alt}
                        className='mw-100 my-3'
                      />
                    </a>
                  )}
                  {innerItem.image_contact_3.url && (
                    <a
                      href={innerItem.link_image_contact_3.url}
                      target={innerItem.link_image_contact_3.target}
                      className={`mr-3 ${innerItem.image_class}`}
                    >
                      <img
                        src={innerItem.image_contact_3.url}
                        alt={innerItem.image_contact_3.alt}
                        className='mw-100'
                      />
                    </a>
                  )}
                </div>
              ))}
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default Contact;
