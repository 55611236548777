

export const fotonTenant = {
  name: 'foton',
  properties: {
    // Primary colors
    '--primary': '#0033A0',
    '--secondary': '#0097d0',
    '--tertiary': '#008acc',
    '--quarter': '#54555a',
    '--error': '#008acc',
    '--nav-bar': '#fff',

    /* Buttons */
    // Primary
    '--color-button-primary': '#fff',
    '--bg-color-button-primary': '#0097d0',
    '--hover-color-button-primary': '#fff',
    '--hover-bg-color-button-primary': '#008acc',
    // Secondary
    '--color-button-secondary': '#fff',
    '--bg-color-button-secondary': '#0097d0',
    '--hover-color-button-secondary': '#fff',
    '--bg-hover-color-button-secondary': '#008acc',
    // Tertiary
    '--color-button-tertiary': '#fff',
    '--bg-color-button-tertiary': '#0097d0',
    '--hover-color-button-tertiary': '#0097d0',
    '--bg-hover-color-button-tertiary': '#fff',

    // Forms
    '--color-input-valid': '#008acc',

    // Fonts
    '--primary-semibold-font-family': 'FSJoeySemiBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#fff',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#0033a0',
    '--logo': 'url(../../../../assets/images/tenants/foton/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--primary)',
  }
};
