import styled from 'styled-components'

export const ContainerCotizador = styled.div`
  max-width: 90%;  
  width: 25rem;
  height: 9.375rem;
  background: rgba(0, 51, 160, .8);
  margin: 0 auto;
  @media (min-width: 768px) {
    transform: translateY(-4rem);
  }
  position: relative;
  z-index: 10;
  border-radius: 0.625rem;
  text-align: center;
`;

export const Title = styled.h3`
  font-family: 'FSJoeySemiBold';
  font-size: 1.875rem;
  color: #fff;
`;

export const Button = styled.button`
  position: relative;
  background-color: #fff;
  border-radius: 1.625rem;
  border: 0;
  padding: .1rem 3rem;
  font-size: 1.4rem;
  @media (min-width: 768px) {
    padding: .5rem 3rem;
    font-size: 1rem;
  }
  color: var(--primary);
  font-family: 'FSJoeySemiBold';
  font-weight: 600;
  :focus { outline: none; }
  :after,
  :before {
    content: '';
    position: absolute;
    width: auto;
    height: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 1rem;
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
  }
  :before {
    border-left: .5rem solid #fff;
    z-index: 1;
    -webkit-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  :after {
    border-left: .5rem solid;
  }
  ${props =>
    (props.border &&`
      color: var(--primary);
      background-color: var(--tertiary);
      font-weight: 600;
      :after,
      :before {
        display: none;
      }
    `)
  }
`;

export const ContainerButtonFixed = styled.div`
  background-color: var(--primary);
  width: 100%;
  position: fixed;
  bottom: 0;  
  z-index: 99;
  text-align: center;
`;

export const ModalBody = styled.div`
  background-color: #fff;
`;

export const ModalFooterParagraph = styled.p`
  font-size: ${props => props.size || '0.875rem'};
  color: #606060;
  font-family: 'FSJoeySemiBold';
`;

export const ModalFooterBold = styled.b`
  font-size: ${props => props.size || '0.875rem'};
  color: #606060;
  font-family: 'FSJoeySemiBold';
`;

export const TitleListSeguros = styled.h4`
  color: #0033a0;
  font-family: 'FSJoeySemiBold';
  border-bottom: 1px solid #707070;
`;

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  margin-bottom: .5rem;
`;

export const IconContainer = styled.div`
  width: 1.5rem;
`;

export const ListItemLink = styled.a`
  color: #606060;
  font-size: 1rem;
  :hover {
    color: #606060;
  }
`;

export const ButtonClose = styled.button`
  background: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1rem;
  top: 1.5rem;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 0;
  z-index: 10;
  color: #0033a0;
  font-weight: 500;
  :focus {
    outline: none;
  }
`;

export const IconClose = styled.span`
  height: 1px;
  font-size: 1.3rem;
`;
