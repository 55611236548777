import React, { useEffect, useState, Fragment } from 'react';
import { RichText } from 'prismic-reactjs';
import Swiper from 'swiper';
import './slider-type-a.scss';
import Link from '../../StyledShared/Link';
import classNames from 'classnames/bind';
import { BackgroundBanner } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import DynamicForm from '../../Forms/DynamicForm';

const SliderTypeA = ({ data }) => {

  const { fields, validation, onSubmit } = data;

  const [ countSlider ] = useState(
    data.data.length
  );


  useEffect(() => {    
    if (countSlider > 1) {
      new Swiper(".swiper-container-slider-type-a", {
        pagination: {
          el: '.swiper-pagination',
        },
        autoplay: {
          delay: 5000,
        },
        speed: 1500,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }   
  }, []);

  const getData = () => data.data;


  return (
    <div className={classNames('swiper-container mt-md-0', countSlider > 1 && 'swiper-container-slider-type-a')}>
      <div className="swiper-wrapper">
        {getData().map((item, indexSliderTypeA) => (
          <BackgroundBanner 
            className="swiper-slide slider d-flex align-items-stretch align-items-md-center pt-md-0"
            key={`indexSliderTypeA_${indexSliderTypeA}`}
            backgroundDesktop={item.image.url}
            backgroundMobile={item.image_mobile.url}
          >
            <Container>
              <Row>
                <Col xs={12} md={7} className="d-flex align-items-center justify-content-md-start justify-content-center mb-3 mb-md-0">
                  <div className="position-relative d-flex align-items-start align-items-md-center justify-content-center justify-content-lg-start col-md-6 pl-md-0">
                    <div className="infoBanner pt-3 pt-md-0 text-center text-md-left">
                      <div className="infoBanner__title" style={{color: item.color_title_banner}}>
                        {RichText.render(item.title)}
                      </div>
                      <div className="infoBanner__sub-title" style={{color: item.color_sub_title}}>
                        {RichText.render(item.sub_title)}
                      </div>
                      <div className="infoBanner__description" style={{color: item.color_body_banner}}>
                        {RichText.render(item.body)}
                      </div>
                      {item.button_name &&                
                        <Link 
                          to={item.link.url}
                          target={item.link.target ? item.link.target : '_self'} 
                          variant="default"
                          elementId={`banner-home-cotizar-${indexSliderTypeA}`}
                          color={item.color_boton}
                          background={item.background_boton}
                        >
                          {item.button_name}
                        </Link>
                      }
                    </div>                
                  </div>
                </Col>
                <Col cs={12} md={5} className="my-2">
                { fields &&          
                  <DynamicForm fields={fields} validation={validation} onSubmit={onSubmit} />            
                }
                </Col>
              </Row>
            </Container>
          </BackgroundBanner>
        ))}
      </div>
      {countSlider > 1 &&     
        <Fragment>
          <div className="swiper-button-next d-none d-md-block"></div>
          <div className="swiper-button-prev d-none d-md-block"></div>
          <div className="container position-relative container-pagination">
            <div className="swiper-pagination customPagination"></div>
          </div>
        </Fragment> 
      }
      
    </div>
  )
}

export default SliderTypeA;
