import React, { useState } from 'react';
import { Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Container } from 'react-bootstrap';
import { Titulo } from '../styles';
import { CustomTypeahead, Search } from './styles';

const FAQSearch = ({
  searchFn,
  headerLabel,
  searchPlaceholder,
  autocompleteOptions,
  labelKey
}) => {
  const [searchText, setSearchText] = useState('');
  const maxResults = 5;

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      searchFn(searchText);
    }
  };

  return (
    <Container className="my-4">
      {headerLabel && <Titulo>{headerLabel}</Titulo>}
      <div className="input-group mb-2">
        <CustomTypeahead
          id="typehead"
          onInputChange={text => {
            setSearchText(text);
          }}
          onChange={selected => {
            searchFn(selected[0]);
            setSearchText(selected[0]);
          }}
          onKeyDown={handleKeyPress}
          onBlur={() => {
            searchFn(searchText);
          }}
          options={autocompleteOptions.map(option => option[labelKey])}
          placeholder={searchPlaceholder}
          maxResults={maxResults}
          renderMenu={(results, menuProps) =>
            results && results.length > 0 ? (
              <Menu {...menuProps}>
                {results.map((result, index) => {
                  if (typeof result === 'string') {
                    return (
                      <MenuItem key={result} option={result} position={index}>
                        {result}
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            ) : null
          }
        />
        <div className="input-group-prepend">
          <Search>
            <i className="fal fa-search"></i>
          </Search>
        </div>
      </div>
    </Container>
  );
};

export default FAQSearch;
