import { environment } from "../environments/environment";
const axios = require('axios');
var qs = require('qs');

  
const SALESFORCE = {
    submit: (event) => {
        event.preventDefault();
    },
    sendTomadorDataToSalesForce: (nombre, apellido, telefono, tipoDocumento, documento, email, codigoProducto, strCual, camposAdicionales) => {
 
        const body = {
            'oid': environment.oidSalesforce, 
            '00N0V000009L0y5': codigoProducto,
            'lead_source': 'Sitios web',                      
            'first_name': nombre,
            'last_name': apellido,
            'mobile': telefono,
            'strTipo_de_documento__c': tipoDocumento,
            'strNumero_de_identificacion__c': documento,
            'email': email,
            'sub_origen__c': 'suraenlinea.com/sura',
            'strCual_TR__c': strCual,
            'submit': 'submit',
            ...camposAdicionales            
        };
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        };
 
        return axios.post(environment.endpointSalesforce, qs.stringify(body), config)
            .then((response) => {
                
            });
    }
};
 
export default SALESFORCE;
