

export const cdiscountTenant = {
  name: 'cdiscount',
  properties: {
    // Primary colors
    '--primary': '#00b5cc',
    '--secondary': '#dbdbdb',
    '--tertiary': '#ffe800',
    '--quarter': '#000',
    '--error': 'red',
    '--nav-bar': '#fff',

    /* Buttons */
    // Primary
    '--color-button-primary': '#00b5cc',
    '--bg-color-button-primary': '#ffe800',
    '--hover-color-button-primary': '#0e8288',
    '--hover-bg-color-button-primary': '#00b5cc',
    // Secondary
    '--color-button-secondary': '#0e8288',
    '--bg-color-button-secondary': '#ffe800',
    '--hover-color-button-secondary': '#0e8288',
    '--bg-hover-color-button-secondary': '#ffe800',
    // Tertiary
    '--color-button-tertiary': '#0e8288',
    '--bg-color-button-tertiary': '#ffe800',
    '--hover-color-button-tertiary': '#0e8288',
    '--bg-hover-color-button-tertiary': '#ffe800',

    // Forms
    '--color-input-valid': '#00aec7',

    // Fonts
    '--primary-semibold-font-family': 'FSJoeySemiBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#00b5cc',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#63ba2f',
    '--logo': 'url(../../../../assets/images/tenants/cdiscount/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': '#0e8288',
    '--color-tittle-h3': '#0e8288',
  }
};
