import API from './api'
const END_POINT_FUEL_TYPE = '/productos/soat/v1/maestros/vehiculo/combustibles';
const END_POINT_VEHICULE_CLASSES =
	'/productos/soat/v1/maestros/vehiculo/clases';
const END_POINT_SERVICES_TYPE =
	'/productos/soat/v1/maestros/vehiculo/servicios';

async function fetchFuelType() {
	try {
		const data = await API.asyncGet(END_POINT_FUEL_TYPE);
		return {
			nameOption: 'fuelType',
			dataOption: data.map(data => {
				return { value: data.codigo, title: data.valor };
			}),
		};
	} catch (error) {}
}

async function fetchVehicleClasses() {
	try {
		const data = await API.asyncGet(END_POINT_VEHICULE_CLASSES);
		return {
			nameOption: 'vehicleClasses',
			dataOption: data.map(data => {
				return { value: data.codigo, title: data.valor };
			}),
		};
	} catch (error) {}
}

async function fetchServicesType() {
	try {
		const data = await API.asyncGet(END_POINT_SERVICES_TYPE);
		return {
			nameOption: 'servicesType',
			dataOption: data.map(data => {
				return { value: data.codigo, title: data.valor };
			}),
		};
	} catch (error) {}
}

const SOAT = {
	API: {
		FuelType() {
			return fetchFuelType();
		},
		VehicleClasses() {
			return fetchVehicleClasses();
		},
		ServicesType() {
			return fetchServicesType();
		},
		Quote() {
			return fetchFuelType();
		},
		Validate() {
			return fetchFuelType();
		},
	},
};

export default SOAT;
