import devEnvironment from "./environment.dev";
import testEnvironment from "./environmentTest";
import stagingEnvironment from "./environment.staging";
import prodEnvironment from "./environment.prod";

const stage = process.env.REACT_APP_STAGE;
let tempEnvironment;
switch (stage) {
    case 'test':
        tempEnvironment = testEnvironment;
        break;
    case 'prod':
        tempEnvironment = prodEnvironment;
        break;
    case 'staging':
        tempEnvironment = stagingEnvironment;
        break;
    default:
        tempEnvironment = devEnvironment;
        break;
}

export const environment = tempEnvironment;
