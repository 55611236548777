

export const bancoDeOccidenteTenant = {
  name: 'bancodeoccidente',
  properties: {
    // Primary colors
    '--primary': '#008bce',
    '--secondary': '#efaa23',
    '--tertiary': '#008acc',
    '--quarter': '#00124a',
    '--error': '#008acc',
    '--nav-bar': '#d9e1f1',

    //Navbar
    '--color-text-nav-bar': '#008bce',

    /* Buttons */
    // Primary
    '--color-button-primary': '#fff',
    '--bg-color-button-primary': '#efaa23',
    '--hover-color-button-primary': '#fff',
    '--hover-bg-color-button-primary': '#efaa23',
    // Secondary
    '--color-button-secondary': '#fff',
    '--bg-color-button-secondary': '#efaa23',
    '--hover-color-button-secondary': '#fff',
    '--bg-hover-color-button-secondary': '#008acc',
    // Tertiary
    '--color-button-tertiary': '#fff',
    '--bg-color-button-tertiary': '#efaa23',
    '--hover-color-button-tertiary': '#008acc',
    '--bg-hover-color-button-tertiary': '#fff',

    // Forms
    '--color-input-valid': '#008acc',

    // Fonts
    '--primary-semibold-font-family': 'FSJoeySemiBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#fff',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#63ba2f',
    '--logo': 'url(../../../../assets/images/tenants/bancodeoccidente/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--primary)',

    // FAQ
    '--color-text-question-title': '#ffff',
    '--background-color-question-title': '#797979',
  }
};
