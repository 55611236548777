import React from 'react';
import Style from './slider-type-b.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames/bind';
import DynamicForm from '../../Forms/DynamicForm';

const SliderTypeB = ({ data }) => {  
  
  const { fields, validation, onSubmit } = data;
   
  return (
    <div className={classNames('w-100', Style.bannerForm)} style={{ backgroundImage: `url(${data.data[0].image.url})` }}>
      <Container>
        <Row>
          <Col className="text-center" xs="12" md="8">
            <h1 className={Style.titulo}>
              <span className={Style.titulo__Block}>
                {data.data[0]['titulo-fragmento1']}
              </span>
              <span className={classNames(Style.titulo__block, Style.titulo__pitem)}>
                {data.data[0]['titulo-fragmento2']}
              </span>
              <span className={classNames(Style.titulo__block, Style.titulo__sitem)}>{data.data[0]['titulo-fragmento3']}
                <span className={classNames(Style.titulo__sitem_varientedos, Style.titulo__block)}> {data.data[0]['titulo-fragmento4'] ? data.data[0]['titulo-fragmento4'] : null}</span>
              </span>
            </h1>
          </Col>
          { fields &&
            <Col xs="12" md="4" className={classNames('py-3 my-3', Style.containerForm)}>
              <DynamicForm fields={fields} validation={validation} onSubmit={onSubmit} />
            </Col>
          }
        </Row>
      </Container>
    </div>
  );
}

export default SliderTypeB;
  