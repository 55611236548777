import * as Yup from 'yup';

const alpha = /^[a-zA-Z_]+( [a-zA-Z_]+)*$/;

export const validation = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^([a-zA-Z\s]+)$/, {message: "Ingrese un nombre válido", excludeEmptyString: true })
    .required('Este campo es requerido')
    .trim()
    .max(35),
  lastName: Yup.string()
    .matches(/^([a-zA-Z\s]+)$/, {message: "Ingrese un apellido válido", excludeEmptyString: true })
    .required('Este campo es requerido')
    .trim()
    .max(35),
  typeId: Yup.string()    
    .required('Este campo es requerido')
    .trim()
    .max(35),
  document: Yup.string()
    .matches(/^\d{6,10}$/, {message: "Ingrese un documento válido", excludeEmptyString: true })
    .required('Este campo es requerido')
    .trim()
    .max(35),
  email: Yup.string()
    .matches(/^([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/, {message: "Ingrese un email válido", excludeEmptyString: true })
    .required('Este campo es requerido')
    .trim()
    .max(35),
  phone: Yup.string()
    .matches(/[3]([0-5])+([0-9]{8})$/, {message: "Ingrese un teléfono válido", excludeEmptyString: true })
    .required('Este campo es requerido')
    .trim()
    .max(10),
  edad: Yup.string()
    .required('Este campo es requerido')
    .trim()
    .max(35),
  cirugias: Yup.bool().oneOf([true, false]),
  embarazo: Yup.bool().oneOf([true, false]),
  authorization: Yup.bool().oneOf([true], "Acepta los términos y condiciones"),
});

export function validateName(value) {
  let error;
  if (!value) {
    error = 'Este campo es requerido!';
  } else if (!/^([a-zA-Z\s]+)$/.test(value)) {
    error = 'Ingrese un nombre válido';
  }
  return error;
}

export function validateLastName(value) {
  let error;
  if (!value) {
    error = 'Este campo es requerido!';
  } else if (!/^([a-zA-Z\s]+)$/.test(value)) {
    error = 'Ingrese un apellido válido';
  }
  return error;
}

export function validateRequired(value) {
  let error;
  if (!value) {
    error = 'Este campo es requerido!';
  }
  return error;
}