import axios from "axios";
import { environment } from "../environments/environment";

export const getMaestro = (catalogo, producto) => {
  return axios.get(`${environment.urlApiMaestros}/maestros?catalogo=${catalogo}&producto=${producto}`,{
    headers:{
      [environment.headerSuscripcionNombre]: environment.headerSuscripcionValor,
    }
  }).then(response => {
    return response.data.respuesta;
  });
};
