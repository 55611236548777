

export const busqoTenant = {
  name: 'busqo',
  properties: {
    // Primary colors
    '--primary': '#5e3eba',
    '--secondary': '#5e3eba',
    '--tertiary': '#7cde8d',
    '--quarter': '#755ad5',
    '--error': 'red',
    '--nav-bar': '#fff',

    /* Buttons */
    // Primary
    '--color-button-primary': 'var(--primary)',
    '--bg-color-button-primary': '#7cde8d',
    '--hover-color-button-primary': 'var(--primary)',
    '--hover-bg-color-button-primary': '#dbdbdb',
    // Secondary
    '--color-button-secondary': 'var(--primary)',
    '--bg-color-button-secondary': 'var(--tertiary)',
    '--hover-color-button-secondary': 'var(--primary)',
    '--bg-hover-color-button-secondary': 'var(--tertiary)',
     // Tertiary
     '--color-button-tertiary': 'var(--primary)',
     '--bg-color-button-tertiary': 'var(--tertiary)',
     '--hover-color-button-tertiary': 'var(--primary)',
     '--bg-hover-color-button-tertiary': 'var(--tertiary)',

    // Forms
    '--color-input-valid': '#00aec7',

    // Fonts
    '--primary-semibold-font-family': 'FSJoeySemiBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#dbdbdb',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#63ba2f',
    '--logo': 'url(../../../../assets/images/tenants/busqo/logo.png)',

    // Footer
    '--footer-color': '#ffffff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--primary)',
  }
};
