import React, { Fragment } from 'react';
import { Field } from 'formik';
import { 
  Group,
  LabelInput,
  HasError,
  InputSelect
} from '../styles';

const renderSelect = (input) => {
  
  return (
    <Group key={input.name}>
      <Field
        name={input.name}
        render={(props) => {
          const { field } = props; 
          const { errors, touched } = props.form;
          const hasError = errors[input.name] && touched[input.name] && field.value;
          const defaultOption =  <option key='' value=''></option>
          const options = input.data.map(i => <option key={i.key} value={i.key}> {i.value} </option> );
          const selectOptions = [defaultOption, ...options];
          const validate = () => {
            if(field.value !== ''){
              return 'valid';
            } else if (errors[input.name] && touched[input.name]) {
              return 'hasError';
            }
          }
          return (
            <Fragment>
              <InputSelect 
                value={field.value} 
                {...field} 
                id={hasError}
                className={validate()}
              >
                {
                  selectOptions
                }
              </InputSelect>
              {hasError && <HasError>{errors[input.name]}</HasError>}
            </Fragment>
          );
        }}
      />
      <LabelInput>{input.label}</LabelInput>
    </Group>
  );
}

export default renderSelect;
