
function fetchQuote(currentStep, nextStep) {

  const sectionAdicional = nextStep.sections
    .filter(section => section.name === "passengers")
    .map(section => {
      return section;
    })[0];
    
 nextStep.sections.push(sectionAdicional)
  return nextStep;
}
const VIAJES = {
  API: {
    Quote(currentStep, nextStep) {
      return fetchQuote(currentStep, nextStep);
    }
  }
};

export default VIAJES;
