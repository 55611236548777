export const HOME_DOCUMENT = {
  page: 1,
  results_per_page: 20,
  results_size: 1,
  total_results_size: 1,
  total_pages: 1,
  next_page: null,
  prev_page: null,
  results: [
    {
      id: "XZ0kDBAAACIA3o0z",
      uid: null,
      url: null,
      type: "home",
      href:
        "https://suraenlinea-v2.cdn.prismic.io/api/v2/documents/search?ref=ZdOe_BEAACQA2geX&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XZ0kDBAAACIA3o0z%22%29+%5D%5D",
      tags: ["home", "sura"],
      first_publication_date: "2019-10-09T00:04:31+0000",
      last_publication_date: "2024-02-19T18:33:32+0000",
      slugs: [],
      linked_documents: [],
      lang: "es-es",
      alternate_languages: [],
      data: {
        title: "Home - (/) - Sura",
        bootstrap_cols: null,
        sections: [
          {
            image: {},
            section: "banner",
          },
          {
            image: {},
            section: "cotizador",
          },
          {
            image: {},
            section: "productos",
          },
          {
            image: {},
            section: "razones",
          },
          {
            image: {},
            section: "contacto",
          },
        ],
        body: [],
        list_component_banner: "sliderTypeA",
        list_banner: [
          {
            image: {
              dimensions: {
                width: 1920,
                height: 562,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-escritorio-seguro-carro.jpg?auto=compress,format",
              id: "ZY2hEBAAAJQ6t6jl",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            image_mobile: {
              dimensions: {
                width: 768,
                height: 1108,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-movil-seguro-carro.jpg?auto=compress,format",
              id: "ZY2hEBAAAJR_t6jn",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            title: [
              {
                type: "heading3",
                text: "Con tu",
                spans: [],
              },
            ],
            color_title_banner: "#ffffff",
            sub_title: [
              {
                type: "heading1",
                text: "SEGURO DE CARRO",
                spans: [],
              },
            ],
            color_sub_title: "#E3E829",
            body: [
              {
                type: "paragraph",
                text:
                  "tienes respaldo 24/7 por daños a terceros, daños propios y más",
                spans: [],
              },
            ],
            color_body_banner: "#ffffff",
            link: {
              link_type: "Web",
              url: "https://www.suraenlinea.com/movilidad/sura/seguro-de-carro",
            },
            button_name: "COMPRAR",
            color_boton: "#0033a0",
            background_boton: "#e3e829",
          },
          {
            image: {
              dimensions: {
                width: 1920,
                height: 562,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-escritorio-seguro-arrendamiento-digital.jpg?auto=compress,format",
              id: "ZY2iARAAAEQ2t60d",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            image_mobile: {
              dimensions: {
                width: 768,
                height: 1108,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-movil-seguro-arrendamiento-digital.jpg?auto=compress,format",
              id: "ZY2iARAAAJR_t60c",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            title: [
              {
                type: "heading1",
                text: "Compra tu",
                spans: [],
              },
            ],
            color_title_banner: "#0033a0",
            sub_title: [
              {
                type: "heading1",
                text: "SEGURO DE\nARRENDAMIENTO DIGITAL",
                spans: [],
              },
            ],
            color_sub_title: "#2d6df6",
            body: [
              {
                type: "paragraph",
                text: "y haz negocio directo\ncon respaldo",
                spans: [],
              },
            ],
            color_body_banner: "#0033a0",
            link: {
              link_type: "Web",
              url: "https://www.suraenlinea.com/arrendamiento-digital/sura",
            },
            button_name: "COMPRAR",
            color_boton: "#0033a0",
            background_boton: "#e3e829",
          },
          {
            image: {
              dimensions: {
                width: 1920,
                height: 562,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-escritorio-seguro-viaje.jpg",
              id: "ZdOewG34KdIZl_QO",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            image_mobile: {
              dimensions: {
                width: 768,
                height: 1108,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-movil-seguro-viaje.jpg",
              id: "ZdOezm34KdIZl_QQ",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            title: [
              {
                type: "heading1",
                text: "Compra tu",
                spans: [],
              },
            ],
            color_title_banner: "#0033a0",
            sub_title: [
              {
                type: "heading1",
                text: "SEGURO DE VIAJE",
                spans: [],
              },
            ],
            color_sub_title: "#2d6df6",
            body: [
              {
                type: "paragraph",
                text:
                  "y cuenta con cobertura médica integral 24/7",
                spans: [],
                direction: "ltr",
              },
            ],
            color_body_banner: "#0033a0",
            link: {
              link_type: "Web",
              url: "https://www.suraenlinea.com/viajes/sura",
            },
            button_name: "COMPRAR",
            color_boton: "#0033a0",
            background_boton: "#e3e829",
          },
       
          {
            image: {
              dimensions: {
                width: 1920,
                height: 562,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-escritorio-seguro-moto.jpg?auto=compress,format",
              id: "ZY2hEBAAAJQ6t6jk",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            image_mobile: {
              dimensions: {
                width: 768,
                height: 1108,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-movil-seguro-moto.jpg?auto=compress,format",
              id: "ZY2hEBAAADPTt6jm",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            title: [
              {
                type: "heading1",
                text: "Compra tu",
                spans: [],
              },
            ],
            color_title_banner: "#ffffff",
            sub_title: [
              {
                type: "heading1",
                text: "SEGURO DE MOTO",
                spans: [],
              },
            ],
            color_sub_title: "#ffffff",
            body: [
              {
                type: "paragraph",
                text:
                  "y muévete con tranquilidad. ¡Recibe asistencia 24/7!",
                spans: [],
              },
            ],
            color_body_banner: "#ffffff",
            link: {
              link_type: "Web",
              url: " https://www.sura.co/seguro-de-moto-digital",
              target: "_blank",
            },
            button_name: "COMPRAR",
            color_boton: "#0033a0",
            background_boton: "#e3e829",
          },
       
          {
            image: {
              dimensions: {
                width: 1920,
                height: 562,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-escritorio-soat.jpg?auto=compress,format",
              id: "ZMAl7BAAACEA5tLF",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            image_mobile: {
              dimensions: {
                width: 768,
                height: 1108,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/banner-home-movil-soat.jpg?auto=compress,format",
              id: "ZMAl7BAAABbg5tLE",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            title: [
              {
                type: "heading1",
                text: "Te acompañamos para que",
                spans: [],
              },
            ],
            color_title_banner: "#0033a0",
            sub_title: [
              {
                type: "heading1",
                text: "NO CAIGAS\nEN FRAUDE",
                spans: [],
              },
            ],
            color_sub_title: "#2d6df6",
            body: [
              {
                type: "paragraph",
                text: "Comparte la información y cuida a los que quieres",
                spans: [],
              },
            ],
            color_body_banner: "#0033a0",
            link: {
              link_type: "Web",
              url: "https://seguros.comunicaciones.sura.com/nocaigasenfraude",
              target: "_blank",
            },
            button_name: "CONOCE MÁS",
            color_boton: "#0033a0",
            background_boton: "#e3e829",
          },
        ],
        title_razones: [
          {
            type: "heading3",
            text: "¿Por qué asegurarte con SURA?",
            label: "text-center",
            spans: [],
          },
        ],
        list_component_razones: "reasons",
        color_title_razones: "#0033a0",
        background_razones: "#f5f5f5",
        bootstrap_cols_razones: "col-12 col-md-3 mt-3 mb-3 mb-md-5 text-center",
        list_razones: [
          {
            image: {
              dimensions: {
                width: 69,
                height: 63,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/first-reason.png?auto=compress,format",
              id: "XZ1AtBAAACQA3woP",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            title: [
              {
                type: "heading6",
                text: "Opción de financiar tu seguro",
                spans: [],
              },
            ],
            body: [
              {
                type: "paragraph",
                text:
                  "Además, puedes pagar desde cualquier lugar de forma rápida y confiable.",
                spans: [],
              },
            ],
            border: "s-border-right",
          },
          {
            image: {
              dimensions: {
                width: 69,
                height: 63,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/second-reason.png?auto=compress,format",
              id: "XZ1AtBAAACQA3woQ",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            title: [
              {
                type: "heading6",
                text: "Asistencia 24 horas",
                spans: [],
              },
            ],
            body: [
              {
                type: "paragraph",
                text:
                  "Te brindamos nuestro respaldo en la línea de atención telefónica y la App Seguros SURA.",
                spans: [
                  {
                    start: 70,
                    end: 73,
                    type: "em",
                  },
                ],
              },
            ],
            border: "s-border-right",
          },
          {
            image: {
              dimensions: {
                width: 69,
                height: 63,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/third-reason.png?auto=compress,format",
              id: "XZ1AtRAAACEA3woS",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            title: [
              {
                type: "heading6",
                text: "Acompañamiento integral",
                spans: [],
              },
            ],
            body: [
              {
                type: "paragraph",
                text: "Especialistas para la gestión de tus riesgos.",
                spans: [],
              },
            ],
            border: "s-border-right",
          },
          {
            image: {
              dimensions: {
                width: 69,
                height: 63,
              },
              alt: null,
              copyright: null,
              url:
                "/assets/images/prismic/fourth-reason.png?auto=compress,format",
              id: "XZ1AtBAAACEA3woR",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "transparent",
              },
            },
            title: [
              {
                type: "heading6",
                text: "Numerosos descuentos",
                spans: [],
              },
            ],
            body: [
              {
                type: "paragraph",
                text: "Para disfrutar con el programa Beneficios Seguros SURA.",
                spans: [],
              },
            ],
            border: null,
          },
        ],
        list_component_contacto: "contact",
        bootstrap_cols_contacto:
          "col-12 col-md-4 px-lg-5 px-md-3 mt-md-5 mb-4 mb-md-0 text-center text-md-left",
        list_contacto: [
          {
            primary: {
              border: "border-right",
              bootstrap_class: "d-none d-md-block",
            },
            items: [
              {
                image_contact_1: {
                  dimensions: {
                    width: 473,
                    height: 500,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/tigre-sura.png?auto=compress,format",
                  id: "XZ1HsBAAACMA3yjh",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 1,
                    background: "transparent",
                  },
                },
                image_contact_2: {},
                image_contact_3: {},
                title_contact: "Habla con nosotros",
                body_contact: [
                  {
                    type: "paragraph",
                    text:
                      "¿Necesitas una cotización o quieres contratar tu seguro por teléfono? ",
                    spans: [],
                  },
                ],
                link_image_contact_1: {
                  link_type: "Any",
                },
                link_image_contact_2: {
                  link_type: "Any",
                },
                link_image_contact_3: {
                  link_type: "Any",
                },
                image_class: "d-none d-md-block",
              },
            ],
            id: "list_contact$2ee702d3-d956-4d6a-a6da-aaf4f2b5ecac",
            slice_type: "list_contact",
            slice_label: null,
          },
          {
            primary: {
              border: "border-right",
              bootstrap_class: "d-none d-md-block",
            },
            items: [
              {
                image_contact_1: {},
                image_contact_2: {},
                image_contact_3: {},
                title_contact: null,
                body_contact: [
                  {
                    type: "paragraph",
                    text:
                      "Para comprar los seguros de carro y moto (no aplica para la compra del SOAT):",
                    spans: [],
                  },
                  {
                    type: "heading6",
                    text: "#888 opc. 8-5",
                    spans: [],
                  },
                  {
                    type: "heading6",
                    text: "01 8000 51 8888 opc. 8-5",
                    spans: [],
                  },
                  {
                    type: "heading6",
                    text: "315 275 7888 opc. 1-1-1-3-5-1 (WSP)\n",
                    spans: [],
                  },
                  {
                    type: "paragraph",
                    text:
                      "Atención al cliente (no aplica para la compra del SOAT):",
                    spans: [],
                  },
                  {
                    type: "heading6",
                    text: "#888 (solo celular)",
                    spans: [],
                  },
                  {
                    type: "heading6",
                    text: "01 8000 51 8888 (fijo y celular)",
                    spans: [],
                  },
                ],
                link_image_contact_1: {
                  link_type: "Any",
                },
                link_image_contact_2: {
                  link_type: "Any",
                },
                link_image_contact_3: {
                  link_type: "Any",
                },
                image_class: null,
              },
            ],
            id: "list_contact$2a780568-33fe-4e0f-9b7b-72ac4f8be9d2",
            slice_type: "list_contact",
            slice_label: null,
          },
          {
            primary: {
              border: "border-none",
              bootstrap_class: null,
            },
            items: [
              {
                image_contact_1: {
                  dimensions: {
                    width: 94,
                    height: 27,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/app-store-apple.svg",
                  id: "X9zPPxAAACUAR9gk",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 1,
                    background: "#fff",
                  },
                },
                image_contact_2: {
                  dimensions: {
                    width: 106,
                    height: 25,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/google-play-store.svg",
                  id: "X9zPPxAAACQAR9gl",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 1,
                    background: "#fff",
                  },
                },
                image_contact_3: {},
                title_contact: "App Seguros Sura",
                body_contact: [
                  {
                    type: "paragraph",
                    text: "",
                    label: "mb-2",
                    spans: [],
                  },
                ],
                link_image_contact_1: {
                  link_type: "Web",
                  url:
                    "https://itunes.apple.com/co/app/seguros-sura/id636278659?mt=8",
                  target: "_blank",
                },
                link_image_contact_2: {
                  link_type: "Web",
                  url:
                    "https://play.google.com/store/apps/details?id=co.com.sura.seguros",
                  target: "_blank",
                },
                link_image_contact_3: {
                  link_type: "Any",
                },
                image_class: null,
              },
              {
                image_contact_1: {
                  dimensions: {
                    width: 37,
                    height: 37,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/home-facebook.png?auto=compress,format",
                  id: "XZ1ElhAAACQA3xsz",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 1,
                    background: "transparent",
                  },
                },
                image_contact_2: {
                  dimensions: {
                    width: 37,
                    height: 37,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/home-instagram.png?auto=compress,format",
                  id: "XZ1ElxAAACQA3xs-",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 1,
                    background: "transparent",
                  },
                },
                image_contact_3: {
                  dimensions: {
                    width: 37,
                    height: 37,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/home-twitter.png?auto=compress,format",
                  id: "XZ1ElxAAACQA3xs7",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 1,
                    background: "transparent",
                  },
                },
                title_contact: "Síguenos en:",
                body_contact: [
                  {
                    type: "paragraph",
                    text: "",
                    label: "mb-4",
                    spans: [],
                  },
                ],
                link_image_contact_1: {
                  link_type: "Web",
                  url: "https://www.facebook.com/SegurosSuraColombia",
                  target: "_blank",
                },
                link_image_contact_2: {
                  link_type: "Web",
                  url: "https://www.instagram.com/segurossura/",
                  target: "_blank",
                },
                link_image_contact_3: {
                  link_type: "Web",
                  url: "https://twitter.com/SegurosSURAcol",
                  target: "_blank",
                },
                image_class: null,
              },
            ],
            id: "list_contact$43015ecf-21fd-42ae-b7db-15597df5713b",
            slice_type: "list_contact",
            slice_label: null,
          },
        ],
        list_component_cotizador: "cotizadorHome",
        list_cotizador: [
          {
            primary: {
              title_group_seguros: "Para personas",
              bootstrap_columns: "col-12 col-md-12",
              bootstrap_columns_items: "col-12 col-md-12 col-lg-6",
            },
            items: [
              {
                imagen_seguro: {
                  dimensions: {
                    width: 24,
                    height: 24,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/sel-soat.svg",
                  id: "Y8q6cxEAAKREEfUR",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 0.75,
                    background: "#fff",
                  },
                },
                title_seguro: "SOAT",
                link_seguro: {
                  link_type: "Web",
                  url:
                    "https://www.suraenlinea.com/soat/sura/seguro-obligatorio",
                  target: "_blank",
                },
              },
              {
                imagen_seguro: {
                  dimensions: {
                    width: 24,
                    height: 24,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/car-alt-solid.svg?auto=compress,format",
                  id: "XZ04cxAAACQA3uWr",
                  edit: {
                    x: -2,
                    y: 0,
                    zoom: 1.6,
                    background: "#fff",
                  },
                },
                title_seguro: "Seguro de Autos",
                link_seguro: {
                  link_type: "Web",
                  url: "https://www.suraenlinea.com/vehiculos/seguro-vehiculos",
                },
              },
              {
                imagen_seguro: {
                  dimensions: {
                    width: 24,
                    height: 24,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/motorcycle-solid.svg?auto=compress,format",
                  id: "XZ04chAAACEA3uWl",
                  edit: {
                    x: -8,
                    y: 0,
                    zoom: 2,
                    background: "#fff",
                  },
                },
                title_seguro: "Seguro de Motos",
                link_seguro: {
                  link_type: "Web",
                  url: "https://www.suraenlinea.com/motos/plan-motos",
                },
              },
              {
                imagen_seguro: {
                  dimensions: {
                    width: 24,
                    height: 24,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/plane-alt-solid.svg?auto=compress,format",
                  id: "XZ04chAAACQA3uWm",
                  edit: {
                    x: -2,
                    y: 0,
                    zoom: 1.7142857142857142,
                    background: "#fff",
                  },
                },
                title_seguro: "Seguro de Viaje",
                link_seguro: {
                  link_type: "Web",
                  url: "https://www.suraenlinea.com/v2/sura/viajes/cotizar",
                },
              },
              {
                imagen_seguro: {
                  dimensions: {
                    width: 24,
                    height: 24,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/home-lg-alt-solid.svg?auto=compress,format",
                  id: "XZ04chAAACEA3uWp",
                  edit: {
                    x: -2,
                    y: 0,
                    zoom: 1.7142857142857142,
                    background: "#fff",
                  },
                },
                title_seguro: "Seguro de Arrendamiento",
                link_seguro: {
                  link_type: "Web",
                  url: "https://www.suraenlinea.com/arrendamiento-digital/sura",
                  target: "_blank",
                },
              },
              {
                imagen_seguro: {
                  dimensions: {
                    width: 24,
                    height: 24,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/heartbeat-solid.svg?auto=compress,format",
                  id: "XZ04chAAACEA3uWi",
                  edit: {
                    x: -2,
                    y: 0,
                    zoom: 1.7142857142857142,
                    background: "#fff",
                  },
                },
                title_seguro: "Seguro de Salud",
                link_seguro: {
                  link_type: "Web",
                  url: "https://www.suraenlinea.com/sura/seguro-de-salud-plan-evoluciona",
                },
              },
              {
                imagen_seguro: {
                  dimensions: {
                    width: 24,
                    height: 24,
                  },
                  alt: null,
                  copyright: null,
                  url:
                    "/assets/images/prismic/family-pet.png?auto=compress,format&rect=0,0,24,24&w=24&h=24",
                  id: "XsRN6BQAACgA1amE",
                  edit: {
                    x: 0,
                    y: 0,
                    zoom: 1,
                    background: "transparent",
                  },
                },
                title_seguro: "Plan Elige: seguro personalizado",
                link_seguro: {
                  link_type: "Web",
                  url:
                    "https://www.suraenlinea.com/plan-elige?utm_source=suraenlinea&utm_medium=referral&utm_term=cotizador-home&utm_content=boton&utm_campaign=plan-elige",
                },
              },
            ],
            id: "cotizador$e9a870a4-a7c9-4c8c-8884-0fab07f0289f",
            slice_type: "cotizador",
            slice_label: null,
          },
        ],
        list_component_productos: "mediaObjectTypeC",
        bootstrap_cols_productos:
          "col-12 col-sm-8 col-md-4 mb-3 mb-md-0 py-5 py-md-3",
        list_productos: [
          {
            image_product: {
              dimensions: {
                width: 342,
                height: 141,
              },
              alt:
                "Persona feliz viajando con el Seguro de Viaje SURA, que le da cobertura médica",
              copyright: null,
              url:
                "/assets/images/prismic/destacado-seguro-viaje.jpg?auto=compress,format",
              id: "ZYyZkBAAAJR_s0tn",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            title_product: [
              {
                type: "heading4",
                text: "Viaja con tranquilidad",
                spans: [],
              },
            ],
            product_description: [
              {
                type: "paragraph",
                text:
                  "Protegemos de manera rápida y 100 % digital tu próxima aventura. Viaja con cobertura médica y otras coberturas integrales a ese destino soñado.",
                spans: [],
              },
              {
                type: "image",
                url:
                  "/assets/images/prismic/boton-cotizar.png",
                alt: null,
                copyright: null,
                dimensions: {
                  width: 216,
                  height: 40,
                },
                id: "XywVqhIAACMABzyJ",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "transparent",
                },
              },
            ],
            product_link: {
              link_type: "Web",
              url: "https://www.suraenlinea.com/viajes/sura",
              target: "_blank",
            },
          },
          {
            image_product: {
              dimensions: {
                width: 342,
                height: 141,
              },
              alt:
                "Mujer tranquila porque tiene el Seguro de Arrendamiento Digital de SURA",
              copyright: null,
              url:
                "/assets/images/prismic/destacado-seguro-arrendamiento-digital.jpg?auto=compress,format",
              id: "ZYycFBAAADPTs1an",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            title_product: [
              {
                type: "heading4",
                text: "Si el inquilino incumple",
                spans: [],
              },
            ],
            product_description: [
              {
                type: "paragraph",
                text:
                  "Te pagamos el arriendo para que tus finanzas no resulten afectadas. Además, tienes asistencia ante emergencias que se presenten en la vivienda.",
                spans: [],
              },
              {
                type: "image",
                url:
                  "/assets/images/prismic/boton-cotizar.png",
                alt: null,
                copyright: null,
                dimensions: {
                  width: 216,
                  height: 40,
                },
                id: "XywVqhIAACMABzyJ",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "transparent",
                },
              },
            ],
            product_link: {
              link_type: "Web",
              url: "https://www.suraenlinea.com/arrendamiento-digital/sura",
              target: "_blank",
            },
          },
          {
            image_product: {
              dimensions: {
                width: 342,
                height: 141,
              },
              alt:
                "Persona en moto rodando con tranquilidad porque tiene el Seguro de Moto de SURA",
              copyright: null,
              url:
                "/assets/images/prismic/destacado-seguro-moto.jpg?auto=compress,format",
              id: "ZYyhPxAAAJR_s22L",
              edit: {
                x: 0,
                y: 0,
                zoom: 1,
                background: "#fff",
              },
            },
            title_product: [
              {
                type: "heading4",
                text: "Muévete con protección",
                spans: [],
              },
            ],
            product_description: [
              {
                type: "paragraph",
                text:
                  "Cuenta con amplía cobertura de daños a terceros, así como con el mejor respaldo 24/7 en caso de choque, varada y daño total. ¡Rueda con SURA!",
                spans: [],
              },
              {
                type: "image",
                url:
                  "/assets/images/prismic/boton-cotizar.png",
                alt: null,
                copyright: null,
                dimensions: {
                  width: 216,
                  height: 40,
                },
                id: "XywVqhIAACMABzyJ",
                edit: {
                  x: 0,
                  y: 0,
                  zoom: 1,
                  background: "transparent",
                },
              },
              {
                type: "paragraph",
                text: " ",
                spans: [],
              },
            ],
            product_link: {
              link_type: "Web",
              url: "https://www.sura.co/seguro-de-moto-digital",
              target: "_blank",
            },
          },
        ],
      },
    },
  ],
  version: "679c7f6",
  license: "All Rights Reserved",
};