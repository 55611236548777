import React from 'react';
import { environment } from '../../environments/environment';
import './chatbotTibot.scss';

const ChatbotTibot = () => {
    return (
        <a id="chatIcon" href="javascript:" className="tibot-chat">
            <img src={environment.webchatTibotLogo} alt="Logo Tibot" class="openchat"/>
            <div class="tibot-chat__text openchat">Chatea con <strong>TiBot</strong></div>
            <div id="notification" class="chatbot-pratech-floating-badge"><label id="countNotification"></label></div>
        </a>
    )
};

export default ChatbotTibot;