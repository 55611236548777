

export const carullaTenant = {
  name: 'carulla',
  properties: {
    // Primary colors
    '--primary': '#6dc72a',
    '--secondary': '#005715',
    '--tertiary': '#dbdbdb',
    '--quarter': '#54555a',
    '--error': 'red',
    '--nav-bar': '#fff',

    /* Buttons */
    // Primary
    '--color-button-primary': '#fff',
    '--bg-color-button-primary': '#005715',
    '--hover-color-button-primary': '#fff',
    '--hover-bg-color-button-primary': '#005715',
    // Secondary
    '--color-button-secondary': '#fff',
    '--bg-color-button-secondary': '#005715',
    '--hover-color-button-secondary': '#fff',
    '--bg-hover-color-button-secondary': '#005715',
     // Tertiary
     '--color-button-tertiary': '#fff',
     '--bg-color-button-tertiary': '#ef7c32',
     '--hover-color-button-tertiary': '#fff',
     '--bg-hover-color-button-tertiary': '#ef7c32',

    // Forms
    '--color-input-valid': '#6dc72a',

    // Fonts
    '--primary-semibold-font-family': 'FSJoeySemiBold',
    '--font-family-secondary': `'Barlow', sans-serif`,
    '--font-family-secondary-medium': `'Barlow', sans-serif`,

    // Header
    '--background-header': '#fff',
    '--color-breadcrumb': '#000',
    '--color-breadcrumb-sucess': '#63ba2f',
    '--logo': 'url(../../../../assets/images/tenants/carulla/logo.png)',

    // Footer
    '--footer-color': '#fff',

    // SOAT
    '--color-tittle-h2': 'var(--primary)',
    '--color-tittle-h3': 'var(--primary)',
  }
};
