

export const exitocomTenant = {
  name: 'exitocom',
  properties: {
     // Primary colors
     '--primary': '#EEDE29',
     '--secondary': '#000',
     '--tertiary': '#EEDE29',
     '--quarter': '#000',
     '--error': 'red',
     '--nav-bar': '#707070',

     /* Buttons */
    // Primary
    '--color-button-primary': '#00b5cc',
    '--bg-color-button-primary': '#EEDE29',
    '--hover-color-button-primary': '#201D18',
    '--hover-bg-color-button-primary': '#00b5cc',
    // Secondary
    '--color-button-secondary': '#201D18',
    '--bg-color-button-secondary': '#EEDE29',
    '--hover-color-button-secondary': '#201D18',
    '--bg-hover-color-button-secondary': '#EEDE29',
    // Tertiary
    '--color-button-tertiary': '#201D18',
    '--bg-color-button-tertiary': '#EEDE29',
    '--hover-color-button-tertiary': '#201D18',
    '--bg-hover-color-button-tertiary': '#EEDE29',

    // Forms
    '--color-input-valid': '#00b5cc',

     // Fonts
    '--primary-semibold-font-family': 'PoppinsSemiBold',
    '--font-family-din-regular': 'PoppinsMedium',
    '--font-family-din-medium': `'Barlow', sans-serif`,
    '--font-family-secondary-light': 'HelveticaNeueLight',
    '--font-family-keep-in-mind-header': 'PoppinsSemiBold',
    '--font-family-nav-link': 'PoppinsMedium',
    '--font-size-nav-link': '1rem',

     // Header
     '--background-header': '#fff',
     '--color-breadcrumb': '#000',
     '--bg-breadcrumb-sucess': '#EEDE29',
     '--color-breadcrumb-sucess': '#000',
     '--logo': 'url(../../../../assets/images/tenants/exitocom/logo.png)',

     // Footer
     '--footer-color': '#fff',

     // SOAT
    '--color-tittle-h2': '#000',
    '--color-tittle-h3': '#000',

    // Breadcrumb
    '--bc-step-badge-bg': '#EEDE29',
    '--bc-step-badge-color': '#000',

    // Cotizador
    '--cotizador-first-line-color': '#000',
    '--cotizador-first-line-font': 'PoppinsLight',
    '--cotizador-second-line-font': 'PoppinsSemiBold',
    '--display-cotizador-second-line': 'block',

    // Nav
    '--nav-bg': 'white',
    '--nav-hover-color': '#EEDE29',

    // Condiciones
    '--condiciones-button-color': 'black',

    // Keep in mind
    '--bg-color-keep-in-mind-item': 'rgba(0,0,0,0)',
    '--color-font-keep-in-mind': '#707070',

    // Razones
    '--bg-color-reasons': 'rgba(0,0,0,0)',

    // Vehicle data summary
    '--bg-data-summary-1': '#EEDE29',
    '--bg-data-summary-2': '#F4F4F4',
    '--color-data-summary-1': '#201D18',
    '--color-data-summary-2': '#201D18',
    '--color-total-soat': '#201D18',

    // Extras
    '--align-titles': 'center',
    '--button-background-color-default': '#EEDE29',
    '--benefits-alignment': 'start',
    '--button-tarifas-background': '#EEDE29',
    '--button-tarifas-color': 'black',
    '--border-bottom-color-how-to-claim': '#D0D0D0',
    '--color-resumen': '#201D18',
    '--color-pagos-tabs': '#000',
    '--font-weight-price-description': 'bold',
    '--color-pago-exitoso-title': '#000',
    '--background-button-pago-exitoso': '#EEDE29',
    '--color-button-pago-exitoso': '#000',
    '--text-decoration-pago-exitoso-button': 'none',
    '--btn-nav-scroll-rigth': '-83px',
  }
};
