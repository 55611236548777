import React, { useEffect } from 'react';
import { Container, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import { ContainerProduct } from './styles';
import './reset.scss';
import Link from '../../StyledShared/Link';
import Swiper from 'swiper';
import classNames from 'classnames/bind';
import { DATALAYERS } from '../../../services/datalayers';
import { useNearScreen } from '../../../hooks/useNearScreen';

const TypeC = ({ data }) => {
  const [show, element] = useNearScreen();

  useEffect(() => {
    new Swiper('.swiper-container-type-c', {
      slidesPerView: 4,
      allowSlidePrev: false,
      allowSlideNext: false,
      breakpoints: {
        760: {
          slidesPerView: 1,
          pagination: {
            el: '.swiper-pagination-type-c',
          },
          allowSlideNext: true,
          allowSlidePrev: true,
        },
      },
    });
  }, []);

  const pushDatalayer = (tagName) =>
    DATALAYERS.push('Interaccion-Destacados', tagName);

  return (
    <Container className='mb-5' ref={element}>
      {show && (
        <div className='swiper-container swiper-container-type-c'>
          <div className='swiper-wrapper'>
            {data.data.map((product, index) => (
              <Col
                key={`product-${index}`}
                className={classNames(
                  'swiper-slide h-auto',
                  data.bootstrapCols
                )}
                onClick={() => {
                  pushDatalayer(index + 1);
                }}
              >
                <Link
                  to={product.product_link.url}
                  target={
                    product.product_link.target
                      ? product.product_link.target
                      : '_self'
                  }
                >
                  <ContainerProduct className='h-100'>
                    <img
                      src={product.image_product.url}
                      alt={product.image_product.alt}
                      className='mw-100'
                    />
                    <div className='px-3 pt-2 title-product'>
                      {RichText.render(product.title_product)}
                    </div>
                    <div className='px-3 pb-2 description-product'>
                      {RichText.render(product.product_description)}
                    </div>
                  </ContainerProduct>
                </Link>
              </Col>
            ))}
          </div>
          <div className='swiper-pagination swiper-pagination-type-c pt-5'></div>
        </div>
      )}
    </Container>
  );
};

export default TypeC;
