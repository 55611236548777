import Flows from "../mockData";
import { environment } from "../commons/enviroment";
import getMenuFromPrismic from './menu-prismic';

function getStepsFlow(flowSearch) {

  
  //TODO Implimentación por tenant
  const steps = Flows
    .filter(flow => flow.flow === flowSearch)
    .map(steps => {
      return steps.steps;
    })[0];
  return steps;
}

const menuAyudaTenants = (tenant) =>{
  return [{
    caption: "Ayuda",
    iconFa: "fas fa-question-circle fa-lg",
    link: `/${tenant}/preguntas-frecuentes`,
    target: "_self"
  }]
}

const COMMONS = {
  MENUS: {
    async get(tenant, active) {
     const menu = await getMenuFromPrismic(tenant);     
     const tenantData = environment.tenants.find(t => t.tenant === tenant);
     const canActivateFaq = tenantData.canActivateRoute.preguntasFrecuentes; 
     return tenant === 'sura' ? menu : (menuAyudaTenants && canActivateFaq ? menuAyudaTenants: null);
    }
  },
  STEPS: {
    get(flowSearch) {
      return getStepsFlow(flowSearch);
    }
  }
};

export default COMMONS;
