import styled from 'styled-components';
import { Accordion } from "react-bootstrap";

export const Title = styled.h2`
  color: var(--primary);
  margin-bottom: 2rem;
  font-family: var(--font-family-secondary);
  font-weight: 600;
`;

export const AccordionExtended = styled(Accordion)`
  background: var(--white);
  box-shadow: 0rem 0.125rem 0.75rem #00000029;
  border-radius: 0.625rem;
  opacity: 1;
  margin: 2rem;
`;