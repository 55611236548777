export const MAESTRO_EDADES = Object.freeze([
    {
        codigo: '18',
        value: 'Entre 18 a 49 años'
    },
    {
        codigo: '50',
        value: 'Entre 50 a 60 años'
    }
]);
