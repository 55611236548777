import styled from 'styled-components';

export const Image = styled.img`
  width: 200px;
  height: 34px;
  @media (min-width: 768px) {
    width: 300px;
    height: 41px;
  }
`;

export const TitleSeguros = styled.div`
  background: var(--primary);
  font-size: 1.3rem;
  text-align: center;
  color: #fff;
  font-family: 'FSJoeySemiBold';
`;

export const Logo =  styled.div`
  height: 54px;
  margin: 4px 0;
  background: var(--logo, url(/assets/images/tenants/sura/logo.png));
  background-repeat: no-repeat;
  width: 230px;
  background-size: 230px;
  background-position: center right;
  @media (min-width: 768px) {
    height: 63px;
    background-size: contain;
    width: 300px;
    margin: 0;
  }
`;

export const HeaderContainer = styled.div`
  background-color: var(--background-header);
`;
