import styled from 'styled-components';

export const BackgroundBanner = styled.header(({ 
  backgroundDesktop,
  backgroundMobile 
}) => `
  background-image: url(${backgroundMobile});
  background-position: center top;
  @media (min-width: 768px) {
    background-image: url(${backgroundDesktop});
  }
`);
