import React, { useEffect, useState } from 'react';
import Services from '../services';
import { PrismicService } from '../services/prismic';
import NavbarHeader from '../components/NavbarHeader';
import NavBarMenu from '../components/NavBarMenu';
import ContentSections from '../components/ContentSections/ContentSections';
import Footer from '../components/Footer';
import { FixedHeader } from '../components/StyledShared/FixedHeader';
import Loading from '../components/Loading';
import { Helmet } from 'react-helmet';

const Home = ({tenant}) => {

  const [menu, setMenu] = useState([]);
  const [sections, setSections] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    PrismicService.getDocument('home', ['sura', 'home'])
      .then(response => {
        setSections(response.results[0].data.sections);
        setData(response.results[0].data);
        setLoading(false);
      }
      );        
  }, []);

  useEffect(() => {
    Services.COMMONS.MENUS.get((tenant && tenant.tenant || "sura")).then(menuPrismic => setMenu(menuPrismic));
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Suraenlinea.com, el cotizador de Seguros SURA</title>
        <meta name="description" content="Compra tu SOAT y asegura tu moto, carro, bici, salud, vivienda, vida y otros respaldos que tenemos para ti en nuestro cotizador de Seguros SURA." />
        <meta name="keywords" content="cotizador de seguros, cotizar seguro, seguro de carro, seguro de moto, seguro de bicicleta, seguro de vida, seguros de hogar, seguro exequial, daños a terceros, soat, soat digital" />
      </Helmet>
      {        
        !loading ?
        <React.Fragment>
          <FixedHeader>
            <NavbarHeader />
            {menu && <NavBarMenu menus={menu} />}
          </FixedHeader>
          <ContentSections
            sections={sections}
            data={data}
          />
          <Footer />
        </React.Fragment>
        : <Loading />          
      }
    </React.Fragment>    
  );
}


export default Home;
