import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Services from "../../services";
import { PrismicService } from "../../services/prismic";
import { TipoDocumentoSalesforce } from "../../services/utils/TipoDocumentoSalesforce";
import { getMaestro } from "../../services/maestro";
import { replaceKeyByMap } from "../../services/replace-key-by-map";
import { PROSPECTO } from "../../services/prospecto";
import { TipoAsegurado } from "../../services/utils/tipo-asegurado";
import SALESFORCE from "../../services/salesforce";
import { SALESFORCE_PRODUCT } from "../../services/utils/salesforce-products";
import { SalesforceStrCual } from "../../services/utils/salesforce-str-cual";
import { DATALAYERS } from "../../services/datalayers";

import { PRISMIC_DOCUMENT_TYPE } from "../../utils/constants/ConfigPrismic";
import { DOCUMENT_NAME } from "../../utils/constants/DocumentName";
import { MAESTRO } from "../../utils/constants/Maestro";
import {
  DATA_LAYER_EVENT,
  DATA_LAYER_PRODUCT_NAME,
  DATA_LAYER_SOURCE,
} from "../../utils/constants/DataLayer";

import NavbarHeader from "../../components/NavbarHeader";
import NavBarMenu from "../../components/NavBarMenu";
import ContentSections from "../../components/ContentSections/ContentSections";
import Footer from "../../components/Footer";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";

import { environment } from "../../environments/environment";
import { validation } from "../Hogar/validaton";
import * as queryString from "query-string";

const MueveteLibre = ({ tenantName }) => {
  const [contactSection, setContactSection] = useState({
    contactSections: [],
    contactData: [],
    loadingContact: true,
  });

  const { asesor, canal, utm_campaign } = queryString.parse(
    window.location.search
  );

  useEffect(() => {
    DATALAYERS.pushObject({
      name: DATA_LAYER_PRODUCT_NAME.MUEVETE_LIBRE,
      source: DATA_LAYER_SOURCE.SURA_EN_LINEA,
      event: DATA_LAYER_EVENT.PAGE_VIEW,
    });
  }, []);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [menu, setMenu] = useState([]);

  const [state, setState] = useState({
    sections: [],
    data: [],
    loading: true,
  });

  const [maestroState, setMaestroState] = useState({
    maestro: [],
  });

  const [showTCModal, setShowTCModal] = useState(false);

  const getMapMaestro = () => {
    const keysMap = {
      codigo: "key",
      short_name: "value",
    };
    const { maestro } = maestroState;
    return replaceKeyByMap(keysMap, maestro);
  };

  const getTipoDocumentoSalesforceBySigla = (sigla) => {
    const siglaTipoDocumentoSalesforce = Object.keys(
      TipoDocumentoSalesforce
    ).find(
      (keyTipoDocumentoSalesforce) => keyTipoDocumentoSalesforce === sigla
    );
    return siglaTipoDocumentoSalesforce
      ? TipoDocumentoSalesforce[siglaTipoDocumentoSalesforce]
      : maestroState.maestro
          .find((maestro) => maestro.codigo === sigla)
          .short_name.toUpperCase();
  };

  const fields = [
    {
      label: "Nombres",
      type: "text",
      name: "firstName",
      value: "",
      maxlength: 50,
    },
    {
      label: "Apellidos",
      type: "text",
      name: "lastName",
      value: "",
      maxlength: 50,
    },
    {
      label: "Tipo de identificación",
      type: "select",
      name: "typeId",
      value: "",
      maxlength: 50,
      data: getMapMaestro(),
    },
    {
      label: "Número de identificación",
      type: "text",
      name: "document",
      value: "",
      maxlength: 11,
    },
    {
      label: "Correo electrónico",
      type: "text",
      name: "email",
      value: "",
      maxlength: 50,
    },
    { label: "Celular", type: "text", name: "phone", value: "", maxlength: 10 },
    {
      label:
        "Autorizo el uso responsable de mi información y acepto",
      type: "checkbox",
      name: "authorization",
      value: "",
      onClick: () => setShowTCModal(true),
    },
  ];

  const onBannerFormSubmit = (values, resetForm) => {
    const tenant = environment.tenants.find(
      (tenant) => tenant.tenant === tenantName
    );
    executeRecaptcha("cotizarSeguroPatineta").then((token) => {
      PROSPECTO.saveProspecto(
        {
          seguros: [
            {
              producto: { nombre: DOCUMENT_NAME.MUEVETE_LIBRE },
            },
          ],
          datosAsesor: {
            codigoCanal: canal ? canal : tenant && tenant.codigoCanal,
            codigoAsesor: asesor ? asesor : tenant && tenant.codigoAsesor,
          },
          aceptos: [
            {
              tipo: "Datos personales",
              nombre: "Tratamiento de datos Muévete Libre",
            },
          ],
          trazabilidad: {
            origen: document.location.origin,
            tenant: tenantName,
            url: document.location.href,
          },
          fechaCreacion: new Date().valueOf(),
          tomador: {
            persona: {
              PersonaNaturalTemp: {
                primerNombre: values.firstName.split(" ")[0],
                segundoNombre: values.firstName.split(" ")[1],
                primerApellido: values.lastName.split(" ")[0],
                segundoApellido: values.lastName.split(" ")[1],
                correo: values.email,
                celular: values.phone.toString(),
                numeroIdentificacion: values.document,
                tipoIdentificacion: values.typeId,
              },
            },
            tipoAsegurado: TipoAsegurado.ASEGURADO_PRINCIPAL,
          },
        },
        token
      );
    });

    const camposAdicionales = {
      Solucion_de_interes__c: "Muévete Libre",
      Asesor_Solicitado__c: asesor ? asesor : "",
      description: utm_campaign ? utm_campaign : "",
    };

    SALESFORCE.sendTomadorDataToSalesForce(
      values.firstName,
      values.lastName,
      values.phone,
      getTipoDocumentoSalesforceBySigla(values.typeId),
      values.document,
      values.email,
      SALESFORCE_PRODUCT.MUEVETE_LIBRE,
      SalesforceStrCual.FORMULARIO_CONTACTO,
      camposAdicionales
    ).finally(() => {
      resetForm();
    });
    DATALAYERS.push("Lead", "Muevete-Libre");
  };

  useEffect(() => {
    PrismicService.getDocument(PRISMIC_DOCUMENT_TYPE.LANDING, [
      tenantName,
      DOCUMENT_NAME.MUEVETE_LIBRE,
    ]).then((response) => {
      setState({
        sections: response.results[0].data.sections,
        data: response.results[0].data,
        loading: false,
      });
    });

    getMaestro(MAESTRO.TIPO_DOCUMENTO, PRISMIC_DOCUMENT_TYPE.LANDING).then(
      (response) => {
        const orderedRes = response.sort((documentoUno, documentoDos) => {
          const codigoDocumentoUno = documentoUno.codigo;
          const codigoDocumentoDos = documentoDos.codigo;
          if (codigoDocumentoUno < codigoDocumentoDos) {
            return -1;
          }
          if (codigoDocumentoUno > codigoDocumentoDos) {
            return 1;
          }
        });
        setMaestroState({
          maestro: orderedRes,
        });
      }
    );

    PrismicService.getDocument(PRISMIC_DOCUMENT_TYPE.CONTACTO, [
      tenantName,
    ]).then((response) => {
      setContactSection({
        contactData: response.results[0].data,
        contactSections: response.results[0].data.sections,
        loadingContact: false,
      });
    });
  }, []);

  useEffect(() => {
    Services.COMMONS.MENUS.get(tenantName).then((menuPrismic) =>
      setMenu(menuPrismic)
    );
  }, []);

  const { sections, data, loading } = state;
  const { contactSections, contactData, loadingContact } = contactSection;

  return (
    <React.Fragment>
      <Helmet>
        <title>Muévete Libre / suraenlinea.com</title>
        <meta name='description' content='Cotiza aquí el seguro de movilidad de SURA que te respalda en cualquier medio de transporte: bus, metro, taxi, bici, patineta, o incluso si vas caminando.' />
        <meta name='keywords' content='seguro de movilidad, accidente en bus, accidente en bicicleta, asistencia sura, patineta eléctrica, accidente en patineta, daños a terceros, robo de patineta, robo de documentos.' />
      </Helmet>
      <NavbarHeader />
      {menu && <NavBarMenu menus={menu} />}
      {!loading ? (
        <ContentSections
          sections={sections}
          data={data}
          fields={fields}
          validation={validation}
          onSubmit={onBannerFormSubmit}
        />
      ) : (
        <p>Loading...</p>
      )}
      {!loadingContact && (
        <ContentSections sections={contactSections} data={contactData} />
      )}
      <Footer />
      <TermsAndConditionsModal
        show={showTCModal}
        onDismiss={() => setShowTCModal(false)}
        prismicTags={[DOCUMENT_NAME.MUEVETE_LIBRE]}
      ></TermsAndConditionsModal>
    </React.Fragment>
  );
};

export default MueveteLibre;
